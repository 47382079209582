<template>
  <div class="wrapper" v-show="!showSelectorMobile" v-bind:class="{ map: from == 'map' }">
    <header ref="main" v-show="windowWidth > 800">
      <button v-if="isAnalysis" class="compare-button btn btn-sm float-right" @click="toggleNav()">{{ $t('analysis.compare') }}</button>
    </header>
    <button v-if="windowWidth <= 800" class="compare-button btn btn-sm float-right" @click="goToSelector">{{ $t('analysis.compare') }}</button>
    <main>
      <div v-if="from  == 'map'"  class="map-chart-top">
        <span class="map-active-parameter-label">{{this.getTitle(activeParameter)}}</span>
        <div class="map-chart-icons-right">
          <span  class="map-close-chart"><img src="../../assets/images/delete-cross.png" @click="closeChart()"></span>
          <span class="add-precipitation" >
                <img src="../../assets/images/precipitation-chart.png"
                     v-bind:class="{ active: hasPrecipitation }"
                     @click="togglePrecipitation"
                     :alt="$t('analysis.add_knmi')"
                     width="32" height="32">
            </span>
        </div>
      </div>
      <div id="chart-container-wrapper">
        <highcharts class='highcharts-component' :options="options" ref="highcharts"> </highcharts>
      </div>
    </main>
    <div id="chart_filter_div" v-if="showFilter">
      <p>{{ $t('datetime.last') }}: </p>
      <div id="chart_filter_btn_group" :class="{ 'btn-group': this.from !== 'map' }">
        <button class="btn lastMonth" :class="{active: timeFilter=='lastMonth'}" @click="toggleTimeFilter('lastMonth');">{{ $t('datetime.month') }}</button>
        <button class="btn lastWeek" :class="{active: timeFilter=='lastWeek'}" @click="toggleTimeFilter('lastWeek');">{{ $t('datetime.week') }}</button>
        <button class="btn lastDay" :class="{active: timeFilter=='lastDay'}" @click="toggleTimeFilter('lastDay');">{{ $t('datetime.day') }}</button>
        <button class="btn reset-zoom-button" v-if="showResetZoomButton" @click="resetZoom()">{{ $t('analysis.reset_zoom') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Highcharts from 'highcharts';
  import i18n from '@/i18n';
  import JQUERY from 'jquery'
  let Color = require('color');

  export default {
    name: "analysis-chart",
    props: ['from', 'first', 'id'],
    components: {

    },
    computed: {
      isAnalysis() {
        return this.from !== 'borden' && this.from !== 'map';
      },
      config() {
        return this.$store.state.config
      },
      publicDashboard() {
        return this.$store.state.publicAccount
      },
      prefersSurfaceLevel() {
        return this.$store.state.preferSurfaceLevel;
      },
      activeParameter() {
        if (this.activeSeries.length > 0) {
          return this.activeSeries[0].parameter;
        }
        return null;
      },
      activeClassificationSeries() {
        if (this.activeSeries.length == 1) {
          return this.activeSeries[0];
        }
        return null;
      },
      hasPrecipitation() {
        return this.idForPrecipitation !== null;
      },
      showFilter() {
        return this.from != 'borden';
      }
    },
    data(){
      let vm = this;
      return {
        windowWidth: window.innerWidth,
        showSelectorMobile: false,
        activeSeries: [],
        idForPrecipitation: null, // Id of the series that was used to add precipitation.
        options: {
          chart: {
            type: 'line',
            zoomType: 'x',
            panning: {
              enabled: true
            },
            panKey: 'shift',
            style: {
              fontFamily: "'Poppins', sans-serif"
            },
            boost: {
              useGPUTranslations: true,
              usePreAllocated: true
            },
            resetZoomButton: {
              theme: {
                style: {
                  display: 'none'
                }
              },
            }
          },
          title: { text: null },
          lang: {
            noData: i18n.t('analysis.no_chart_selected')
          },
          noData: {
            style: {
              fontWeight: 'bold',
              fontSize: '15px',
              color: '#303030'
            }
          },
          legend: {
            verticalAlign: 'top',
            align: 'left',
            maxHeight:40
          },
          xAxis: {
            type: 'datetime',
            opposite: true,
            lineColor: 'black',
            lineWidth: 3,
            tickColor: '#969696',
            plotLines: [{
              // Vertical line to indicate now.
              value: (new Date()).getTime(),
              color: '#969696',
              width: 0.5
            }],
            events:{
              setExtremes: function(e) {
                if (e.trigger == 'zoom') {
                  vm.showResetZoomButton = true;
                }
              }
            },
            showEmpty: false
          },
          time: {
            useUTC: false
          },
          yAxis: {
            opposite: false,
            title: {
              text: ""
            },
            lineColor: '#969696',
            lineWidth: 0.5,
            gridLineColor: '#969696',
            gridLineWidth: 0.5,
            tickAmount: 5,
            showEmpty: false,
          },
          colors: ['#00008c', '#00C8A0', '#2e98fc', '#ff2246', '#f7a35c', '#e4d354', '#90ed7d',  '#434348', '#8085e9', '#f15c80', '#2b908f', '#f45b5b', '#91e8e1'],
          plotOptions: {
            series: {
              lineWidth: 2
            }
          },
          exporting: { enabled: false },
          credits: {
            enabled: false
          },
          responsive: {
            rules: [{
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                subtitle: {
                  text: null
                },
                navigator: {
                  enabled: false
                }
              }
            }]
          }
        },
        timeFilter: null,  // Holds the selected choice in the time filter.
        showResetZoomButton: false,
        colorNumber: -1,
        autoRedraw: true,
      }
    },
    mounted() {
      this.localize();
      if (this.from == "map" && this.first) {
        this.$parent.$parent.selectedChart = this;
      }
      // highcharts doesn't response to changing of the size
      // of the component so you have to tell it manually
      window.addEventListener('resize', this.handleResize)
      this.handleResize();

      this.$store.dispatch('retrieveConfig')      // Is this still necessary?

    },
    methods: {
      localize() {
        function translateDatetime(keys) {
          return keys.map(s => i18n.t('datetime.' + s));
        }
        let shortMonths = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
        let months = ['january', 'february', 'march', 'april', 'may_long', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
        let weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        Highcharts.setOptions({
          lang: {
            shortMonths: translateDatetime(shortMonths),
            months: translateDatetime(months),
            weekdays: translateDatetime(weekdays),
            printChart: i18n.t('analysis.print_chart'),
            downloadJPEG: i18n.t('analysis.download_jpg'),
            downloadPNG: i18n.t('analysis.download_png'),
            downloadPDF: i18n.t('analysis.download_pdf'),
            downloadSVG: i18n.t('analysis.download_svg')
          },
          accessibility: {
            enabled: false
          }
        });
      },
      getChart() {
        if (this.$children.length > 0){
          return this.$children[0].chart;
        }
      },
      closeChart(){
        if (this.first) {
          this.$parent.$parent.chartOneVisible=false;
        } else {
          this.$parent.$parent.chartTwoVisible=false;
        }
      },
      checkIfPopupBorden () {
        // do a hacky check
        return this.$parent.$el.className.includes('contentPopupChart')
      },
      goToSelector() {
        // only for mobile
        this.showSelectorMobile = !this.showSelectorMobile;
        this.$parent.active = true
        this.$parent.selectorMobile = true
      },
      handleResize() {
        // this.$children[0] instead of this.$refs.highcharts, because on borden there are multiple.
        let chart = this.getChart();
        if (chart.index !== undefined) {
          if (this.from === 'borden') {
            chart.setSize(
                    '', // new width of the containing div
                    (window.innerHeight / 2 - 112), // new height of the containing div
                    false	// don't animate the chart itself changing
            );
          } else {
            chart.setSize(
                    '', // new width of the containing div
                    window.innerHeight / 2, // new height of the containing div
                    false	// don't animate the chart itself changing
            );
          }
        }
      },
      toggleNav () {
        this.$parent.$emit('toggleNav')
        let chart = this.getChart();
        let vm =this;
        let width;
        // wait 200 ms to get the correct sizes
        setTimeout(function () {
          if (vm.windowWidth > 800){
            width = vm.$refs.main.clientWidth;
          } else {
            width = vm.windowWidth;
          }
          chart.setSize(
                  width, // new width of the containing div
                  window.innerHeight/2, // new height of the containing div
                  true	// don't animate the chart itself changing
          );
        },200)
      },
      removeData(chart, id) {
        while (chart.get(id)) {
          chart.get(id).remove();
        }
      },
      removeForecast(chart, id) {
        this.removeData(chart, id + '_F');
      },
      removeModifiedForecast(chart, id) {
        this.removeData(chart, id + '_M');
      },
      removeStatus(chart, id) {
        this.removeData(chart, id + '_status');
      },
      removeSeries (id) {
        /* Remove a TimeSeries from the chart. */
        let chart = this.getChart();
        this.removeData(chart, id);
        this.removeForecast(chart, id);
        this.removeModifiedForecast(chart, id);
        this.removeStatus(chart, id);

        if(chart.yAxis[0]) chart.yAxis[0].removePlotBand();
        this.activeSeries.filter(s => s.id == id).forEach(serie => {
          delete serie.colorNumber;
        });

        this.activeSeries = this.activeSeries.filter(serie => serie.id != id);
        this.updatePlotBands(chart);
        if (!this.timeFilter) {this.resetZoom();}
      },
      removeAllSeries () {
        /* Clear all TimeSeries from the chart. */
        let chart = this.$refs.highcharts.chart;
        for(let i = chart.series.length - 1; i >= 0; i--) {
          chart.series[i].remove();
        }
        this.activeSeries = [];
        this.$emit('changeTitle', '');
      },
      reloadChart () {
        const seriesArray = [...this.activeSeries];   // Make a copy.

        this.removeAllSeries();
        this.loadMultiple(seriesArray, this.idForPrecipitation);
      },
      lastMonthDate () {
        let d = new Date();
        let newMonth = d.getMonth() - 1;
        if(newMonth <= 0){
          newMonth += 12;
          d.setFullYear(d.getFullYear() - 1);
        }
        d.setMonth(newMonth);
        return d
      },
      lastWeekDate() {
        return new Date(new Date() - (7*24*3600*1000));
      },
      lastDayDate() {
        return new Date(new Date() - (24*3600*1000))
      },
      startDate() {
        return {
          lastMonth: this.lastMonthDate,
          lastWeek: this.lastWeekDate,
          lastDay: this.lastDayDate
        }[this.timeFilter]();
      },
      applyTimeFilter() {
        let start = this.startDate();
        this.setTimestampLimits(start.getTime(), null);
      },
      setTimestampLimits(start, end) {
        this.getChart().xAxis[0].setExtremes(start, end);   // Uses integer timestamps, triggers redraw
      },
      maxZoomLevel() {
        let maxZoom = 0;
        this.getChart().series.forEach(serie => {
          if (serie.userOptions.id != 'precipitation-data') {
            //get the max X value of a serie
            let max = serie.xData[serie.xData.length - 1];
            if (max > maxZoom || maxZoom == 0) {
              maxZoom = max;
            }
          }
        });
        return maxZoom;
      },
      minZoomLevel() {
        let minZoom = 0;
        this.getChart().series.forEach(serie => {
          if (serie.userOptions.id != 'precipitation-data') {
            //get the min X value of a serie
            let min = serie.xData[0];
            if (min < minZoom || minZoom == 0) {
              minZoom = min;
            }
          }
        });
        return minZoom;
      },
      applyZoom(timeFilter) {
        this.timeFilter = timeFilter;
        if (this.timeFilter) {
          this.applyTimeFilter();
        }
        else {
          this.setTimestampLimits(this.minZoomLevel(), this.maxZoomLevel());
        }
      },
      resetZoom() {
        this.showResetZoomButton = false;
        this.applyZoom(null);
      },
      toggleTimeFilter(timeFilter) {
        if (this.timeFilter != timeFilter) {
          this.applyZoom(timeFilter);
        }
        else {
          this.resetZoom();
        }
      },
      async load(series) {
        if (this.from != "map") {
          this.setTitle(series.parameter)
        }
        //this.setAxisTitle(chart, series)
        if (series.unit == '-') series.unit = '';
        if (series.colorNumber === undefined) {
          series.colorNumber = this.nextColorNumber();
        }
        return this.loadData(series);
      },
      async loadById(id, colorNumber) {
        /* Load a TimeSeries by id. */
        let response = await this.seriesMetaData(id);
        let series = response.data;
        series.colorNumber = colorNumber;
        return this.load(series);
      },
      async loadMultiple(seriesArray, idForPrecipitation, timeFilter) {
        /* Load multiple TimeSeries into the chart at once. Only uses ids and colorNumbers in seriesArray. */
        this.timeFilter = timeFilter;
        this.autoRedraw = false;
        let promises = [];
        seriesArray.forEach(s => promises.push(this.loadById(s.id, s.colorNumber)));
        await Promise.all(promises);
        if (idForPrecipitation !== null) {
          let response = await this.seriesMetaData(idForPrecipitation);
          await this.addPrecipitation(response.data);
        }
        this.redraw();
        this.autoRedraw = true;
      },
      loadPrecipitation(series, data) {
        let precipitationSeries = {
          id: 'precipitation-data',
          parameter: "Precipitation",
          unit: "mm",
          name: i18n.t('analysis.precipitation_for') + ' ' + series.name
        };
        const extraOptions = {
          color: "#7cb5ec",
          yAxis: 1,
          pointRange: 24 * 3600 * 1000, //daily
          pointPadding: 0,
          groupPadding: 0,
          pointPlacement: 0.5
        }
        this.addPrecipitationAxis();
        this.idForPrecipitation = series.id;
        this.addSeries(precipitationSeries, data, extraOptions);
      },
      async locationPrecipitation(series) {
        const coordinates = series.location.coordinates;
        const params = {
          longitude: coordinates[0],
          latitude: coordinates[1],
          date_format: 'epoch'
        };
        const response = await axios.get('/precipitation/', {params: params});
        return response.data;
      },
      async connectedPrecipitation(series) {
        const url = `/series/${series.meta.precipitation_series}/data/`;
        const params = {
          format: 'json',
          date_format: 'epoch',
          resample: 'd'
        };
        const response = await axios.get(url, {params: params});
        return response.data;
      },
      async fetchPrecipitationData(series) {
        if (series) {
          if (series.meta && series.meta.precipitation_series) {
            return await this.connectedPrecipitation(series);
          } else if (series.location) {
            return await this.locationPrecipitation(series);
          }
        }
        return null;
      },
      async addPrecipitation(series=null) {
        /* Load precipitation based on the location of the last TimeSeries. */
        if (series === null) {
          series = this.latestSeries();
        }
        let data = await this.fetchPrecipitationData(series);
        if (data) {
          this.loadPrecipitation(series, data);
        }
      },
      latestSeries() {
        if (this.activeSeries.length == 0) return null;
        return this.activeSeries[this.activeSeries.length-1];
      },
      removePrecipitation() {
        this.idForPrecipitation = null;
        const chart = this.getChart();
        chart.get('precipitation-data').remove();
        chart.get('precipitation-axis').remove();
      },
      togglePrecipitation() {
        if (this.hasPrecipitation) {
          this.removePrecipitation();
        } else {
          this.addPrecipitation();
        }
      },
      setAxisTitle(chart, series) {
        /* Set the title of the y axis based on the loaded series. */
        chart.yAxis[0].update({
          title: {
            text: series.parameter + ' (' + series.unit + ')'
          },
        });
      },
      setTitle(parameter) {
        /* Set the title of the chart based on the parameter. */
        let title = this.getTitle(parameter);
        this.$emit('changeTitle', title);
      },
      getTitle(parameter) {
        let titles = {
          'T': i18n.t('parameters.temperature'),
          'EC': i18n.t('parameters.ec'),
          'SoilMoisture': i18n.t('parameters.soil_moisture'),
          'WaterLevel': i18n.t('parameters.water_level'),
          'pH': i18n.t('parameters.ph'),
          'NO3': i18n.t('parameters.no3'),
          'O2': i18n.t('parameters.oxygen'),
          'Precipitation': i18n.t('parameters.precipitation'),
          'SolarRadiation': i18n.t('parameters.radiation'),
          'WindSpeed': i18n.t('parameters.wind_speed'),
          // 'WindDirection': 'Windrichting',
        };
        return  titles[parameter];
      },
      fetchData(url, params={}) {
        /* Load data from the backend. */
        axios.defaults.headers.common['Authorization'] = 'JWT ' + this.$store.state.token;
        params.format = 'json';
        if (this.prefersSurfaceLevel) params.reference_level = 'surface';
        return axios.get(url, {params: params});
      },
      seriesMetaData(id) {
        const url = `/series/${id}/`;
        return this.fetchData(url);
      },
      seriesData(id) {
        const url = `/series/${id}/data/`;
        const params = {date_format: 'epoch'};
        return this.fetchData(url, params);
      },
      seriesStatusData(id) {
        const url = `/statusmeasurement/`;
        const params = {series: id, date_format: 'epoch'};

        return this.fetchData(url, params);
      },
      forecastData(id, params) {
        const url = `/series/${id}/forecast/`;
        return this.fetchData(url, params);
      },
      classificationData(id) {
        const url = `/classification/${id}/`;
        return this.fetchData(url);
      },
      mergeOptions(defaults, options) {
        /* Merge custom options with default options.
         *
         * Uses a shallow merge. Does not modify defaults and options.
         * Options defined in options overrule those in defaults.
         *
         * Returns:
         *     object: Highcharts options
         */
        return JQUERY.extend({}, defaults, options);
      },
      async loadForecast(series, params={}, name='forecast', dashStyle='shortdot', idExtension='_F') {
        name = i18n.t('analysis.' + name);
        if (series.forecastable) {
          let response;
          try {
            response = await this.forecastData(series.id, params);
          } catch (e) {
            return;   // Forecast might be unavailable even though series.forecastable = true.
          }
          const data = response.data;
          if (data) {
            const extraOptions = {
              dashStyle: dashStyle,
              color: this.options.colors[series.colorNumber]
            };
            const forecast_series = {...series};        // Make a copy.
            forecast_series.name = series.name + ' ' + name;
            forecast_series.id = series.id + idExtension;
            this.addSeries(forecast_series, data, extraOptions);
          }
        }
      },
      loadModifiedForecast(params) {
        let series = this.latestSeries();
        if (series === null) return;
        this.removeModifiedForecast(this.getChart(), series.id);
        let name = 'modified_forecast';
        let dashStyle = 'dot';
        let idExtension = '_M';
        this.loadForecast(series, params, name, dashStyle, idExtension);
      },
      async loadStatusMeasurements(series, measurements) {
        let response = await this.seriesStatusData(series.id)
        const data = response.data.results;
        data.forEach(m => {
          let timestamp = Date.parse(m.time);

          let previousMeasurement = measurements.filter(d => timestamp > d.time)[0];
          let nextMeasurement = measurements.filter(d => timestamp < d.time).slice(-1)[0];

          let statusMeasurement;
          if (nextMeasurement === undefined && previousMeasurement === undefined) {
            statusMeasurement = [{ "time" : timestamp, "value" : 0}];
          } else {
            if (previousMeasurement === undefined) {
              previousMeasurement = nextMeasurement;
            } else  if (nextMeasurement === undefined) {
              nextMeasurement = previousMeasurement;
            }

            let t0 =  previousMeasurement.time;
            let v0 = previousMeasurement.value;
            let t1 =  nextMeasurement.time;
            let v1 = nextMeasurement.value;

            let statusValue =  v0 + (v1-v0) * ((timestamp - t0) / (t1 - t0));

            if (isNaN(statusValue)) {
              statusValue = previousMeasurement.value;
            }

            statusMeasurement = [{ "time" : timestamp, "value" : statusValue}];
          }

          const extraOptions = {
            color: "red",
            dashStyle: "solid",
            showInLegend: false,
            scatter: {'stickyTracking': false},
            lineWidth: 1,
            marker: {
              symbol: 'circle',
              radius:12
            },
            tooltip: {
              xDateFormat: '%e %B %Y, %k:%M:%S',
              pointFormat: i18n.t('analysis.ran_dry'),
              crosshairs: false,
              shared: true
            },
          };

          const status_series = {...series};        // Make a copy.
          status_series.name = series.name + ' status';
          status_series.id = series.id + '_status';

          this.addSeries(status_series, statusMeasurement, extraOptions);

        });
      },
      async loadData(series) {
        let response = await this.seriesData(series.id)
        const extraOptions = {
          color: this.options.colors[series.colorNumber]
        };
        this.activeSeries.push(series);
        this.addSeries(series, response.data, extraOptions);
        await this.loadForecast(series);
        await this.loadStatusMeasurements(series, response.data);
      },
      updatePlotBands() {
        let chart = this.getChart();
        function toRGBA(color) {
          let colorObject = Color(color);
          return 'rgba('+colorObject.color[0]+', '+colorObject.color[1]+','+colorObject.color[2]+', 0.25)';
        }
        if (this.activeSeries.length == 1) {
          this.classificationData(this.activeClassificationSeries.id).then(cResponse => {
            const cData = cResponse.data;
            if (cData.default) {
              const choices = cData.default.choices;
              const gradientMargin = 0.17;
              const plotBands = (() => {
                let bands = []
                let lastGradientMaxValue;
                let lastGradientMarginDistance;

                for (let i=0; i < choices.length; i++) {
                  let isLast = choices[i+1] === undefined;
                  let isFirst = i === 0;
                  let thisClassificationColor = choices[i].color;

                  //Leave 10% (gradientMargin = 0.1) space on min and max for the gradient to fill.
                  let thisMinClassificationValue, thisMaxClassificationValue;
                  if(!isLast) {
                    if (choices[i].min >= 0) {
                      thisMinClassificationValue = isFirst ? choices[i].min : choices[i].min + ((choices[i].max - choices[i].min) * gradientMargin);
                      thisMaxClassificationValue =  choices[i].max * (1 - gradientMargin);
                    } else {
                      thisMinClassificationValue = isFirst ? choices[i].min : choices[i].min + ((choices[i].max - choices[i].min) * gradientMargin);
                      thisMaxClassificationValue = choices[i].max * (1 + gradientMargin);
                    }
                  } else {
                    //The last always max  integer so there will be no white spaces at the top of the chart
                    thisMaxClassificationValue = Number.MAX_SAFE_INTEGER;

                    //Make sure the last value is connected to the last gradient (last gradient margin is calculated)
                    thisMinClassificationValue = lastGradientMaxValue;
                  }

                  //The first always max negative integer so there will be no white spaces at the bottom of the chart
                  if (isFirst) {
                    thisMinClassificationValue = Number.NEGATIVE_INFINITY;
                  }


                  //If the margin for the gradient overlaps each other then make the color transparent and let the gradient band decide the colors
                  let color;
                  if (thisMinClassificationValue > thisMaxClassificationValue) {
                    let temp = thisMaxClassificationValue;
                    thisMaxClassificationValue = thisMinClassificationValue;
                    thisMinClassificationValue = temp;
                    color = "#00FFFFFF";
                    let colorObject =  Color(color);
                    color = 'rgba('+colorObject.color[0]+', '+colorObject.color[1]+','+colorObject.color[2]+', 0)';
                  } else {
                    color =  toRGBA(thisClassificationColor);
                  }

                  bands.push({
                    id: 'classification',
                    color: {
                      linearGradient: {x1: 0, x2: 0, y1: 1, y2: 0},
                      stops: [
                        [0, color],
                        [1, color]
                      ]
                    },
                    label: {
                      text: choices[i].label,
                      style: {
                        color: "#111111"
                      },
                      verticalAlign: 'middle',
                    },
                    borderWidth: 0,
                    from: thisMinClassificationValue,
                    to: thisMaxClassificationValue
                  })

                  //If not the last item add the gradient band
                  if (!isLast) {
                    let nextClassificationColor = choices[i + 1].color;
                    let nextMinClassificationValue = choices[i + 1].min + ((choices[i + 1].max - choices[i + 1].min) * gradientMargin);

                    let isSecondLast = choices[i+2] === undefined;
                    if (!isSecondLast) {
                      lastGradientMarginDistance = nextMinClassificationValue - thisMaxClassificationValue;
                    } else {
                      nextMinClassificationValue = thisMaxClassificationValue + lastGradientMarginDistance;
                      nextClassificationColor =  choices[choices.length-1].color;
                      lastGradientMaxValue = nextMinClassificationValue;
                    }

                    bands.push({
                      id: "classification",
                      color: {
                        linearGradient: {x1: 0, x2: 0, y1: 1, y2: 0},
                        stops: [
                          [0, toRGBA(thisClassificationColor)],
                          [1, toRGBA(nextClassificationColor)]
                        ]
                      },
                      borderWidth: 0,
                      from:thisMaxClassificationValue,
                      to: nextMinClassificationValue
                    });
                  }
                }


                return bands
              })()

              if (chart.index !== undefined) {
                chart.update({
                  yAxis: {plotBands: plotBands}
                })
              }

            } else {
              if(chart.yAxis && chart.yAxis[0]) chart.yAxis[0].removePlotBand();
            }
          })
        } else {
          setTimeout(function() {
            if(chart.yAxis && chart.yAxis[0]) chart.yAxis[0].removePlotBand("classification");
          }, 200);
        }
      },
      seriesOptions(series) {
        /* Highcharts options for a TimeSeries. */
        const options = {
          id: series.id,
          name: series.name,
          parameter: series.parameter,
          tooltip: {
            xDateFormat: '%e %B %Y, %k:%M:%S',
            pointFormat: '{series.name}<br>{point.y} ' + series.unit,
            crosshairs: true,
            shared: true,
            valueDecimals: 2
          },
          zIndex: 2,
        };
        if (series.parameter === 'Precipitation') {
          options.type = 'column';
          options.zIndex = 1;
        }
        return options;
      },
      addPrecipitationAxis() {
        /* Update the chart axis for the precipitation. */
        const chart = this.getChart();
        chart.xAxis[0].tickInterval= 1 * 3600 * 1000;
        if (chart.get('precipitation-axis')) return;
        chart.addAxis({
          id: 'precipitation-axis',
          title: {
            text: i18n.t('parameters.precipitation') + ' (mm)'
          },
          lineWidth: 2,
          labels: {
            format: '{value} '
          },
          opposite: true
        })
      },
      addSeries(series, data, extraOptions) {
        let seriesOptions = this.seriesOptions(series);
        if (extraOptions) seriesOptions = this.mergeOptions(seriesOptions, extraOptions);
        seriesOptions.data = this.toHighcharts(data);
        this.getChart().addSeries(seriesOptions, false);  // redraw=false, because redrawing happens in this.redraw().
        if (this.autoRedraw) {
          this.redraw();
        }
      },
      redraw() {
        /* Redraw the chart (applyZoom triggers redraw). */
        this.updatePlotBands();
        this.applyZoom(this.timeFilter);
      },
      toHighcharts(data) {
        /* Transforms the response data into an array usable by Highcharts. */
        if (data == null) return null;
        if (isNaN(data[0].time)) return data.map(measurement => [this.isoToTimestamp(measurement.time), measurement.value]).reverse();               // TODO: Remove when all requests support date_format=epoch.
        return data.map(measurement => [measurement.time, measurement.value]).reverse();
      },
      isoToTimestamp(isoString) {
        /* Transform an ISO 8601 string into a millisecond timestamp. */
        return (new Date(isoString)).getTime();
      },
      nextColorNumber() {
        /* Cycle through the colors. */
        this.colorNumber = (this.colorNumber + 1) % this.options.colors.length;
        if (this.activeSeries.filter(s => s.colorNumber == this.colorNumber).length > 0) return this.nextColorNumber();
        return this.colorNumber;
      },
      serializeSeries(series) {
        /* Serialize series shown in chart (avoiding duplicate data in backend). */
        return {
          id: series.id,
          colorNumber: series.colorNumber
        };
      },
      serialize() {
        return {
          series: this.activeSeries.map(s => this.serializeSeries(s)),
          idForPrecipitation: this.idForPrecipitation,
          timeFilter: this.timeFilter
        };
      },
      deserialize(data) {
        // Unpack serialized data.
        this.loadMultiple(data.series, data.idForPrecipitation, data.timeFilter);
      }
    }
  }
</script>

<style scoped>
  * {
    box-sizing: border-box;
  }
  .wrapper {
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }
  .col-8 .wrapper {
    position: fixed;
    width: 65%;
  }
  .contentPopupChart .col-8 .wrapper {
    width: 635px;
  }
  main {
    border-radius: 8px;
    overflow: hidden;
    background-color: #ffffff;
    box-shadow: 0px 8px 15px 0px rgba(27, 33, 58, 0.2);
  }
  @media screen and (max-width: 800px) {
    main {
      margin-top: 44px;
    }
  }
  main > div {
    padding: 0 2px 0 0;
  }
  .compare-button {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #00008c !important;
    text-align: right;
    margin-bottom: 30px;
    z-index: 1;
  }

  .map-chart-top {
    position:relative;
    padding:5px 10px 5px 15px;
  }
  .add-precipitation {
    cursor:pointer;
  }


  .add-precipitation .active, .add-precipitation img:hover{
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
    padding: 2px;
  }

  .map-active-parameter-label {
    float:left;
  }

  .map-chart-icons-right {
    float:right;
  }

  .map-chart-icons-right span {
    float:right;
    margin-left:15px;
  }

  .map-close-chart {
    cursor:pointer;
    float:right;
  }

  .map-close-chart img {
    position:relative;
    z-index:999;
    width:24px;
  }

  .map-close-chart img:hover {
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
    padding: 2px;

  }

  .btn:focus, .btn.focus {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .highcharts-component {
    width: 100%;
  }


  /* page Borden */
  .chart-container-1 main > div, .chart-container-2 main > div {
    padding: 0 10px 0 0;
  }
  #chart_filter_div {
    padding: 3px 8px;
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;
    background-color: #00008c;
    border-radius: 8px;
  }
  #chart_filter_div p{
    display: inline;
    color: white;
    font-size: 12px;
    font-weight: 800;
    padding-right: 8px;
  }
  #chart_filter_div button {
    font-size: 12px;
    font-weight: 800;
    color: #00008c;
    background-color: white;
    padding: 3px 7px;
    margin-bottom: 2px;
  }
  #chart_filter_div button:hover {
    border: 1px solid #818181;
  }
  #chart_filter_div button.active {
    border: 1px solid #818181;
    background-color: #e2e2e2;
  }


  /* MAP only chart style */
  .map.map-chart {
    position:relative;
    display: inline-block;
  }

  .map main {
    float:left;
    width:85%;
    height:95%;
  }

  .map #chart_filter_div {
    display: inline-block;
    position:relative;
    float:left;
    width:13%;
    margin-left:10px;
    margin-top:100px;
  }

  .map #chart_filter_btn_group {
    display: inline-block;
    position:relative;
    float:left;
    width:100%;
    pointer-events: auto;
  }

  .map #chart_filter_div button {
    float:left;
    width:100%;
    height:25px;
    display: inline-block;
  }

</style>
<style>
  .highcharts-container {
    position: static !important;
  }
  .reset-zoom-button {
    background-color: #6666ba !important;
    color: white !important;
  }
</style>

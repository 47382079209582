<template>
  <table>
    <tr><td :height="height" :style="rowStyle" >{{ -(number*0.01).toFixed(1) }} ─</td></tr>
    <tr v-for="(n, index) in 4" :key="index"><td :style="rowStyle"   :height="height">-</td></tr>
  </table>
</template>


<script>
  export default {
    props: ['number', 'pxpercm', 'backgroundColor'],
    data(){
      return {
        rowStyle: {
          backgroundColor: this.backgroundColor,
          opacity:0.8
        }
      }
    },
    computed: {
      height () {
        return 2 * this.pxpercm;
      }
    }
  }
</script>

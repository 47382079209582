<template>
  <div id="head">
    <h1>Pin {{ name.slice(-3) }}</h1>
    <p><img :src="image" height="21" width="33"/></p>
  </div>
</template>


<script>
  export default {
    props: ['values', 'name'],
    data () {
      return {
        batteryImages: {
          0: require('../../assets/images/batterij_vol_wit.png'),
          1: require('../../assets/images/batterij_leeg_wit.png'),
          2: require('../../assets/images/batterij_leeg_wit.png')
        }
      }
    },
    computed: {
      image () {
        let status = this.values["batteryStatus"];
        return this.batteryImages[status];
      }
    }
  }
</script>


<style>
  #head {
    text-align: center;
  }
</style>

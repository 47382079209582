<template>
  <router-link class="btn" :to="{ name: 'infographic', query: {pinNumber: pinNumber}, params: {back_page: back_page} }">
    <div class="row">
      <fixeau-head-devices class="col-10" :values='values' :name='name' :pinNumber="pinNumber"> </fixeau-head-devices>
      <slot></slot>
    </div>
  </router-link>
</template>


<script>
  import fixeauHeadDevices from './fixeauHeadDevices.vue'

  export default {
    props: ['values', 'name', 'pin', 'pinNumber', 'back_page'],
    components: {
      fixeauHeadDevices,
    },
    data () {
      return {
        
      }
    }
  }
</script>


<style>
  .list-devices .listitem {
    width: 350px;
    border: none;
    border-bottom: 1px solid #F2EFE9 !important;
    margin: 0 !important;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: 25px center;
  }
  .list-devices a:hover {
    text-decoration: none !important;
  }
  .list-devices .listitem.pin {
    background-image: url(../../assets/images/pin_green.png);
    border-radius: 0;
    height: 64px;
  }
  .list-devices .listitem.pin:hover {
    background-color: #f2efe9;
  }
  @media screen and (max-width: 800px) {
    .list-devices .listitem.pin:hover {
      background-color: transparent;
    }
  }
  .list-devices .listitem.probe {
    height: 64px;
    background-image: url(../../assets/images/probe_green.png);
    border-radius: 0;
    cursor: default !important;
  }
  .list-devices .listitem:last-child {
    border: none;
  }
  .list-devices .listitem:hover  {
    cursor: pointer;
  }
  .list-devices .listitem h1 {
    text-align: left !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    padding-left: 60px;
  }
  .list-devices .listitem p {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    line-height: 26px;
    font-weight: 400;
    color: #808080;
    text-align: left !important;
    margin: 0;
    padding-left: 60px;
  }
  .list-devices .listitem p img {
    margin-top: -2px;
  }
  .list-devices .listitem p .battery {
    opacity: 0.6;
    margin-left: 15px;
  }
</style>

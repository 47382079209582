<template>
  <div class="profile-container">
    <div class="profile">
      <back-button/>
      <ul class="list-group text-light" v-if="profile">
        <li class="list-group-item primary-item" v-if="profile.username">
          <span class="label">{{ $t('profile.username') }}</span>
          <p>{{ profile.username }}</p>
        </li>
        <li class="list-group-item primary-item" v-if="profile.first_name">
          <span class="label">{{ $t('profile.name') }}</span>
          <p>{{ profile.first_name }} {{ profile.last_name }}</p>
        </li>
        <li class="list-group-item primary-item" v-if="profile.email">
          <span class="label">{{ $t('profile.mail') }}</span>
          <p>{{ profile.email }}</p>
        </li>
        <li class="list-group-item primary-item" v-if="profile.details.phone_number">
          <span class="label">{{ $t('profile.phone_number') }}</span>
          <p>{{ profile.details.phone_number }}</p>
        </li>
        <li class="list-group-item primary-item">
          <span class="label">{{ $t('profile.address') }}</span>
          <p v-if="profile.details.address">{{ profile.details.address }}</p>
          <p v-if="profile.details.city">{{ profile.details.postal_code }} {{ profile.details.city }}</p>
          <p v-if="profile.details.country">{{ profile.details.country }}</p>
        </li>
        <li class="list-group-item primary-item">
          <span class="label">{{ $t('profile.devices') }}</span>
          <p class="list-group" v-for="(device, index) in devices" :key="index">{{ device }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>


<script>
  import BackButton from './BackButton.vue';
  export default {
    name: "my-profile",
    components: {
      BackButton
    },
    created() {
      this.$store.dispatch('retrieveProfile');
    },
    computed: {
      profile() {
        return this.$store.state.profile;
      },
      devices() {
        return this.profile.datasource_set.filter(
          // Filter DataSources that might be confusing for the user
          source => !source.includes('caddisfly-') && !source.includes('user-')
        );
      }
    }
  }
</script>


<style scoped>
  .profile-container {
    background-color: #f2efe9;
    height: 100%;
    min-height: calc(100vh - 70px);
    text-align: left;
  }
  .profile {
    width: calc(100% - 60px);
    max-width: 500px;
    margin: auto;
  }
  @media screen and (max-width: 800px) {
    .profile {
      margin-bottom: 90px;
    }
  }
  .profile p {
    color: #404040;
  }
  .profile li.list-group-item {
    border-bottom: 1px solid #e5e5e5 !important;
    margin-bottom: 8px!important;
    padding-bottom: 8px!important;
  }
  .profile li:last-child {
    border-bottom: none;
  }
  .primary-item {
    padding: 0;
    margin-bottom: 0;
    background-color: transparent;
    border: none;
  }
  .label {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    color: #000000 !important;
    display: block;
    margin-bottom: 4px;
  }
</style>

<template>
  <div id="ruler">
    <partial-ruler :backgroundColor="getBackgroundColor(i,index)" v-for="(i, index) in numbers" :number='i' :key="index" :pxpercm='pxpercm'> </partial-ruler>
    <table>
    <tr><td :style="getStyleClass(max_number)"  height="height">{{ -(max_number*0.01).toFixed(1) }} ─</td></tr>
    </table>
  </div>
</template>


<script>
  import partialRuler from './partialRuler.vue'

  export default {
    props: ['length', 'pxpercm'],
    computed: {
      max_number () {
        return 10 * Math.ceil(this.length / 10);
      },
      numbers () {
        return Array.from({length: this.max_number/10}, (v, k) => 10*k);
      },
      height () {
        return 2 * this.pxpercm;
      }
    },
    methods : {
      getStyleClass: function(i) {
        let backgroundColor = this.getBackgroundColor(i);

        return  { backgroundColor:  backgroundColor};
      },
      getBackgroundColor: function() {
        return "rgb(0, 234, 129)";
        // i+=10;
        // if (i <= 40) {
        //   return "red";
        // } else if(i <= 80) {
        //   return "orange";
        // } else if(i <= 120) {
        //   return "blue";
        // } else {
        //   return "purple";
        // }
      }
    },
    components: {
      partialRuler
    }
  }
</script>


<style>
  #ruler {
    position:relative;
    text-align: right;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    color: #00008c;

    z-index:-1;
  }
  #ruler table tr td {
    border: 0px;
    padding: 0px;
    margin: 0px;
    margin-left: auto;
    margin-right: 10px;
    width:100%;
  }
  #ruler table {
    width:100%;
  }
</style>

<template>
  <div class="text-center">
    <div :style="outerStyleObject">
      <div id="pin-wrapper">
        <div class="pin-back-link">
          <router-link class="btn btn-back" :to="{name: `${back_page}`}"></router-link>
        </div>
        <div class="pin-head" :style="headStyleObject">
          <fixeau-head v-if="loaded" :values="head.values" :name="dev_eui"> </fixeau-head>
        </div>
        <div class="pin-middle" :style="modulesStyleObject">
          <router-link v-for="module in modules" :key="module.position" :to="{ name: 'analysis', params: {selectedTimeseries: [module]} }"><fixeau-module :module="module" :pxpercm="pxpercm"> </fixeau-module></router-link>
        </div>
        <div class="pin-bottom"></div>
      </div>
      <div :style="rulerStyleObject">
        <side-ruler :length="totalLength" :pxpercm="pxpercm"> </side-ruler>
      </div>
      <!--<router-link class="btn mb-5 btn-hidden" :to="{name: `${back_page}`}">Go Back</router-link>-->
    </div>
  </div>
</template>


<script>
  import axios from 'axios';
  import fixeauHead from './fixeauHeadPin.vue'
  import fixeauModule from './fixeauModule.vue'
  import sideRuler from './sideRuler.vue'

  export default {
    props: ['back_page'],
    data () {
      return {
        dev_eui: '',
        setup: [],
        pxpercm: 18,
        loaded: false
      }
    },
    computed: {
      head () {
        if (this.loaded) {
          return this.setup[0]
        }
        else {
          return {
            'extensionSize': 0,
            'values': {}
          }
        }
      },
      modules () {
        return this.setup.slice(1)
      },
      headStyleObject () {
        return {
          'background': 'rgb(0, 234, 129)',
          'width': 25 * this.pxpercm + 'px',
          'height': 12 * this.pxpercm + 'px',
          'margin': '0px auto',
        }
      },
      modulesStyleObject () {
        return {
          'background': 'rgb(0, 234, 129)',
          'width': 10 * this.pxpercm + 'px',
          'margin': '0px auto',
          'padding-top': (this.head.extensionSize * this.pxpercm) - 60 + 'px'
        }
      },
      totalLength () {
        let length = this.head.extensionSize;
        for (let module of this.modules) {
          length += module.moduleSize;
          length += module.extensionSize;
        }
        return length;
      },
      rulerTranslation () {
        return -1 * (this.totalLength * this.pxpercm + 15) + 'px';
      },
      rulerStyleObject () {
        return {
          'position': 'relative',
          'top': this.rulerTranslation,
          'margin-bottom': this.rulerTranslation,
          'width': '100%',
          'float': 'right',
          'z-index': '-1',
        }
      },
      outerStyleObject () {
        return {
          'background': 'rgba(0, 234, 129)',
          //TODO: Get highest color!
         // 'background': 'purple',

          'z-index': '10',
          'position' : 'relative',
          'margin-top': '-20px'
        }
      }
    },
    mounted () {
      this.dev_eui = this.$route.query.pinNumber;
      let url = '/pinsetup/' + this.dev_eui + '/?format=json';
      axios
        .get(url)
        .then(response => {
          this.setup = response.data;
          this.loaded = true

          //Adds a classification to a module.
          function addClassificationToModule(module, classifications) {
            module.classification = (module.series in classifications) ? classifications[module.series] : null;
            return module;
          }

          let classifications = this.$store.state.classifications;
          this.modules.map(
            function (module) {
              return addClassificationToModule(module, classifications);
            }
          );
        })
    },
    components: {
      fixeauHead,
      fixeauModule,
      sideRuler
    }
  }
</script>


<style>
  .pin-head {
    background-image: url(../../assets/images/pin_top.png) !important;
    background-position: center top !important;
    background-repeat: no-repeat !important;
    width: auto !important;
    height: 231px !important;
    margin-top:-75px;
    z-index:20;
    position:relative;
  }
  .pin-middle {

    background-image: url(../../assets/images/pin_middle.png) !important;
    background-position: center top !important;
    background-size: 155px auto !important;
    background-repeat: repeat-y !important;
    width: 155px !important;
  }
  .pin-middle a {
    text-decoration: none !important;
  }
  .pin-bottom {
    background-image: url(../../assets/images/pin_bottom.png);
    background-position: center top;
    background-repeat: no-repeat;
    height: 72px;
    width:155px;
    margin:auto;
    background-color:rgb(0, 234, 129);
    border-bottom-left-radius: 75px;
    border-bottom-right-radius: 75px;
  }
  .btn-back {
    padding-left: 14px;
    background-image: url(../../assets/images/go_back_blue.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    width: 20px;
    height: 20px;
    display: block;
  }
  .pin-head #head h1 {
    font-size: 35px;
    line-height: 50px;
    font-weight: 700;
    margin: 20px 0;
  }
  .btn-hidden {
    margin: 40px 0 40px 0;
    opacity: 0;
  }

  .pin-back-link {
    position: absolute;
    left: 10px;
    top: 10px;
    height: 65px;
    width: 65px;
    z-index: 9999;
  }

  @media screen and (max-width: 800px) {
    .btn-hidden {
      margin: 80px 0 80px 0;
    }
  }
</style>

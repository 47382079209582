<template class="page-wrapper">
  <div class="form-container">
    <b-alert :show="dismissCountDown" variant="success" @dismissed="dismissCountDown=0" fade>
      {{ $t('contact.thank_you') }}
    </b-alert>
    <div class="page-wrapper contact-form">
      <back-button/>
      <p>&nbsp;</p>
      <form action="#" @submit.prevent="contact">
        <div v-if="serverError" class="server-error">{{ $t('contact.something_went_wrong') }}</div>
        <div class="form-control-message">
          <label for="message">{{ $t('contact.message') }}</label>
          <textarea name="message" id="message" class="contact-input" :class="{ 'input-error':errors.has('message') }" v-model="message" v-validate="'required|min:16'"/>
          <span class="form-error">{{ errors.first('message') }}</span>
        </div>
        <div class="form-control-submit">
          <button type="submit" class="btn-submit">{{ $t('contact.send') }}</button>
        </div>
      </form>
      <div class="mt-5">
        <p>{{ $t('contact.contact_text') }}</p>
        <p>
          <img class="contact-icon" src="@/assets/images/phone_green.png" alt=""/>
          <a :href="'tel:' + contactInfo.phone">{{ contactInfo.phoneText }}</a>
        </p>
        <p>
          <img class="contact-icon" src="@/assets/images/mail_green.png" alt=""/>
          <a :href="'mailto:' + contactInfo.email">{{ contactInfo.email }}</a>
        </p>
      </div>
    </div>
  </div>
</template>


<script>
  import i18n from '@/i18n';
  import BackButton from './BackButton.vue';
  import templateHelper from '@/helpers/templateHelper';
  export default {
    name: 'contact_us',
    components: {
      BackButton
    },
    data() {
      return {
        message: '',
        serverError: '',
        dismissCountDown: 0,
        dismissSecs: 2
      }
    },
    computed: {
      activeTemplate() {
        return templateHelper.getActiveTemplate();
      },
      contactInfo() {
        if (['burqaa', 'kunzila'].includes(this.activeTemplate)) {
          return {
            email: 'stefan.dewildt@acaciawater.com',
            phone: '+251979756748',
            phoneText: '+251 97 975 6748'
          };
        }
        return {
          email: 'info@fixeau.com',
          phone: '+31611286594',
          phoneText: '+31 (0) 6 1128 6594'
        }
      },
    },
    mounted() {
      const dictionary = {
        en: {
          custom: {
            message: {
              required: i18n.t('contact.required'),
              min: i18n.t('contact.min')
            }
          }
        }
      }
      this.$validator.localize(dictionary);
    },
    methods: {
      contact() {
        let vm = this;
        this.$validator.validateAll().then(() => {
          if (!this.errors.any() && this.message !== '') {
            this.$store.dispatch('contactUs', {
              message: this.message,
            })
              .then(() => {
                this.dismissCountDown = this.dismissSecs;
                setTimeout(function () {vm.$router.push({name: 'home'})}, vm.dismissSecs*1000);
              })
              .catch(error => {
                this.serverError = error.response.data.non_field_errors[0];
              })
          }
        })
      }
    }
  }
</script>


<style lang="scss" scoped>
  .form-container {
    background-color: #f2efe9;
    height: 100%;
    min-height: calc(100vh - 70px);
    text-align: left;
  }
  label {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    color: #404040;
    display: block;
    margin-bottom: 4px;
  }
  p a {
    color: #00008c;
    text-decoration: none;
  }
  p a:hover {
    color: #00008c;
    text-decoration: underline;
  }
  .contact-heading {
    margin-top: 30px;
    margin-bottom: 7px;
  }
  .form-control-subject {
    margin-bottom: 10px;
    border: none !important;
  }
  .form-control-message {
    margin-bottom: 20px;
    border: none !important;
  }
  .form-control-message {
    border: none !important;
  }
  .contact-form {
    width: calc(100% - 60px);
    max-width: 500px;
    margin: auto;
  }
  @media screen and (max-width: 800px) {
    .contact-form {
      margin-bottom: 90px;
    }
  }
  .contact-input {
    font-family: 'Dosis', sans-serif;
    font-size: 20px;
    font-weight: 400;
    width: 100%;
    height: 120px;
    padding: 12px 16px;
    outline: 0;
    border-radius: 5px;
    border: 1px solid #bfbdba;
    -webkit-appearance: none;
  }
  .btn-submit {
    font-family: 'Dosis', sans-serif;
    font-size: 20px;
    font-weight: 400;
    width: 100%;
    padding: 14px 12px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    background-color: #2e98fc;
    -webkit-appearance: none;
    cursor: pointer;
    &:hover {
      background-color: darken(#2e98fc, 10%);
    }
    &:disabled {
      background-color: lighten(#2e98fc, 25%);
      cursor: not-allowed;
    }
  }
  .server-error {
    font-family: 'Dosis', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: red;
    width: 100%;
    padding: 12px 16px;
    outline: 0;
    border-radius: 5px;
    border: 1px solid red;
    -webkit-appearance: none;
  }
  .form-error {
    font-size: 14px;
    color: red;
  }
  .input-error {
    border: 1px solid red;
  }
  .contact-icon {
    margin: 0 10px 3px 0;
  }
</style>

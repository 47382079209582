<template>
  <div class="container">
    <SuccessfullySavedAlert></SuccessfullySavedAlert>
    <ModifySeriesName :id="id" :name="name"></ModifySeriesName>
    <SoilType :id="id" :name="name"></SoilType>
    <Classification :id="id" :name="name"></Classification>
  </div>
</template>


<script>
  import ModifySeriesName from "./ModifySeriesName"
  import SoilType from "./SoilType"
  import Classification from "./Classification"
  import SuccessfullySavedAlert from "./SuccessfullySavedAlert"
  export default {
    name: "timeseriesSettings",
    components: {ModifySeriesName, SoilType, Classification, SuccessfullySavedAlert},
    data () {
      return {
        id: this.$route.query.id,
        name: this.$route.query.name
      }
    }
  }
</script>


<style scoped>
  .container {
    width: 350px;
    margin: 60px auto;
  }
</style>

<style>
  .save-btn {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    border-radius: 0;
    border: none;
    color: #00008c;
    background-color: #f2efe9;
     -webkit-appearance: none;
    cursor: pointer;
  }
</style>

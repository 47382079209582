const state = {
  levelModifications: [],
  baseLevelModifications: [],
  resistanceModifications: []
};

const getters = {
};

const actions = {
};

const mutations = {
  pushLevelModification(state, modification) {
    state.levelModifications.push(modification);
  },
  pushBaseLevelModification(state, modification) {
    state.baseLevelModifications.push(modification);
  },
  pushResistanceModification(state, modification) {
    state.resistanceModifications.push(modification);
  },
  deleteLevelModification(state, id) {
    state.levelModifications = state.levelModifications.filter(modification => modification.id !== id);
  },
  deleteBaseLevelModification(state, id) {
    state.baseLevelModifications = state.baseLevelModifications.filter(modification => modification.id !== id);
  },
  deleteResistanceModification(state, id) {
    state.resistanceModifications = state.resistanceModifications.filter(modification => modification.id !== id);
  },
  updateModification(state, payload) {
    let modifications = {
      'level': state.levelModifications,
      'baseLevel': state.baseLevelModifications,
      'resistance': state.resistanceModifications
    };
    let modification = modifications[payload.modificationType].find(modification => modification.id == payload.id);
    modification[payload.key] = payload.value;
  }
};

const namespaced = true;

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}

<template>
  <div class="page-wrapper">
    <Map :mapConfig="mapConfig"></Map>
  </div>
</template>


<script>
  import Map from './map/Map'
	export default {
		name: "main_map_container",
    components: {
      Map,
    },
    data () {
      return {
        mapConfig: {
          page: 'main_map_container',
          timeFilter: null,
          selectedInTheSelector: {
            selectedMarkers: {
              get: localStorage.getItem('selectedInTheSelector') || 'pins',
              set: function (layer) {
                if (layer !== undefined && layer != "undefined") {
                  localStorage.setItem('selectedInTheSelector', layer);
                }
              }
            },
            selected_categories: {
              get:  JSON.parse(localStorage.getItem('selected_categories')) || [],
              set: function (categories) {
                  localStorage.setItem('selected_categories', JSON.stringify(categories));
                }
            },
            selected_timedimension: {
             // Uncomment when releasing timedimensions!
             // get:  JSON.parse(localStorage.getItem('selected_timedimension')) || [],
             get:  [],
              set: function (timedimension) {
                localStorage.setItem('selected_timedimension', JSON.stringify(timedimension));
              }
            },
            baselayer: {
              get: localStorage.getItem('selectedInTheSelectorBaselayer') || 'GoogleStreet',
              set: function (newVal) {
                localStorage.setItem('selectedInTheSelectorBaselayer', newVal)
              }
            },
            overlaylayer: {
              get:    JSON.parse(localStorage.getItem('selectedInTheSelectorOverlaylayers') ) || [],
              set: function (newVal) {
                localStorage.setItem('selectedInTheSelectorOverlaylayers', JSON.stringify(newVal))
              }
            }
          },
          markersOption: {
            timeseriesOptions: function (object) {
              return object.onEachFeaturePopupTimeseries
            },
            handheldsOptions: function (object) {
              return object.onEachFeaturePopupHandhelds
            },
            pinsOptions: function (object) {
              return object.onEachFeaturePopupPins
            }
          }
        }
      }
    },
	}
</script>


<style scoped>

</style>

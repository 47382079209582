<template>
  <!-- add class="page-wrapper" to have the loading spin -->
  <div class="page-wrapper">
    <div id="analysis">
      <!-- classes from bootstrap mostly -->
      <div class="row">
        <div :class="{'col-12':!active, 'col-8':active}">
          <div class="chart-title-div" v-if="chartTitle && (windowWidth > 800 || !active )">
            <h3 class="chart-title"><b>{{ chartTitle }}</b></h3>
          </div>
          <analysis-chart ref="chart_component" v-on:changeTitle="updateTitle($event)"/>
        </div>
        <div v-show="active" :class="{'col-4':active, 'col-12':selectorMobile}">
          <button v-if="windowWidth <= 800" class="btn btn-sm mb-3" @click="backToChart()"> </button>
          <analysis-selector :selectedTimeseries="selectedTimeseries"> </analysis-selector>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import analysisSelector from './analysis/analysisSelector';
  import analysisChart from './analysis/analysisChart';
	export default {
    props: ['selectedTimeseries'],
		name: "analysis",
    components: {analysisSelector, analysisChart},
    data() {
      return {
        // active = toggle to activate selector
        active: true,
        windowWidth: window.innerWidth,
        // selectorMobile = toggle to fully screen for mobile
        selectorMobile: false,
        chartTitle: ''
      }
    },
    created(){
      if (this.windowWidth <= 800) {
        this.active = !this.active
      }
    },
    mounted () {
      // related to vergelijken button
      this.$on('toggleNav', () => {
        this.active = !this.active
      })
    },
    methods: {
      backToChart: function () {
        this.active = !this.active;
        this.selectorMobile = !this.selectorMobile;
        this.$refs.chart_component.showSelectorMobile = false;
      },
      updateTitle(title) {
          this.chartTitle = title;
      }
    }
	}
</script>

<style scoped>
  #analysis {
    padding: 20px;
  }
  .btn {
    padding-left: 28px;
    background-image: url(../assets/images/go_back_blue.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    width: 20px;
    height: 20px;
    display: block;
  }
</style>
<style>
  .chart-title-div {
    position: absolute;
    width: 350px;
  }
  .chart-title {
    font-family: Dosis;
    margin-left: 0;
  }
  .custom-checkbox .custom-control-input:checked~.custom-control-label::before{
    background-color: #00008c !important;
  }
  .highcharts-container hr {
    border-top: 1px solid #F2EFE9;
  }
  .analysis-container .col-form-label {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .analysis-container .custom-control-label::before {
    border: 1px solid #2d97fb !important;
    background-color: transparent !important;
  }
  .analysis-container .custom-checkbox .custom-control-input:checked~.custom-control-label::before{
    border: 1px solid #00eb82 !important;
    background-color: transparent !important;
    background-image: url(../assets/images/check.png);
    background-repeat: no-repeat;
  }
  .analysis-container .custom-checkbox .custom-control-label::before {
    position: absolute;
    left: calc(100% - 20px);
  }
  .analysis-container .custom-control-label {
    position: unset !important;
    display: block;
  }
  .bordenPopupChart .analysis-container {
    border-radius: 8px;
    border-bottom: 1px solid #F2EFE9 !important;
  }
</style>
<template>
  <div>
    <div :style="moduleStyle">
      <div id="content">
        <span :style="classificationColor" class="pin-info-title">{{ parameter_name }}</span>
        <div :style="classificationColor" class="pin-info-image">
            <inline-svg
                    :src="image"
                    width="60"
                    height="60"
            ></inline-svg>
          </div>
        <div :style="classificationColor" class="pin-info-value">{{ value.toFixed(3) }}</div>
      </div>
    </div>
    <div :style="extensionStyle"></div>
  </div>
</template>


<script>
  import i18n from '@/i18n';

  export default {
    props: ['module', 'pxpercm'],
    data () {
      return {
        names: {
          1: i18n.t('settings.battery'),
          2: i18n.t('settings.ec'),
          3: i18n.t('settings.groundwater_level'),
          5: i18n.t('settings.soil_moisture')
        },
        images: {
          1: require('../../assets/images/battery-charging-status.jpg'),
          2: require('../../assets/images/salt.svg'),
          3: require('../../assets/images/ground_water.svg'),
          5: require('../../assets/images/drop.svg')
        },
        mainValues: {
          1: 'battery',
          2: 'ec',
          3: 'waterLevel',
          5: 'soilMoisture'
        },
        defaultColor: "#bebeaf"
      }
    },
    computed: {
      image () {
        return this.images[this.module.type];
      },
      parameter_name () {
        return this.names[this.module.type];
      },
      value () {
        let mainValue = this.mainValues[this.module.type];
        return this.module.values[mainValue]
      },
      classificationColor () {
        if (this.module.classification != null) {
            let color = this.module.classification.color
            return {
                'fill': color,
                'color': color
            }
        } else {
          return {
            'fill': this.defaultColor,
            'color': this.defaultColor
          }
        }
      },
      moduleStyle () {
        let totalPixels = this.module.moduleSize * this.pxpercm;
        let contentPixels = 135;

        return {
            'height': totalPixels + 'px',
            'padding-top': (totalPixels - contentPixels) / 2 + 'px',
        }
      },

      extensionStyle () {
        return {
          'height': this.module.extensionSize * this.pxpercm + 'px'
        }
      }
    }
  }
</script>


<style scoped>
  #content {
    text-align: center;
    padding: 44px 0px;
    margin: auto;
    background-image: url(../../assets/images/pin_datablok.png);
    background-position: center top;
    background-repeat: no-repeat;
    height: 180px !important;
    margin-top: -35px;
  }
  .pin-info-title {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    text-transform: uppercase;
    word-wrap: break-word;
    word-break: break-all;
    color: #bebeaf;
    display: block;
  }
  .pin-info-image {
    height: 65px;
  }
  .pin-info-image svg {
    width: 65px;
    height: auto;
  }
  .pin-info-value {
    font-size: 35px;
    font-weight: 700;
    text-align: center;
    color: #000000;
  }
</style>

<template>
  <div id="savingAlert">
    <b-alert
      :show="successfulAlertCounter"
      dismissible
      variant="success"
      @dismissed="successfulAlertCounter=0"
      fade
    >
    {{ $t('settings.save_success') }}
    </b-alert>
  </div>
</template>


<script>
export default {
  name: 'successfullySavedAlert',
  computed: {
    successfulAlertCounter: {
      get(){
        return this.$store.state.timeseriesSettings.successfulAlertCounter
      },
      set(newVal) {
        this.$store.commit('setSuccessfulAlertCounter', newVal)
      }
    }
  }
}
</script>


<style>
  #savingAlert {
    position: fixed;
    top: 70px;
    right: calc(50% - 127px);
  }
</style>

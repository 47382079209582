<template>
  <div/>
</template>

<script>
  export default {
    created() {
      localStorage.clear();
      this.$store.dispatch('destroyToken')
        .then(() => {
          this.$store.dispatch('resetState').then(() => {
            this.$router.push({ name: 'login' });
          });
        });
    }
  }
</script>

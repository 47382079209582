<template>
  <div>
    <h3 class="text-timeseries text">{{name}} {{ $t('settings.needs_settings') }}</h3>
    <a  class="btn redirect-btn" 
    @click="toSettings(notification.id)">
    {{ $t('settings.to_settings') }}
    </a>
  </div>
</template>


<script>
import {mapActions} from "vuex"
export default {
  props: ["notification"],
  data () {
    const data = this.notification.data
    return {
      name: `${data.name}`,
      url: `/series/?id=${data.id}&name=${data.name}`
    }
  },
  methods: {
    ...mapActions(['completedNotification']),
    toSettings(id) {
      this.completedNotification(id).then(() => {
        this.$router.push(this.url);
      })
    }
  }
}
</script>


<style>
  .redirect-btn {
    margin-top: 12px;
    font-size: 14px !important;
    background-color: #2c97fb !important;
    width: 100%;
    position: absolute;
    bottom: -1px;
    left: 0px;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    color: black !important;
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .redirect-btn:hover {
    border: 1px solid white;
    color: white !important;
  }
  .text-timeseries {
    margin-bottom: 65px !important;
  }
</style>

<template>
  <div class="form-container">
    <div class="page-wrapper login-form">
      <back-button/>
      <form action="#" @submit.prevent="changePassword">
        <div v-if="serverError" class="server-error">{{ serverError }}</div>
        <div class="form-control">
          <input type="password" :placeholder="$t('password.old_password')" name="oldPassword" id="oldPassword" class="login-input" :class="{ 'old-input-error':errors.has('oldPassword') }" v-model="oldPassword" v-validate="'required|min:6'">
          <span class="form-error">{{ errors.first('oldPassword') }}</span>
        </div>
        <div class="form-control">
          <vue-password type="password" :placeholder="$t('password.new_password')" name="New_Password" id="newPassword" :class="{ 'input-error':errors.has('New_Password') }" v-model="newPassword" v-validate="'required|min:8'" ref="password" :strengthMessages="strengthMessages"/>
          <vue-password type="password" :placeholder="$t('password.confirm_password')" name="Confirm_New_Password" id="confirmNewPassword" :class="{ 'input-error':errors.has('Confirm_New_Password') }" v-model="confirmNewPassword" v-validate="'required|confirmed:password'" :disableStrength="true"/>
          <span class="form-error">{{ errors.first('New_Password') }}</span>
          <span class="form-error">{{ errors.first('Confirm_New_Password') }}</span>
        </div>
        <div class="form-control">
          <button type="submit" class="btn-submit-change-pw">{{ $t('password.change_password') }}</button>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
  import VuePassword from 'vue-password';
  import i18n from '@/i18n';
  import BackButton from './BackButton.vue';
  export default {
    name: "change-password",
    components: {
      VuePassword,
      BackButton
    },
    data () {
      return {
        username: '',
        oldPassword: '',
        newPassword: '',
        serverError: '',
        confirmNewPassword: '',
        strengthMessages: [
          i18n.t('password.very_weak'),
          i18n.t('password.weak'),
          i18n.t('password.medium'),
          i18n.t('password.strong'),
          i18n.t('password.very_strong')
        ]
      };
    },
    mounted() {
      const dictionary = {
        en: {
          custom: {
            oldPassword: {
              required: i18n.t('password.old_required'),
              min: i18n.t('password.old_min')
            },
            New_Password: {
              required: i18n.t('password.new_required'),
              min: i18n.t('password.new_min')
            },
            Confirm_New_Password: {
              required: i18n.t('password.confirm_required'),
              min: i18n.t('password.confirm_min'),
              confirmed: i18n.t('password.confirm_match')
            }
          }
        }
      }
      this.$validator.localize(dictionary);
    },
    methods: {
      changePassword () {
        this.$validator.validateAll().then(() => {
          if (!this.errors.any()) {
            this.$store.dispatch('changePassword', {
              username: localStorage.getItem('username'),
              oldPassword: this.oldPassword,
              newPassword: this.newPassword
            })
              .then(() => {
                this.$router.push({name: 'logout'})
              })
              .catch(error => {
                if (error.response.status === 400) {
                  this.serverError = i18n.t('password.http400_message')
                  this.newPassword = '';
                  this.confirmNewPassword = '';
                } else if (error.response.status === 401) {
                  this.serverError = i18n.t('password.http401_message')
                  this.oldPassword = '';
                  this.newPassword = '';
                  this.confirmNewPassword = '';
                }
              })
          }
        })
      }
    }
  }
</script>


<style lang="scss">
  .form-container {
    background-color: #f2efe9;
    height: 100%;
    min-height: calc(100vh - 70px);
    text-align: left;
  }
  label {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 4px;
  }
  .login-heading {
    margin-top: 30px;
    margin-bottom: 7px;
  }
  .form-control {
    border: none !important;
    padding: 0 !important;
    background-color: transparent !important;
  }
  .form-control {
    margin-bottom: 50px;
  }
  #confirmNewPassword {
    margin-bottom: 8px !important;
  }
  .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .login-form {
    width: calc(100% - 60px);
    max-width: 500px;
    margin: auto;
  }
  @media screen and (max-width: 800px) {
    .login-form {
      margin-bottom: 90px;
    }
  }
  .VuePassword:first-child {
    height: 90px;
  }
  .login-input, .VuePassword__Input input {
    font-family: 'Dosis', sans-serif;
    font-size: 20px;
    font-weight: 400;
    width: 100%;
    padding: 10px 0 10px 14px !important;
    outline: 0;
    border-radius: 0;
    border: none !important;
    border-bottom: 2px solid #e5e5e5 !important;
    background-color: #ffffff !important;
    -webkit-appearance: none;
  }
  .login-input:focus, .VuePassword__Input input:focus {
    border-bottom: 2px solid #00eb82 !important;
  }
  .VuePassword__Input input:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .VuePassword__Input input {
    height: 52px;
  }
  .VuePassword__Toggle__Icon {
    width: 22px;
    color: #00008c;
}
  .VuePassword__Meter {
    margin-top: -40px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    height: 4px !important;
  }
  .VuePassword__Message {
    padding-left: 0px;
    padding-right: 0px;
  }
  .btn-submit-change-pw {
    font-family: 'Dosis', sans-serif;
    font-size: 20px;
    font-weight: 400;
    width: 100%;
    padding: 14px 12px;
    margin-top: 120px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    background-color: #2e98fc;
    -webkit-appearance: none;
    cursor: pointer;
    &:hover {
      background-color: darken(#2e98fc, 10%);
    }
    &:disabled {
      background-color: lighten(#2e98fc, 25%);
      cursor: not-allowed;
    }
  }
  .server-error {
    font-family: 'Dosis', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: red;
    width: 100%;
    padding: 10px 0 10px 0;
    outline: 0;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid red;
    -webkit-appearance: none;
  }
  .form-error {
    font-size: 14px;
    color: red;
    display: block;
  }
  .input-error input {
    border: none;
    border-bottom: 2px solid red !important;
  }
  .old-input-error {
    border: none;
    border-bottom: 2px solid red !important;
  }
</style>

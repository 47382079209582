import Vue from 'vue'
import Router from 'vue-router'
import Main_map_container from '@/components/Main_map_container'
import Analysis from '@/components/Analysis'
import Login from '@/components/auth/Login'
import Logout from '@/components/auth/Logout'
import ContactUs from '@/components/menu/Contact_us'
import MyProfile from '@/components/menu/MyProfile'
import ChangePassword from '@/components/menu/ChangePassword'
import Infographic from '@/components/infographic/pin'
import Devices from '@/components/infographic/devices'
import Terms from '@/components/terms/Terms'
import Settings from '@/components/menu/Settings'
import Actual from '@/components/Actual'
import MeasurementsList from '@/components/lists/MeasurementsList'
import MeasurementsListActual from '@/components/lists/MeasurementsListActual'
import Borden from '@/components/Borden'
import Bord from '@/components/boards/board'
import Home from '@/components/Home'
import Notifications from '@/components/notifications/Notifications'
import DeviceTimeseriesList from '@/components/settings/timeseries/DeviceTimeseriesList'
import TimeseriesSettings from '@/components/settings/timeseries/TimeseriesSettings'
import scenarioOverview from '@/components/scenario/scenarioOverview'
import scenarioCreate from '@/components/scenario/scenarioCreate'

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
       requiresAuth: true,
      }
    },
    {
      path: '/actual',
      name: 'actual',
      component: Actual,
      meta: {
       requiresAuth: true,
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      props: true,
      meta: {
        requiresVisitor: true,
      }
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
    },
    {
      path: '/analysis',
      name: 'analysis',
      component: Analysis,
      props: true,
      meta: {
       requiresAuth: true,
      }
    },
		{
      path: '/contact-us',
      name: 'contact-us',
      component: ContactUs,
      props: true,
      meta: {
       requiresAuth: true,
      }
    },
    {
      path: '/devices',
      name: 'devices',
      component: Devices,
      meta: {
       requiresAuth: true,
      }
    },
    {
      path: '/infographic',
      name: 'infographic',
      component: Infographic,
      props: true,
      meta: {
       requiresAuth: true,
      }
    },
    {
      path: '/my-profile',
      name: 'my-profile',
      component: MyProfile,
      meta: {
       requiresAuth: true,
      }
    },
    {
      path: '/terms',
      name: 'terms',
      component: Terms,
      meta: {
       requiresAuth: true,
      }
    },
    {
      path: '/change-password',
      name: 'change-password',
      component: ChangePassword,
      meta: {
       requiresAuth: true,
      }
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: {
       requiresAuth: true,
      }
    },
    {
      path: '/map',
      name: 'main_map_container',
      component: Main_map_container,
      meta: {
       requiresAuth: true,
      }
    },
    {
      path: '/measurements',
      name: 'measurementsList',
      component: MeasurementsList,
      props: true,
      meta: {
       requiresAuth: true,
      }
    },
    {
      path: '/measurements',
      name: 'measurementsListActual',
      component: MeasurementsListActual,
      props: true,
      meta: {
       requiresAuth: true,
      }
    },
    {
      path: '/borden',
      name: 'borden',
      component: Borden,
      props: true,
      meta: {
       requiresAuth: true,
      }
    },
    {
      path: '/bord',
      name: 'bord',
      component: Bord,
      props: true,
      meta: {
        requiresAuth: true,
      }
    },

    {
      path: '/notifications',
      name: 'notifications',
      component: Notifications,
      props: true,
      meta: {
       requiresAuth: true,
      }
    },
    {
      path: '/timeseries',
      name: 'deviceTimeseriesList',
      component: DeviceTimeseriesList,
      props: true,
      meta: {
       requiresAuth: true,
      }
    },
    {
      path: '/series',
      name: 'timeseriesSettings',
      component: TimeseriesSettings,
      props: true,
      meta: {
       requiresAuth: true,
      }
    },
    {
      path: '/scenario-overview',
      name: 'scenarioOverview',
      component: scenarioOverview,
      meta: {
       requiresAuth: true,
      }
    },
    {
      path: '/scenario-create',
      name: 'scenarioCreate',
      component: scenarioCreate,
      meta: {
       requiresAuth: true,
      }
    },
  ],
  mode: 'history'
})
// For the progress bar on top of navbar
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()

    var ignoreRoutes = ["/bord", "/home", "/login", "/logout", "/"];

    if (!ignoreRoutes.includes(to.path)) {
      localStorage.setItem('previous_visited_page', from.path);
      localStorage.setItem('last_visited_page', to.path);
    }
  }
  next()
})

// eslint-disable-next-line
router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done(true)
})

export default router

<template>
  <div id='page-wrapper nzgmeet'>
    <header id='main-header'>
      <div class='row sticky-menu'>
        <div class='blocks-container '>
          <div class="block logo">
            <img id='logo'
              src="../../../assets/images/Fixeau_logo.png" class='logo' title='logo'>
          </div>
        </div>
      </div>
    </header>
    <main>
      <div class='row rij_1' :style="{ backgroundImage: `url(${backgroundUrl})` }">
        <div class='blocks-container'>
          <div class="block rechts">
            <div class="content">
              <div class="titel"> <h1> <span style="padding-left:90px; padding-right:90px;"> NZGmeet </span> </h1> </div>
                <div v-if="!showLogin" class="tekst"> <p style="font-weight: 400;">NZGmeet is het meetplatform met informatie en advies voor agrariërs en waterbeheerders uit het Noordelijk Zandgebied (NZG) in de kop van Noord-Holland. Al bijna 5 jaar meten agrariërs hier aan zoutgehalten in sloten en boezems om zo de waterkwaliteit voor beregening in de gaten te houden. In de beginjaren waren metingen zichtbaar dankzij het initiatief ‘Inlaat op Maat’. Binnen het programma Boeren Meten Water krijgt dit meetinitiatief een vervolg. De agrariërs zetten met behulp van de Aqua Mobile en de Aqua Pin hun zoutmetingen voort en maken ook de komende jaren samen met het waterschap de waterkwaliteit in het gebied inzichtelijk op perceel en in de sloot. Het meten van de waterkwaliteit draagt bij aan het vergroten van inzicht in het gebied en aan de optimalisatie van de inlaathoeveelheid zoetwater, op de juiste locatie en het juiste moment.</p>
                  <p style="font-weight: 400;">Op het Fixeau platform zijn de metingen uit het Noordelijk Zandgebied online beschikbaar en is voor direct betrokkenen (via inlog) extra informatie beschikbaar zoals voorspellingen van verwachte grondwaterstand en bodemvocht voor de komende dagen.</p>
                  <p style="font-weight: 400;"></p>
                </div>

                <div v-if="showLogin" id="login-container"  class="tekst">
                  <p  v-if="windowWidth > 800" style="font-weight: 400;"></p>
                    <form action="#" @submit.prevent="$parent.login">
                    <h2>Login</h2>
                    <div v-if="$parent.serverError" class="server-error">{{ $t('login.login_failed') }}</div>

                    <div class="form-control">
                      <img class="input-icon" src="@/assets/images/mail_green.png" alt="" /><input type="text" placeholder="Gebruikersnaam" name="username" id="username" class="login-input form-mail" :class="{ 'input-error': this.$parent.errors.has('username') }" v-model="$parent.username" v-validate="'required'">
                      <span class="form-error">{{ $parent.errors.first('username') }}</span>
                    </div>

                    <div class="form-control">
                      <img class="input-icon" src="@/assets/images/lock_green.png" alt="" /><input type="password" placeholder="Wachtwoord" name="password" id="password" class="login-input form-pass" :class="{ 'input-error': this.$parent.errors.has('password') }" v-model="$parent.password" v-validate="'required|min:6'">
                      <span class="form-error">{{  $parent.errors.first('password') }}</span>
                    </div>

                    <div class="submit-buttons">
                      <button type="submit" class="btn-submit">Inloggen</button>
                      <button @click="showLogin=false" id="back" class="btn-submit">Terug</button>
                    </div>
                  </form>
                </div>

              <div class="buttons"  v-if="!showLogin" >
                <a @click="showLogin=true"> Inloggen portaal </a>
                <!--<a href="?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3MzM4MzkzNDUsImVtYWlsIjoiIiwidXNlcl9pZCI6MjQ1LCJ1c2VybmFtZSI6InB1YmxpYyJ9.V7uXcI5E4x_SABegtgrrQD8tNjx_y1pWNuO0J2U26cU"> Openbaar </a>-->
                <a @click="$parent.publicLogin('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IiIsInVzZXJfaWQiOjIxNywiZXhwIjoxNzE2NjQwNzM5LCJ1c2VybmFtZSI6Im56Z21lZXQifQ.9AHpj7H_L1YW7ZH0dh2yS5GN6OWHkRUayzzeDuHeXNw')" > Openbaar </a>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </main><!-- #main -->
    <footer id='main-footer'>
      <div class='row'>
        <div class='full-row'>
          <div class='blocks-container'>
            <div class='block left'>
              <p> Contact: info@fixeau.com </p>
            </div> <!-- block -->
            <div class="block right">
              <div class="linkjes">
                <a target="_blank" href="./Fixeau - Privacyverklaring - 2019.pdf"> Privacy </a>
                <a target="_blank" href="./Licentie%20en%20voorwaarden%20Fixeau%20Platform%202019.pdf"> Algemene voorwaarden </a>
              </div>
            </div>
          </div> <!-- blocks-container -->
        </div> <!-- full-row -->
      </div> <!-- row -->
    </footer>
  </div> <!-- page-wrapper -->
</template>

<script>
  import backgroundUrl from '../../..//assets/images/background-nzg.jpg'

  export default {
    name: "nzgmeet",
    data() {
      return {
        showLogin: false,
        backgroundUrl,
        windowWidth: window.innerWidth,
      }
    },
    created() {
      document.title = 'NZGmeet';
    }
  }
</script>

<style scoped>

/*
Theme Name: Minimal210
Theme URI: https://www.buro210.nl/
Author: BURO210
Author URI: https://profiles.wordpress.org/wilcodeveloper
Description: Krachtig Lichtgewicht
Version: 1.3
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: minimal210

/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/
  html {
    font-family: sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust:     100%;
  }

  body {
    margin: 0;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }

  audio,
  canvas,
  progress,
  video {
    display: inline-block;
    vertical-align: baseline;
  }

  audio:not([controls]) {
    display: none;
    height: 0;
  }

  [hidden],
  template {
    display: none;
  }

  a {
    background-color: transparent;
  }

  a:active,
  a:hover {
    outline: 0;
  }

  abbr[title] {
    border-bottom: 1px dotted;
  }

  b,
  strong {
    font-weight: bold;
  }

  dfn {
    font-style: italic;
  }

  h1 {
    font-size: 2em;
    margin: 0;
  }

  mark {
    background: #ff0;
    color: #000;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25em;
  }

  img {
    border: 0;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  figure {
    margin: 1em 40px;
  }

  hr {
    box-sizing: content-box;
    height: 0;
  }

  pre {
    overflow: auto;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit;
    font: inherit;
    margin: 0;
  }

  button {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }

  button[disabled],
  html input[disabled] {
    cursor: default;
  }

  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  input {
    line-height: normal;
  }

  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
  }

  legend {
    border: 0;
    padding: 0;
  }

  textarea {
    overflow: auto;
  }

  optgroup {
    font-weight: bold;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  td,
  th {
    padding: 0;
  }

  /*--------------------------------------------------------------
    # Typography
    --------------------------------------------------------------*/
  body,
  button,
  input,
  select,
  textarea {
    color: #404040;
    font-family: sans-serif;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    clear: both;
  }

  p {
    margin-bottom: 1.5em;
  }

  dfn,
  cite,
  em,
  i {
    font-style: italic;
  }

  blockquote {
    margin: 0 1.5em;
  }

  address {
    margin: 0 0 1.5em;
  }

  pre {
    background: #eee;
    font-family: "Courier 10 Pitch", Courier, monospace;
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1.6em;
  }

  code,
  kbd,
  tt,
  var {
    font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
    font-size: 15px;
    font-size: 0.9375rem;
  }

  abbr,
  acronym {
    border-bottom: 1px dotted #666;
    cursor: help;
  }

  mark,
  ins {
    background: #fff9c0;
    text-decoration: none;
  }

  big {
    font-size: 125%;
  }

  /*--------------------------------------------------------------
    # Elements
    --------------------------------------------------------------*/
  html {
    box-sizing: border-box;
  }

  body {
    background: #fff; /* Fallback for when there is no custom background color defined. */
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
  }

  blockquote,
  q {
    quotes: "" "";
  }

  hr {
    background-color: #ccc;
    border: 0;
    height: 1px;
    margin-bottom: 1.5em;
  }

  ul,
  ol {
    margin: 0 0 1.5em 3em;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  li > ul,
  li > ol {
    margin-bottom: 0;
    margin-left: 1.5em;
  }

  dt {
    font-weight: bold;
  }

  dd {
    margin: 0 1.5em 1.5em;
  }

  img {
    height: auto; /* Make sure images are scaled correctly. */
    max-width: 100%; /* Adhere to container width. */
  }

  figure {
    margin: 1em 0; /* Extra wide images within figure tags don't overflow the content area. */
  }

  table {
    margin: 0 0 1.5em;
    width: 100%;
  }

  /*--------------------------------------------------------------
    # Forms
    --------------------------------------------------------------*/
  button,
  input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    border: none;
    border-radius: 0;
    font-size: 12px;
    line-height: 1;
  }

  button:hover,
  input[type="button"]:hover,
  input[type="reset"]:hover,
  input[type="submit"]:hover {
    border-color: #ccc #bbb #aaa;
  }

  button:focus,
  input[type="button"]:focus,
  input[type="reset"]:focus,
  input[type="submit"]:focus,
  button:active,
  input[type="button"]:active,
  input[type="reset"]:active,
  input[type="submit"]:active {
    border-color: #aaa #bbb #bbb;
    outline:none;
  }

  input[type="text"],
  input[type="email"],
  input[type="url"],
  input[type="password"],
  input[type="search"],
  input[type="number"],
  input[type="tel"],
  input[type="range"],
  input[type="date"],
  input[type="month"],
  input[type="week"],
  input[type="time"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="color"],
  textarea {
    color: #666;
    border: none;
    border-radius: 0;
    padding: 3px;
  }

  select {
    border: 1px solid #ccc;
  }

  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="url"]:focus,
  input[type="password"]:focus,
  input[type="search"]:focus,
  input[type="number"]:focus,
  input[type="tel"]:focus,
  input[type="range"]:focus,
  input[type="date"]:focus,
  input[type="month"]:focus,
  input[type="week"]:focus,
  input[type="time"]:focus,
  input[type="datetime"]:focus,
  input[type="datetime-local"]:focus,
  input[type="color"]:focus,
  textarea:focus {
    color: #111;
    outline:none;
  }

  textarea {
    width: 100%;
  }

  .site-main .comment-navigation,
  .site-main .posts-navigation,
  .site-main .post-navigation {
    margin: 0 0 1.5em;
    overflow: hidden;
  }

  .comment-navigation .nav-previous,
  .posts-navigation .nav-previous,
  .post-navigation .nav-previous {
    float: left;
    width: 50%;
  }

  .comment-navigation .nav-next,
  .posts-navigation .nav-next,
  .post-navigation .nav-next {
    float: right;
    text-align: right;
    width: 50%;
  }

  /*--------------------------------------------------------------
    # Accessibility
    --------------------------------------------------------------*/
  /* Text meant only for screen readers. */
  .screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
  }

  .screen-reader-text:focus {
    background-color: #f1f1f1;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    color: #21759b;
    display: block;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000; /* Above WP toolbar. */
  }

  /* Do not show the outline on the skip link target. */
  #content[tabindex="-1"]:focus {
    outline: 0;
  }

  /*--------------------------------------------------------------
    # Alignments
    --------------------------------------------------------------*/
  .alignleft {
    display: inline;
    float: left;
    margin-right: 1.5em;
  }

  .alignright {
    display: inline;
    float: right;
    margin-left: 1.5em;
  }

  .aligncenter {
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  /*--------------------------------------------------------------
    # Clearings
    --------------------------------------------------------------*/
  .clear:after{
    clear: both;
  }

  /*--------------------------------------------------------------
    # Widgets
    --------------------------------------------------------------*/

  /* Make sure select elements fit in widgets. */
  .widget select {
    max-width: 100%;
  }

  /*--------------------------------------------------------------
    # Content
    --------------------------------------------------------------*/
  /*--------------------------------------------------------------
    ## Posts and pages
    --------------------------------------------------------------*/
  .sticky {
    display: block;
  }

  .hentry {
    margin: 0 0 1.5em;
  }

  .byline,
  .updated:not(.published) {
    display: none;
  }

  .single .byline,
  .group-blog .byline {
    display: inline;
  }

  .page-content,
  .entry-content,
  .entry-summary {
    margin: 1.5em 0 0;
  }

  .page-links {
    clear: both;
    margin: 0 0 1.5em;
  }

  /*--------------------------------------------------------------
    ## Comments
    --------------------------------------------------------------*/
  .comment-content a {
    word-wrap: break-word;
  }

  .bypostauthor {
    display: block;
  }

  /*--------------------------------------------------------------
    # Infinite scroll
    --------------------------------------------------------------*/
  /* Globally hidden elements when Infinite Scroll is supported and in use. */
  .infinite-scroll .posts-navigation, /* Older / Newer Posts Navigation (always hidden) */
  .infinite-scroll.neverending .site-footer { /* Theme Footer (when set to scrolling) */
    display: none;
  }

  /* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before. */
  .infinity-end.neverending .site-footer {
    display: block;
  }

  /*--------------------------------------------------------------
    # Media
    --------------------------------------------------------------*/
  .page-content .wp-smiley,
  .entry-content .wp-smiley,
  .comment-content .wp-smiley {
    border: none;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
  }

  /* Make sure embeds and iframes fit their containers. */
  embed,
  iframe,
  object {
    max-width: 100%;
  }

  /*--------------------------------------------------------------
    ## Captions
    --------------------------------------------------------------*/
  .wp-caption {
    margin-bottom: 1.5em;
    max-width: 100%;
  }

  .wp-caption img[class*="wp-image-"] {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .wp-caption .wp-caption-text {
    margin: 0.8075em 0;
  }

  .wp-caption-text {
    text-align: center;
  }

  .gallery-caption{
  }

  /* Minimal210 Standard CSS */

  /* Header */
  #main-header{

  }

  #main-header .blocks-container{

    position: relative;
  }

  #main-slider{

    max-width: 100%;
    overflow: hidden;
  }

  h1.site-title{

    font-size:20px;
    display: inline-block;
  }

  h1.site-title a{

    text-decoration: none;
  }

  .logo{

    display: inline-block;
  }

  #logo{
    height: 79px !important;
    width: 60px !important;
    max-height: 79px !important;
    max-width: 60px !important;
  }

  a,a:focus{

    outline:none;
  }

  .center{

    text-align: center;
  }

  #main-navigation{

    display: inline-block;
  }

  #main-navigation ul{

    list-style: none;
    padding:0;
    margin:0;

    /* Remove white space inline-block */
    font-size: 0;
  }

  #main-navigation ul li{

    display: inline-block;
    font-size: 16px;
  }

  #main-navigation ul li a{

    text-decoration: none;
  }

  .full,
  .full-row,
  .full-container{
    width:750px;
    margin: 0 auto;
    max-width: 1080px;
    padding: 0 35px;
  }

  .full-row .linkjes, .full-row  .left p {
    font-size:16px !important;
  }

  #main-navigation{

    margin-right: 16px;
  }

  /* Search Icon */

  #theme-search{

    position: absolute;
    right: 0;
    top:0;
    height: 100%;
    line-height: 1;
    width:16px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  #theme-search i.fa{

    cursor:pointer;
  }

  #theme-search + .search-form{

    display: none;
    width:100%;
    position: absolute;
    top:100%;
    z-index: 10;
    height: 30px;
  }

  #primary-menu{

    display: inline-block;
  }

  #main-header{

    background: #fff;
  }

  /* Sticky header */
  .sticky-active{

    position: fixed;
    top: 0;
    width:100%;
    left:0;
    right:0;
    bottom:auto;
    z-index: 99;
    background: #fff;
  }

  header .menu > li{

    position: relative;
  }

  header .menu > li.menu-item-has-children ul{
    display: none;
    left: 0;
    opacity: 0;
    position: absolute;
    z-index: 999;
  }
  header .menu > li.menu-item-has-children:hover ul{
    display: block!important;
    opacity: 1!important;
  }

  header .menu > li > ul > li{

    display: block!important;
  }

  .relative{

    position: relative;
  }

  .cover{

    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  /* submenu's */
  ul.menu > li{
    position: relative;
  }
  ul.menu > li > a{
    display: inline-block;
  }
  ul.menu > li ul.sub-menu{
    left: 0%;
    top: 100%;
  }
  ul.menu > li ul.sub-menu > li{
    display: block!important;
    position: relative;
  }
  ul.menu > li ul.sub-menu > li > a{
    display: inline-block;
    white-space: nowrap;
  }
  ul.menu > li ul.sub-menu > li ul.sub-menu{
    opacity: 0 !important;
    display: none !important;
  }
  ul.menu > li ul.sub-menu > li:hover > ul.sub-menu{
    left: 100%;
    top: 0;
    opacity: 1 !important;
    display: block !important;
  }


@import url('https://fonts.googleapis.com/css?family=Dosis:700&display=swap'); @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap'); #main-header { background:none; position:absolute; width:100%; top:0; z-index:999; }

#main-header .row { background:transparent !important; padding:30px 0px; }

#main-header .row .blocks-container .block.logo { margin-left:30px; max-width:25%; float:left; }

#main-header .row .blocks-container .block.logo img { width:auto; height:auto; max-width:100%; max-height:104px; display:inline-block; }

@media (max-width:780px) { #main-header { margin-top:0px !important; }

  #main-header .row.sticky-active { top:0px !important; position:relative; }

}

html .scss_errors { position:fixed; z-index:9999999; left:0; right:0; top:50%; transform:translateY(-50%); }

html .scss_errors pre { padding-top:100px; padding-bottom:100px; -webkit-box-shadow:0px 0px 0px 5000px rgba(0, 0, 0, 0.8); -moz-box-shadow:0px 0px 0px 5000px rgba(0, 0, 0, 0.8); box-shadow:0px 0px 0px 5000px rgba(0, 0, 0, 0.8); margin-bottom:0px; white-space:normal; }

.blocks-container::after { content:''; display:block; clear:both; }

.blocks-container .block-group:after { content:''; display:block; clear:both; }

.row.rij_1 { position:relative; overflow:hidden; min-height:92vh; background-size:cover; background-repeat:no-repeat; background-position:center center; }

.row.rij_1 .blocks-container .block.rechts { width:40%; float:right; }

.row.rij_1 .blocks-container .block.rechts .content { position:relative; z-index:1; padding:140px 100px 30px 0px; box-sizing:border-box; width:100%; float:left; text-align:center; }

.row.rij_1 .blocks-container .block.rechts .content .titel { width:100%; float:left; padding-bottom:40px; box-sizing:border-box; }

.row.rij_1 .blocks-container .block.rechts .content .titel h1 { font-size:45px; font-family:'Dosis', sans-serif; line-height:52px; color:#fff; display:inline-block; position:relative; }

.row.rij_1 .blocks-container .block.rechts .content .titel h1 span { color:#00eb82; }

.row.rij_1 .blocks-container .block.rechts .content .titel h1:after { content:''; display:inline-block; position:absolute; width:40%; height:4px; background:#fff; bottom:-20px; left:50%; transform:translateX(-50%); }

.row.rij_1 .blocks-container .block.rechts .content .tekst { width:100%; float:left; }

.row.rij_1 .blocks-container .block.rechts .content .tekst h1,.row.rij_1 .blocks-container .block.rechts .content .tekst h2,.row.rij_1 .blocks-container .block.rechts .content .tekst h3,.row.rij_1 .blocks-container .block.rechts .content .tekst h4,.row.rij_1 .blocks-container .block.rechts .content .tekst h5,.row.rij_1 .blocks-container .block.rechts .content .tekst h6 { margin:0px; padding:0px 0px 10px 0px; font-size:20px; line-height:24px; color:#00eb82; font-family:'Roboto', sans-serif; }

.row.rij_1 .blocks-container .block.rechts .content .tekst p { margin:0px; padding:0px 0px 20px 0px; font-size:18px; line-height:24px; color:#fff; font-family:'Roboto', sans-serif; }

.row.rij_1 .blocks-container .block.rechts .content .tekst a { text-decoration:none; color:#00eb82; }

.row.rij_1 .blocks-container .block.rechts .content .tekst a:hover { color:#fff; }

.row.rij_1 .blocks-container .block.rechts .content .buttons { width:100%; float:left; padding-top:20px; }

.row.rij_1 .blocks-container .block.rechts .content .buttons a { display:inline-block; margin:0px 20px; padding:10px 25px; text-decoration:none; color:#fff; font-size:18px; font-family:'Roboto', sans-serif; line-height:1; margin-bottom:20px; -moz-transition:0.3s ease; -o-transition:0.3s ease; -ms-transition:0.3s ease; -webkit-transition:0.3s ease; transition:0.3s ease; }

.row.rij_1 .blocks-container .block.rechts .content .buttons a:nth-child(2n+1) { background-color:#14337e; }

.row.rij_1 .blocks-container .block.rechts .content .buttons a:nth-child(2n+1):hover { background-color:#2e98fc; }

.row.rij_1 .blocks-container .block.rechts .content .buttons a:nth-child(2n+2) { background-color:#2e98fc; }

.row.rij_1 .blocks-container .block.rechts .content .buttons a:nth-child(2n+2):hover { background-color:#14337e; }

.row.rij_1 .blocks-container .block.rechts:before { content:''; display:block; position:absolute; left:calc(40% - 5%); bottom:0; width:25%; height:100%; background:linear-gradient(to top left, #000 50%, transparent 50%); opacity:0.75; }

.row.rij_1 .blocks-container .block.rechts:after { content:''; display:block; position:absolute; bottom:0; width:100%; height:100%; background:rgba(0, 0, 0, 0.75); }

@media (max-width:1280px) { .row.rij_1 .blocks-container .block.rechts { width:50%; }

  .row.rij_1 .blocks-container .block.rechts:before { left:calc(50% - 25%); }

}

@media (max-width:1080px) { .row.rij_1 .blocks-container .block.rechts { width:60%; }

  .row.rij_1 .blocks-container .block.rechts:before { left:calc(60% - 45%); }

}

@media (max-width:780px) { .row.rij_1 .blocks-container .block.rechts { width:100%; }

  .row.rij_1 .blocks-container .block.rechts .content { margin:0 auto; padding:140px 35px 0 35px; }

  .row.rij_1 .blocks-container .block.rechts:before { display:none; }

}

@media (max-width:680px) { .row.rij_1 .blocks-container .block.rechts .content .titel h1 { font-size:35px; line-height:42px; }

}
map-chart-top
#main-footer .row { background-color:#9b9b9b; padding:9px 0px; }

#main-footer .row .blocks-container .block.left { width:50%; float:left; }

#main-footer .row .blocks-container .block.left p { margin:0px; color:#707070; font-family:'Roboto', sans-serif; }

#main-footer .row .blocks-container .block.right { width:50%; float:right;  text-align:right; }

#main-footer .row .blocks-container .block.right .linkjes a { margin:0px; color:#707070; font-family:'Roboto', sans-serif; text-decoration:none; padding-left:30px; }

#main-footer .row .blocks-container .block.right .linkjes a:hover { color:#00eb82; }

@media (max-width:780px) { #main-footer .row .blocks-container .block.left { width:100%; margin-bottom:20px; text-align:center; }

  #main-footer .row .blocks-container .block.right { width:calc(100%); margin-left:0px; text-align:center; }

}

/*# sourceMappingURL=main.css.map */
  body.nzgmeet {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  #login-container {
    float:left;
    width:100%;
  }

  #login-container form{
    width: 400px;
    margin-left: -200px;
    left: 50%;
    position: relative;
  }

  #login-container form h2{
    color:#00eb82;
  }

  #login-container form input{
    margin-top:5px;
  }

  .buttons a {
    cursor:pointer;
  }

  .submit-buttons button {
    display: inline-block;
    margin: 0px 20px;
    padding: 10px 25px;
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    line-height: 1;
    margin-bottom: 20px;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
  }

  .submit-buttons #back {
    float:right;
    background-color: #2e98fc;
  }

  .submit-buttons #back:hover {
    background-color: #14337e;
  }

  .submit-buttons .btn-submit {
    background-color: #14337e;
  }


  .submit-buttons .btn-submit:hover {
    background-color: #2e98fc;
  }

  @media (min-width:1580px) {
    #login-container p {
      width: 1480px;
    }
  }

  @media (max-width:1579px) {
    #login-container p {
      width: 1300px;
    }
  }

  @media (max-width:1410px) {
    #login-container p {
      width: 1100px;
    }
  }


  @media (max-width:1210px) {
    #login-container p {
      width: 980px;
    }
  }


  @media (max-width:1080px) {
    #login-container p {
      width: 720px;
    }
  }

  @media (max-width:820px) {
    #login-container p {
      width: 600px;
    }
  }

  @media (max-width:650px) {
    #login-container form{
      width: 300px;
      margin-left: -160px;
      left: 50%;
      position: relative;
    }

    #login-container p {
      width: 280px;
    }
  }




   .row {
    padding:10px 0px;
     background-color: #9b9b9b;
    margin-left:1px !important;
    margin-right:1px !important;
  }

</style>

<template>
  <!-- id should be the same like in index.html -->
  <div id="app">
    <Navbar></Navbar>
    <InstructionModal></InstructionModal>
    <cookie-law v-show="showCookieBanner"
                :message="$t('cookie.message')"
                :buttonText="$t('cookie.accept')"
                :buttonDecline="true"
                :buttonDeclineText="$t('cookie.decline')"
                ref="cookielaw"
                theme="base"
                v-on:accept="enableGoogleAnalyticsPlugin()">
    </cookie-law>
  </div>
</template>


<script>
  import Navbar from './components/nav/Navbar'
  import InstructionModal from './components/instructions/InstructionModal'
  import CookieLaw from 'vue-cookie-law'
  import { bootstrap } from 'vue-gtag'

  export default {
      name: 'app',
      components: {
        Navbar,
        InstructionModal,
        CookieLaw
      },
      computed: {
        googleAnalyticsAllowed() {
          //If accepted the cookie banner.
          return (this.$refs.cookielaw && this.$refs.cookielaw.isAccepted());
        },
        showCookieBanner() {
          //Dont show: If cookie banner is accepted
          if (this.googleAnalyticsAllowed){
            return false;
          }
          //Dont show: If cookie banner is declined.
          if (this.$refs.cookielaw && !this.$refs.cookielaw.isAccepted()) {
            return false;
          }
          return true;
        }
      },
      mounted() {
        //Only accept Google Analytics plugin if allowed.
        if (this.googleAnalyticsAllowed) {
          this.enableGoogleAnalyticsPlugin();
        }
      },
      methods: {
        enableGoogleAnalyticsPlugin () {
          bootstrap().then(() => {
            //Google Analytics enabled.
          })
        }
      }
  }
</script>


<style lang="scss">
  // General styling for the whole project
  @import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css");
  @import url('https://fonts.googleapis.com/css?family=Dosis:400,700|Poppins:400,700');
  // duration animation time between routing pages
  .page-wrapper {
    animation-duration: 0.1s;
  }

  body {
    background-color: #f2efe9 !important;
  }
  div, p, span, h1, h2, h3, h4, h5, h6, img, ol, ul, li, form {
    font-family: 'Dosis', sans-serif;
    font-weight:400;
    color: #000000;
    margin: 0;
    padding: 0;
    border: 0;
  }
  h1 {
    font-size: 45px;
    line-height: 50px;
    font-weight:700;
  }
  h2 {
    font-size: 35px;
    line-height: 40px;
    font-weight:700;
  }
  h3 {
    font-size: 30px;
    line-height: 35px;
    font-weight:700;
  }
  h4 {
    font-size: 20px;
    line-height: 25px;
    font-weight:700;
  }
  p, div {
    font-size: 20px;
    line-height: 28px;
  }
  .Cookie__button--decline {
    cursor:pointer;
  }
</style>

<template>
  <table>
    <tr>
      <td>{{ $t('action.groundwater_recharge') }}</td>
      <td><input class="action-input" type="number" v-model="sprinkleAmount"> mm</td>
    </tr>
    <tr>
      <td>{{ $t('action.level_set') }}</td>
      <td><input class="action-input" type="number" v-model="level"> mNAP</td>
    </tr>
    <tr>
      <td>{{ $t('datetime.date') }}</td>
      <td><input class="date-input" v-model="date"></td>
      <td><button id="action-btn" class="btn save-btn" @click="forecast()">{{ $t('action.apply') }}</button></td>
    </tr>
  </table>
</template>

<script>
  export default {
    data() {
      return {
        sprinkleAmount: null,
        level: null,
        date: ''
      }
    },
    methods : {
      forecast() {
        let params = {};
        if (this.sprinkleAmount || this.sprinkleAmount === 0) {
          params['c_pn0'] = 0.001 * this.sprinkleAmount;  // convert mm to m
        }
        if (this.level || this.level === 0) {
          params['hp'] = this.level;
        }
        if (this.date) {
          params['date'] = this.date;
        }
        this.$parent.$parent.$refs.chart_component.loadModifiedForecast(params);
      },
    }
  }
</script>

<style>
  .action-input {
    width: 70px;
  }
  .date-input {
    width: 140px;
  }
  #action-btn {
    margin: 10px;
    font-size: 12px;
    border: solid black 1px;
    color: #00008c;
    background-color: #f2efe9;
  }
</style>

<template>
    <div>
        <!--<transition name="router-animation" enter-active-class="animated slideInDown" leave-active-class="animated slideOutDown" mode="out-in">
            <modal name="bordenPopupMap" class="bordenPopupMap" :adaptive="true" width="1000px" height="600px" @before-close="beforeClose">
                <div class="topPopup">
                    <button class="btn-annuleren" @click="$modal.hide('bordenPopupMap')">
                        ANNULEREN
                    </button>
                </div>
                <Map class="popup-map" :mapConfig="mapConfigPopup"></Map>
                <button class="save-btn btn" @click="saveMapConfig">OPSLAAN</button>
            </modal>
        </transition>

        <transition name="router-animation" enter-active-class="animated slideInDown" leave-active-class="animated slideOutDown" mode="out-in">
            <modal name="bordenPopupChart" class="bordenPopupChart" :adaptive="true" :scrollable="true" width="1000px" height="600px" @before-close="beforeClose">
                <div class="topPopup">
                    <button class="btn-annuleren" @click="$modal.hide('bordenPopupChart')">
                        ANNULEREN
                    </button>
                </div>
                <analysis class="contentPopupChart" :selectedTimeseries="selected_borden_chart_config"> </analysis>
                <button class="save-btn" @click="saveChartConfig(activeChart)">OPSLAAN</button>
            </modal>
        </transition>-->



        <div class="row borden-container">
            <div class="col-12">
                <div class="board-top">
                    <router-link v-if="$store.state.boards.length > 1" :to="{ name: 'borden', params: {'back_button' : true} }"><img src="../../assets/images/arrow-left-white.svg"></router-link>
                    <h1 v-if="board">{{board.name}}</h1>
                </div>
            </div>

            <div class="col-8">
                <div class="map-container"  v-if="(mapConfigBorden && mapReady)">
                    <div>
                        <h3 class="d-inline borden-title">{{ $t('boards.map') }}</h3>
                        <!--<button v-if="!publicDashboard" class="btn-wijzigen" v-on:click="showBordenPopupMap">WIJZIGEN</button>-->
                    </div>
                    <Map ref="mapelement" v-if="!hideBordenMap" class="map bordenMap" :mapConfig="mapConfigBorden"></Map>
                </div>
            </div>

            <div class="col-4">
                <div class="chart-container-1">
                    <div>
                        <h3 class="d-inline borden-title">{{borden_chart1_title}}</h3>
                        <!--<button v-if="!publicDashboard" class="btn-wijzigen" v-on:click="showBordenPopupChart('borden_chart1')">WIJZIGEN</button>-->
                    </div>
                    <analysis-chart class="chart-1" ref="borden_chart1_ref" :from="'borden'" v-on:changeTitle="updateTitle($event)"></analysis-chart>
                </div>
                <div class="chart-container-2">
                    <div>
                        <h3 class="d-inline borden-title">{{borden_chart2_title}}</h3>
                        <!-- <button v-if="!publicDashboard" class="btn-wijzigen" v-on:click="showBordenPopupChart('borden_chart2')">WIJZIGEN</button>-->
                    </div>
                    <analysis-chart class="chart-2" ref="borden_chart2_ref" :from="'borden'" v-on:changeTitle="updateTitle2($event)"></analysis-chart>
                </div>
            </div>

        </div>

    </div>
</template>



<script>
    import Map from '../map/Map'
    import AnalysisChart from '../analysis/analysisChart'
    import axios from 'axios'
    import i18n from '@/i18n'

    export default {
        name: "bord",
        props: ["board"],
        components: {Map, AnalysisChart},
        created() {
            //No board selected, redirect to boards overview

            if (this.board === undefined) {
                this.$router.push({
                    name: 'borden'
                });
                return;
            }

            this.$store.dispatch('retrieveConfig')
                .then(() => {
                    localStorage.setItem('legends-config', JSON.stringify(this.$store.state.config.legend_config));
                    this.$store.dispatch('retrieveBoards')
                        .then(() => {
                            this.boardConfig = this.board.config;
                            this.mapConfigBorden = this.deserialize(this.boardConfig.maps[0]);

                            let charts = this.boardConfig.charts;
                            if (charts.length > 0 &&  this.$refs.borden_chart1_ref !== undefined) {
                                this.$refs.borden_chart1_ref.deserialize(charts[0]);
                            }
                            if (this.boardConfig.charts.length > 1 &&  this.$refs.borden_chart2_ref !== undefined) {
                                this.$refs.borden_chart2_ref.deserialize(charts[1]);
                            }

                            this.evaluateDataMap();
                            this.mapReady = true;
                        });
                });

            /*
            this.$store.dispatch('retrieveConfig')
                .then(() => {
                    this.mapConfigBorden = this.config.borden_map;
                    this.evaluateDataMap()
                    this.loadMapDataToPopup()

                    this.loadChartDataToPopup('borden_chart1')
                    this.loadChartDataToPopup('borden_chart2')

                })*/
        },
        data () {
            return {

                boardConfig: {},
                hideBordenMap: false,
                mapReady: false,
                mapConfigBorden: null,
                selected_borden_chart_config: null,
                borden_chart1_config: null,
                borden_chart2_config: null,
                activeChart: '',
                borden_chart1_title: i18n.t('boards.chart1'),
                borden_chart2_title: i18n.t('boards.chart2'),
                mapConfigPopup: {
                    page: 'borden_map_popup',
                    timeFilter: null,
                    selectedInTheSelector: {
                        selectedMarkers: {
                            get: 'pins',
                            set: function (layer) {
                                localStorage.setItem('selectedInTheSelectorBorden', layer);
                            }
                        },

                        selected_categories: {
                            get: localStorage.getItem('selected_categories_borden') || [],
                            set: function (categories) {
                                localStorage.setItem('selected_categories_borden', categories);
                            }
                        },
                        selected_timedimension: {
                            get: [],
                            set: function (timedimension) {
                                localStorage.setItem('selected_timedimension_borden', timedimension)
                            }
                        },

                        baselayer: {
                            get: 'GoogleStreet',
                            set: function (newVal) {
                                localStorage.setItem('selectedInTheSelectorBaselayerBorden', newVal)
                            }
                        },
                        overlaylayer: {
                            get: [],
                            set: function (object) {
                                localStorage.setItem('selectedInTheSelectorOverlaylayersBorden', object.selectedInTheSelectorOverlaylayers)
                            }
                        }
                    },
                    markersOption: {
                        timeseriesOptions: function () {
                            return ''
                            //return object.onEachFeaturePopupTimeseries
                        },
                        handheldsOptions: function (object) {
                            return object.onEachFeaturePopupHandhelds
                        },
                        pinsOptions: function (object) {
                            return object.onEachFeaturePopupPins
                        }
                    }
                }
            }
        },
        computed: {
            config() {
                return this.$store.state.config
            },
            publicDashboard() {
                return this.$store.state.publicAccount
            }
        },
        methods: {

            deserialize(mapConfig) {
                return {
                    zoom: mapConfig.zoom,
                    center: mapConfig.center,
                    markersOption: {
                        handheldsOptions: "emptyOptions",
                        timeseriesOptions: "emptyOptions",
                        pinsOptions: "emptyOptions"
                    },
                    selectedInTheSelector: {
                        selectedMarkers: {
                            set: "emptyOptions",
                            get: mapConfig.parameter
                        },
                        overlaylayer: {
                            set: "emptyOptions",
                            get: mapConfig.overlayLayers
                        },
                        baselayer: {
                            set: "emptyOptions",
                            get: mapConfig.baseLayer
                        },
                        selected_categories: {
                            set: "emptyOptions",
                            get: mapConfig.categories
                        },
                        selected_timedimension: {
                            set: "emptyOptions",
                            get: mapConfig.timedimension
                        },
                    },
                    timeFilter: mapConfig.timeFilter,
                    page: "borden"
                };
            },
            updateTitle (updatedTitle) {
                this.borden_chart1_title = updatedTitle;
            },
            updateTitle2 (updatedTitle) {
                this.borden_chart2_title = updatedTitle;
            },
            showBordenPopupMap () {
                this.hideBordenMap = true;
                this.$modal.show('bordenPopupMap');
            },
            showBordenPopupChart (activeChart) {
                this.hideBordenMap = true;
                this.activeChart = activeChart;
                this.$modal.show('bordenPopupChart');
                this.selected_borden_chart_config = this[activeChart+ '_config']
                localStorage.setItem('selectedTimeseriesBorden', JSON.parse("[" + this.config[activeChart].selectedTimeseriesBorden + "]"))
            },
            beforeClose () {
                this.hideBordenMap = false;
            },
            evaluateDataMap() {
                //Evaluate the value of the database sometimes to be this function (because you can't put functions there)
                // eslint-disable-next-line
                const emptyOptions = function () {      // TODO: fix emptyOptions?
                    return ''
                };

                //Convert data from the database to be readable by the map component
                this.mapConfigBorden.markersOption.timeseriesOptions = eval(this.mapConfigBorden.markersOption.timeseriesOptions);
                this.mapConfigBorden.markersOption.handheldsOptions = eval(this.mapConfigBorden.markersOption.handheldsOptions);
                this.mapConfigBorden.markersOption.pinsOptions = eval(this.mapConfigBorden.markersOption.pinsOptions);

                this.mapConfigBorden.selectedInTheSelector.selectedMarkers.set = eval(this.mapConfigBorden.selectedInTheSelector.selectedMarkers.set);
                this.mapConfigBorden.selectedInTheSelector.baselayer.set = eval(this.mapConfigBorden.selectedInTheSelector.baselayer.set);
                this.mapConfigBorden.selectedInTheSelector.overlaylayer.set = eval(this.mapConfigBorden.selectedInTheSelector.overlaylayer.set);
                this.mapConfigBorden.selectedInTheSelector.selected_categories.set = eval(this.mapConfigBorden.selectedInTheSelector.selected_categories.set);
                this.mapConfigBorden.selectedInTheSelector.selected_timedimension.set = eval(this.mapConfigBorden.selectedInTheSelector.selected_timedimension.set);

                this.mapConfigBorden.isBord = true;
            },
            loadMapDataToPopup() {
                this.mapConfigPopup.selectedInTheSelector.selectedMarkers.get = this.mapConfigBorden.selectedInTheSelector.selectedMarkers.get;
                this.mapConfigPopup.selectedInTheSelector.baselayer.get = this.mapConfigBorden.selectedInTheSelector.baselayer.get;
                this.mapConfigPopup.selectedInTheSelector.overlaylayer.get = this.mapConfigBorden.selectedInTheSelector.overlaylayer.get;
                this.mapConfigPopup.selectedInTheSelector.selected_categories.get = this.mapConfigBorden.selectedInTheSelector.selected_categories.get;
                this.mapConfigPopup.selectedInTheSelector.selected_timedimension.get = this.mapConfigBorden.selectedInTheSelector.selected_timedimension.get;
                this.mapConfigPopup.timeFilter = this.mapConfigBorden.timeFilter;

                this.mapReady = true;
            },
            saveMapConfig() {
                axios.defaults.headers.common['Authorization'] = 'JWT ' + this.$store.state.token;
                let borden_map_main = this.config.borden_map
                let overlaylayerBordenServer = [];
                if (localStorage.getItem('selectedInTheSelectorOverlaylayersBorden')) {
                    overlaylayerBordenServer = localStorage.getItem('selectedInTheSelectorOverlaylayersBorden').split(",")
                }
                let borden_map = {
                    "page": "borden",
                    "selectedInTheSelector": {
                        "overlaylayer": {
                            "get": overlaylayerBordenServer,
                            "set": "emptyOptions"
                        },
                        "baselayer": {
                            "get": localStorage.getItem('selectedInTheSelectorBaselayerBorden') || borden_map_main.selectedInTheSelector.baselayer.get,
                            "set": "emptyOptions"
                        },
                        "selectedMarkers": {
                            "get": localStorage.getItem('selectedInTheSelectorBorden') || borden_map_main.selectedInTheSelector.selectedMarkers.get,
                            "set": "emptyOptions"
                        },
                        "currentCategories": {
                            "get": parseInt(localStorage.getItem('currentCategoriesBorden')) || borden_map_main.currentCategories.get,
                            "set": "emptyOptions"
                        },
                    },
                    "markersOption": {
                        "pinsOptions": "emptyOptions",
                        "timeseriesOptions": "emptyOptions",
                        "handheldsOptions": "emptyOptions"
                    },
                    "timeFilter": null
                }
                axios.patch('/config/me/', {
                    "borden_map": borden_map,
                }).then(() => {
                    this.mapConfigBorden = borden_map;
                    this.evaluateDataMap()
                    this.loadMapDataToPopup()
                }).then(() => {
                    this.$modal.hide('bordenPopupMap')
                })
            },
            saveChartConfig(activeChart) {
                axios.defaults.headers.common['Authorization'] = 'JWT ' + this.$store.state.token;
                let borden_chart = {
                    selectedParameterTimeseriesBorden: localStorage.getItem('selectedParameterTimeseriesBorden'),
                    selectedTimeseriesBorden: localStorage.getItem('selectedTimeseriesBorden'),
                    filter: {
                        is_active: JSON.parse(localStorage.getItem('isActiveChartBorden')),
                        start: localStorage.getItem('selectedFilterChartBrden')
                    }
                }
                let patchObject = {}
                patchObject[activeChart] = borden_chart;
                axios.patch('/config/me/', patchObject).then(() => {
                    this.$store.dispatch('retrieveConfig')
                        .then(() => {
                            this.loadChartDataToPopup(activeChart)
                        }).then(() => {
                        this.loadSeries(activeChart)
                    }).then(() => {
                        this.$modal.hide('bordenPopupChart')
                    })
                })
            },
            loadChartDataToPopup(activeChart) {
                //Load chart data to popup changable mode chart
                let chartConfigArray = []
                let activeChartConfig = activeChart + '_config';
                const parameter = this.config[activeChart].selectedParameterTimeseriesBorden;
                this.config[activeChart].selectedTimeseriesBorden = JSON.parse("[" + this.config[activeChart].selectedTimeseriesBorden + "]");
                this.config[activeChart].selectedTimeseriesBorden.forEach(serie => chartConfigArray.push({series: serie, parameter: parameter}))
                this[activeChartConfig] = chartConfigArray
            }
        }
    }
</script>
<style scoped>
    .borden-container {
        background-color: #00008c;
        height: 100%;
        min-height: calc(100vh - 70px);
        margin-right: 0;
    }
    .borden-title {
        font-family: 'Dosis', sans-serif;
        font-weight: bolder;
        color: #ffffff;
    }
    .btn-wijzigen {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #ffffff;
        margin: 0 0 7px 25px;
        border: none;
        background-color: transparent;
        cursor: pointer;
    }
    .topPopup {
        height: 50px;
        display: block;
    }
    .btn-annuleren {
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        line-height: 18px;
        font-weight: 500;
        color: #00008c;
        margin: 16px 15px 0 0;
        border: none;
        background-color: transparent;
        float: right;
        cursor: pointer;
    }
    .map-container {
        margin: 0px 10px 0 20px !important;
    }
    .map {
        height: calc(100vh - 190px) !important;
        border-radius: 8px;
        overflow: hidden;
    }
    .chart-container-1 {
        margin: 0px 0 0 0;
    }
    .chart-1 {
        border-radius: 8px;
        height: calc(50vh - 112px);
    }
    .chart-container-2 {
        margin: 0;
    }
    .chart-2 {
        height: calc(50vh - 112px);
        border-radius: 8px;
    }

    .board-top {
        margin-left:20px;
    }
    .board-top img {
        height:32px;
    }
    .board-top a {
        float:left;
        display: inline-block;
    }

    .board-top h1 {
        font-weight:bold;
        color:#00eb82;
        text-align:center;
        margin-bottom: 0;
    }
</style>

<template>
  <div/>
</template>


<script>
  export default {
    name: "Home",            // the purpose of this component is to redirect to the right home page.
    data() {
      return {
        windowWidth: window.innerWidth,
      };
    },
    created() {
      if (!localStorage.getItem("last_visited_page") || localStorage.getItem("last_visited_page") == "/home" || localStorage.getItem("last_visited_page") == "null") {
        this.$store.dispatch('retrieveBoards')
          .then(() => {
            if (this.windowWidth > 800 && this.$store.state.boards && this.$store.state.boards.length > 0) {
                this.$router.push("borden");
            } else {
                this.$router.push("map");
            }
          });
      } else {
        if(localStorage.getItem("last_visited_page") == '/terms') {
          this.$router.push("map");
        } else {
          this.$router.push(localStorage.getItem("last_visited_page"));
        }
      }
    }
  }
</script>

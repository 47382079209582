<template>
<div>
  <div class="soilTypeContainer">
    <h3>{{ $t('settings.what_is_soil_type') }} ({{name}})?</h3>
    <b-form-select v-model="selected" class="dropdown col-4">
      <option  v-for="(type, index) in soilTypeData.choices" :key="index" :value="type">{{ $t('settings.' + type) }}</option>
    </b-form-select>
    <button class="btn save-btn" @click="saveAndRefresh(id, selected)">{{ $t('settings.save') }}</button>
  </div>
</div>
</template>


<script>
  import {mapActions, mapGetters, mapMutations} from 'vuex'
  export default {
    name: "soilType",
    props: ['id', 'name'],
    data () {
      return {
        selected: null,
      }
    },
    created() {
      this.fetchSoilTypeData(this.id).then(() => {
        this.selected = this.soilTypeData.soil_type
      })
    },
    computed: {
      ...mapGetters(['soilTypeData'])
    },
    methods: {
      ...mapMutations(['setSuccessfulAlertCounter']),
      ...mapActions(['fetchSoilTypeData', 'saveSoilType', 'fetchClassificationData']),
      saveAndRefresh(id, selected) {
        this.saveSoilType({id, selected}).then(() => {
          this.fetchClassificationData(id)
          this.setSuccessfulAlertCounter(2)
        })
      }
    }
  }
</script>


<style scoped>
  .soilTypeContainer {
    padding-top: 20px; 
    padding-bottom: 50px; 
    border-bottom: 1px solid gray;
  }
  .dropdown {
    margin-top: 10px;
    margin-left: 10px;
    padding-left: 8px !important;
    border: 1px solid black !important;
  }
  .btn {
    margin-left: 100px;
    margin-top: 50px;
  }
</style>

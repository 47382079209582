<template>
  <div class="device-timeseries-list">
    <div class="back"><router-link to="/settings">{{ $t('settings.settings') }}</router-link></div>
    <div class="list-devices">
      <div class="listitem"
      :style="{ backgroundImage: 'url(' + parameterImageSrc[timeseries.parameter] + ')' }"
      v-for="timeseries in allDeviceTimeseries" :key="timeseries.id">
        <h1 class="heading">{{timeseries.name}}</h1>
        <router-link class="timeseriesSetting" :to="{ name: 'timeseriesSettings', query: {id: timeseries.id, name: timeseries.name} }">
          <img src="@/assets/images/nav_settings_active.png" alt="">
        </router-link>
      </div>
    </div>
  </div>
</template>


<script>
  import { mapGetters, mapActions } from 'vuex';
  export default {
    name: "deviceTimeseriesList",
    data(){
      return {
        device: this.$route.query.device,
        parameterImageSrc: {
          'EC': 'https://dashboard.fixeau.com/timeseries-list-images/ec.png',
          'WaterLevel': 'https://dashboard.fixeau.com/timeseries-list-images/ground_water.png',
          'T': 'https://dashboard.fixeau.com/timeseries-list-images/temperature.png',
          'pH': 'https://dashboard.fixeau.com/timeseries-list-images/ph.png',
          'NO3': 'https://dashboard.fixeau.com/timeseries-list-images/no3.png',
          'O2': 'https://dashboard.fixeau.com/timeseries-list-images/no3.png',
          'SoilMoisture': 'https://dashboard.fixeau.com/timeseries-list-images/soil_moisture.png',
          'Precipitation': 'https://dashboard.fixeau.com/timeseries-list-images/precipitation.png',
          'SolarRadiation': 'https://dashboard.fixeau.com/timeseries-list-images/radiation.png',
          'WindSpeed': 'https://dashboard.fixeau.com/timeseries-list-images/wind.png'
        },
      }
    },
    created() {
      this.fetchDeviceTimeseries(this.device);
    },
    computed: mapGetters(['allDeviceTimeseries']),
    methods: {
      ...mapActions(['fetchDeviceTimeseries'])
    }
  }
</script>


<style scoped>
  .device-timeseries-list {
    width: 350px;
    margin: auto;
    padding: 0;
  }
  .list-devices  {
    box-shadow: 0px 8px 15px 0px rgba(27, 33, 58, 0.2);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 30px;
    background-color: #ffffff;
  }
  .list-devices .listitem {
    border: none;
    border-bottom: 1px solid #F2EFE9 !important;
    margin: 0 !important;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: 25px center;
    padding: 20px 0px;
    background-position: left;
    cursor: default;
  }
  .list-devices .listitem h1 {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    margin-left: 12px;
    max-width: 350px;
  }
  .timeseriesSetting {
    float: right;
    margin-right: 10px;
    display: block;
  }
  .heading {
    display: inline-block;
  }
</style>

<template>
  <div class="classContainer" v-if="classificationData.default">
    <h3>{{ $t('settings.what_is_classification') }} ({{name}})?</h3>
    <div>
      <form @submit="onSubmit">
        <div class="row" v-for="(choice, index) in choices" :key="index">
          <div class="col-6">
            <input type="number" disabled="true" step="any" v-model.number="choice.min" :placeholder="choice.min">
            <input type="number" :disabled="index === choices.length-1" step="any" v-model.number="choice.max"
            @blur="updateNextChoiceMin(index)" @change="updateNextChoiceMin(index)">
          </div>
          <div class="col-6 mt-3">
            <input type="color" id="head" name="head" v-model="choice.color">
            <label for="head" class="ml-2">{{choice.label}}</label>
          </div>
        </div>
        <button type="submit" class="btn save-btn classification-btn">{{ $t('settings.save') }}</button>
      </form>
    </div>
  </div>
</template>


<script>
  import {mapActions, mapGetters, mapMutations} from 'vuex'
  export default {
    name: "classification",
    props: ['id', 'name'],
    data () {
      return {

      }
    },
    created () {
      this.fetchClassificationData(this.id)
    },
    computed: {
      ...mapGetters(['classificationData']),
      choices() {
        return this.classificationData.default.choices
      }
    },
    methods: {
      ...mapMutations(['setSuccessfulAlertCounter']),
      ...mapActions(['fetchClassificationData', 'saveClassification']),
      onSubmit(e) {
        e.preventDefault();
        this.saveClassification({id: this.id, choices: this.choices}).then(() => {
          this.setSuccessfulAlertCounter(2)
        })
      },
      updateNextChoiceMin(index) { // when you change the max of the choice
        if(this.choices[index+1]) {
          if (this.choices[index].max > this.choices[index+1].max) this.choices[index].max = this.choices[index+1].max
          if (this.choices[index].max < this.choices[index].min) this.choices[index].max = this.choices[index].min
          this.choices[index+1].min = this.choices[index].max
        }
      },
      // updatePreviousChoiceMax() { // when you change the min of the choice
      //   for (let i=0; i < this.choices.length; i++) {
      //     if(this.choices[i+1]) {
      //       if (this.choices[i+1].min >= this.choices[i+1].max) this.choices[i+1].min = this.choices[i+1].max
      //       else if (this.choices[i+1].min <= this.choices[i].min) this.choices[i+1].min = this.choices[i].min
      //       this.choices[i].max = this.choices[i+1].min
      //     }
      //   }
      // },
    }
  }
</script>


<style scoped>
  .classContainer {
    padding-bottom: 30px; 
    border-bottom: 1px solid gray;
    margin-top: 20px;
  }
  .row {
    margin-bottom: 20px;
  }
  .classContainer .row input {
    max-width: 150px;
  }
  .classification-btn {
    display: block;
    margin: auto;
  }
</style>

<template>
  <div class="back">
    <router-link :to="to">{{ $t('settings.settings') }}</router-link>
  </div>
</template>


<script>
  export default {
    data () {
      return {
        to: {name: 'settings'}
      }
    }
  }
</script>


<style>
  .back {
    text-align: left;
    padding: 12px 0 30px 0;
  }
  .back a {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    color: #00008c;
    text-decoration: none;
    padding-left: 28px;
    background-image: url(../../assets/images/go_back_blue.png);
    background-repeat: no-repeat;
    background-size: 18px auto;
    background-position: 0px 2px;
  }
  .back a:hover {
    color: #00008c;
    text-decoration: none;
  }
</style>

<template>
  <div>
    <div class="container">
      <div class="notifications">
        <h1 v-if="allNotifications.length === 0" class="mt-5">{{ $t('settings.no_notifications') }}</h1>
        <div v-for="notification in allNotifications" :key="notification.id">
          <NotificationItem :notification="notification"></NotificationItem>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import NotificationItem from './NotificationItem'
export default {
  name: "Notifications",
  components: {NotificationItem},
  created() {
    //this.fetchNotifications();
  },
  computed: mapGetters(['allNotifications']),
  methods: {
    ...mapActions(['fetchNotifications'])
  }
}
</script>


<style scoped>
  .notifications {
    max-width: 400px;
    margin: 30px auto;
    margin-bottom:80px;
    text-align: center;
  }
</style>

<template>
  <div>
    <h3 class="text">{{heading}}</h3>
    <div class="btn-group" role="group">
      <button v-for="(choice, index) in classifyChoices" 
      @click="submitNotification(choice.label)"
      :key="index" type="button" class="btn"
      :style="{backgroundColor: choice.color}">
        {{choice.label}}
      </button>
    </div>
  </div>
</template>


<script>
import {mapActions} from "vuex";
export default {
  props: ["notification"],
  data () {
    const data = this.notification.data
    return {
      heading: `Kunt u de huidige situatie van ${data.name} beoordelen?`,
      classifyChoices: []
    }
  },
  created() {
    this.fetchClassifyChoices(this.notification.data.id).then(choices => {
      this.classifyChoices = choices
    })
  },
  methods: {
    ...mapActions(['fetchClassifyChoices', 'saveClassify', 'completedNotification']),
    submitNotification(choice) {
      this.saveClassify({id: this.notification.data.id, selected: choice}).then(() => {
        this.completedNotification(this.notification.id)
      })
    }
  }
}
</script>


<style scoped>
  .btn-group {
    width: 100%;
  }
  .btn-group .btn {
    width: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    font-size: 16px;
    font-weight: 600;
  }
  .btn-group .btn:hover {
    border: 1px solid white;
    color: white;
  }
</style>

import i18n from '@/i18n';
const axios = require('axios');


async function getGxgData(layer, latitude, longitude) {
  const scenarioID = layer.scenarioID;
  const url = `/gxg/?latitude=${latitude}&longitude=${longitude}&scenario=${scenarioID}`
  const response = await axios.get(url);
  return response.data;
}


async function popupContent(layer, latitude, longitude) {
  try {
    const data = await getGxgData(layer, latitude, longitude);
    const gvgDiff = data.gvg-data.gvg_base;
    const glgDiff = data.glg-data.glg_base;
    const ghgDiff = data.ghg-data.ghg_base;
    const diffText =  i18n.t('scenario.difference');
    return `
      <h5>${layer.modelRunConfig.name}</h5>
      <table style="font-size: xx-small">
        <tr>
          <th style="font-size: medium">GVG</th><td style="font-size: medium">${data.gvg.toFixed(2)}</td>
          <td>min</td><td>${data.gvg_min.toFixed(2)}</td>
          <td>max</td><td>${data.gvg_max.toFixed(2)}</td>
          <td>${diffText}</td><td style="color: ${gvgDiff >= 0 ? 'green' : 'red'}">${gvgDiff.toFixed(2)}</td>
        </tr>
        <tr>
          <th style="font-size: medium">GLG</th><td style="font-size: medium">${data.glg.toFixed(2)}</td>
          <td>min</td><td>${data.glg_min.toFixed(2)}</td>
          <td>max</td><td>${data.glg_max.toFixed(2)}</td>
          <td>${diffText}</td><td style="color: ${glgDiff >= 0 ? 'green' : 'red'}">${glgDiff.toFixed(2)}</td>
        </tr>
        <tr>
          <th style="font-size: medium">GHG</th><td style="font-size: medium">${data.ghg.toFixed(2)}</td>
          <td>min</td><td>${data.ghg_min.toFixed(2)}</td>
          <td>max</td><td>${data.ghg_max.toFixed(2)}</td>
          <td>${diffText}</td><td style="color: ${ghgDiff >= 0 ? 'green' : 'red'}">${ghgDiff.toFixed(2)}</td>
        </tr>
      </table>
    `;
  } catch (error) {
    try {
      return error.response.data[0];
    } catch (error) {
      return 'error';
    }
  }
}


async function createMarker(map, layer, latitude, longitude) {
  const content = await popupContent(layer, latitude, longitude);
  if (content == 'Out of bounds') return;   // Do not draw marker
  const customIcon = L.icon({
    iconUrl: require('../assets/images/nav_map.png'),
    iconSize: [14, 21],
    iconAnchor: [7, 21],
    popupAnchor: [0, -11]
  });
  const marker = new L.Marker([latitude, longitude], {icon: customIcon}).addTo(map);
  marker.bindPopup(content).openPopup();
}


const parameters = {
  'hp': i18n.t('scenario.hp'),
  'c1': i18n.t('scenario.c1'),
  'c2': i18n.t('scenario.c2'),
  'c3': i18n.t('scenario.c3'),
  'c4': i18n.t('scenario.c4'),
  'b1': i18n.t('scenario.b1'),
  'b2': i18n.t('scenario.b2'),
  'b3': i18n.t('scenario.b3'),
  'b4': i18n.t('scenario.b4'),
}


function configHtml(layer) {
  const config = layer.modelRunConfig;
  let html = `
    <table>
      <tr><th>${config.name}</th></tr>
    </table>
    <table style="font-size: small">
      <tr><td style="padding-right: 5px;">${config.start || ''}</td><td>${config.stop || ''}</td><td>${config.climate || ''}</td></tr>
    </table>
  `;
  for (const modification of layer.modelRunConfig.modifications) {
    html += `
      <table style="font-size: small">
        <tr>
          <td>${parameters[modification.parameter]}</td>
          <td>${modification.operator}</td>
          <td>${modification.value}</td>
          <td style="padding-right: 5px;">${modification.start || ''}</td>
          <td>${modification.stop || ''}</td>
        </tr>
      </table>
    `;
  }
  return html;
}


function createModificationLayers(map, layer) {
  const html = configHtml(layer);
  const style = {
    "fill": false,
    "weight": 3,
    "color": "#6666ba"
  };
  const onHoverStyle = {
    "fill": false,
    "weight": 5,
    "color": "#6666ba"
  }
  let layers = [];
  for (const modification of layer.modelRunConfig.modifications) {
    let layer = L.geoJSON(modification.features, {
      style: style,
      onEachFeature: function(feature, layer) {
        layer.bindPopup(html);
        layer.on('mouseclick', function() { layer.openPopup(); });
        layer.on('mouseover', function() { this.setStyle(onHoverStyle); });
        layer.on('mouseout', function() { this.setStyle(style); });
      }
    })
    layer.addTo(map);
    layers.push(layer);
  }
  return layers;
}


function onClick(map, latitude, longitude, layer) {
  if (layer && layer.isModelRunLayer) {
    createMarker(map, layer, latitude, longitude);
  }
}


export default {
  onClick,
  createModificationLayers
}

import JQUERY from 'jquery'
// fetch the (filtered) tags for a location
function fetchTags(token, location) {
    return JQUERY.ajax({
		url: 'https://www.telecontrolnet.nl/api/v1/locations/' + location.id.replace('-','') + '/tags',
      data: {'type':'10,12,13'},
		headers: {'Authorization': 'Bearer '+token}
	}).then(data => {
		const candidates = ['mwf', 'ber'];
		// filter essential tag info (reference code = mwf or ber
		return data.tags.filter(item => {
			return candidates.includes(item.tag.referencecode);
		});
	});
}

// format html for feature popup
function formatContent(location, tags) {
	var html = L.Util.template('<h4>{displaycode}</h4>{description}<br/>Zone: {zone}<br/>',location);
	if (tags && tags.length > 0) {
		// add a table with essential tag info
		html += '<table class="table table-condensed table-sm table-hover">\
			<thead><tr><th>parameter</th><th>tijdstip</th><th>waarde</th><th>eenheid</th</tr></thead>';
		JQUERY.each(tags, (key,item) => {
			html += L.Util.template('<tr><td>{object}</td><td>{updated}</td><td>{value}</td><td>{unit}</td></tr>',item.tag);
		});
		html += '</table>'
	}
	return html;
}

// create layer with scada locations
function loadScada(token) {
	// load location data
	return JQUERY.ajax({
		url : 'https://www.telecontrolnet.nl/api/v1/locations',
		headers: {'Authorization': 'Bearer '+token}
	}).then(data => {
		// transform location data to geoJSON
		var features = data.locations.map(item => {
			var location = item.location;
			return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [location.y, location.x]
          },
        properties: location
      };
		});

		var featureColor = function(feature) {
			// return color corresponding to EC value of feature
			// assumes unit is mS/cm
			if('tags' in feature.properties) {
				var ec = feature.properties.tags.find(item => {
					return item.tag.object.startsWith('Geleid'); // Geleidendheid or Geleidbaarheid
				});
				if (ec) {
          const scales = [0.75, 1.0, 1.250, 1.5, 2.0, 2.5];
          const colors = ['blue', 'skyblue', 'green', 'lime', 'yellow', 'orange', 'red'];
          var value = ec.tag.value;
          for(var index = 0; index < scales.length; index++) {
            if (value < scales[index])
              return colors[index];
          }
          // more than 2.5 mS/cm
          return 'red';
				}
			}
			// no tags in feature properties or no EC value available.
			return 'gray';
		};

		// create single geoJSON layer for all features
		var scada = L.geoJSON(features, {
			// start off by creating gray dots for all locations
        pointToLayer: (feature, latlng) => {
            return L.circleMarker(latlng, {
              radius: 5,
              fillColor: 'gray',
              color: '#000',
              weight: 1,
              opacity: 1,
              fillOpacity: 0.8
            });
        },
        onEachFeature: (feature, layer) => {
          // show Loading.. popup while tags are fetched from the server
          layer.bindPopup("Loading " + feature.properties.displaycode, {maxWidth: 600});
          // fetch tag information
          fetchTags(token, feature.properties).then(tags => {
            // cache tags in feature properties
            feature.properties.tags = tags;
            // set color of dot using EC value in feature properties
            layer.setStyle({
              fillColor: featureColor(feature)
            });
            layer.on('click', () => {
              layer.setPopupContent(formatContent(feature.properties, tags));
              });
          });
        }
		});
		return scada;
	});
}

export {
  loadScada
}

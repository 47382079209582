<template>
  <div>
    <template>
      <template v-if="(userTermsAcceptance || isSimple) && loggedIn">
        <!-- desktop version -->
        <b-navbar class="navbar" v-if="windowWidth > 1200" toggleable="md" type="dark" variant="info">

          <b-navbar-brand href="https://www.fixeau.com" target="_blank"><img class="nav-logo" src="@/assets/images/Fixeau_logo.png" alt="logo" /></b-navbar-brand>

          <b-navbar-nav class="text-center mx-auto" style="width: 440px;">
            <router-link v-if="hasBoardsAvailable" class="nav-item nav-item-icon-5" to="/borden">{{ $t('navbar.boards') }}</router-link>
            <router-link v-if="!publicDashboard && !isSimple" class="nav-item nav-item-icon-1" to="/actual">{{ $t('navbar.actual') }}</router-link>
            <router-link class="nav-item nav-item-icon-2" to="/map">{{ $t('navbar.map') }}</router-link>
            <router-link class="nav-item nav-item-icon-3" to="/analysis">{{ $t('navbar.analysis') }}
              <span v-if="countBasket > 0" variant="danger" class="basket">{{countBasket}}</span>
            </router-link>
            <router-link v-if="hasScenario" class="nav-item nav-item-icon-5" to="/scenario-overview">{{ $t('navbar.scenario') }}</router-link>
            <router-link class="nav-item nav-item-icon-4" to="/settings">{{ $t('navbar.settings') }}</router-link>
          </b-navbar-nav>

          <router-link v-if="!publicDashboard && !isSimple" class="nav-item nav-item-icon-6" to="/notifications">
            <div class="notificationImgContainer" v-bind:style="{ marginTop: notificationMarginCorrection + 'px' }">
              <img class="notificationImg" src="../../assets/images/nav_notifications.png" alt="">
            </div>
            <span v-if="notificationsBasket > 0" variant="danger" class="basket notificationsBasket">{{notificationsBasket}}</span>
          </router-link>

          <logo-list/>

        </b-navbar>
        <!-- tablet/mobile version (for tablet on top, for mobile in bottom -with css-) -->
        <b-navbar v-else toggleable="md" type="dark" variant="info" class="text-center row">
          <router-link v-if="!publicDashboard && !isSimple" class="nav-item nav-item-icon-1 col-3" to="/actual">{{ $t('navbar.actual') }}</router-link>
          <router-link class="nav-item nav-item-icon-2 col-3" to="/map">{{ $t('navbar.map') }}</router-link>
          <router-link class="nav-item nav-item-icon-3 col-3" to="/analysis">{{ $t('navbar.analysis') }}
            <span v-if="countBasket > 0" variant="danger" class="basketMobile">{{countBasket}}</span>
          </router-link>
          <router-link class="nav-item nav-item-icon-4 col-3" to="/settings">{{ $t('navbar.settings') }}</router-link>
        </b-navbar>
      </template>
    </template>

    <transition name="router-animation" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in">
      <!-- show the active router component here -->
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
  import axios from 'axios';
  import logoList from './logoList.vue'

	export default {
		name: "navbar",
    components: {
      logoList,
    },
    data() {
      return {
        windowWidth: window.innerWidth,
      }
    },
    mounted() {
      if(this.$store.state.token) {
        this.$store.dispatch('retrieveBoards');
        this.checkAgreed();
        //this.$store.dispatch('fetchNotifications')
      }
    },
    updated() {
      this.checkAgreed();
    },
    computed: {
      loggedIn() {
        return this.$store.getters.loggedIn
      },
      userTermsAcceptance() {
        return this.$store.state.userTermsAcceptance
      },
      countBasket() {
        return this.$store.getters.countBasket
      },
      notificationsBasket() {
        return this.$store.getters.notificationsBasket
      },
      publicDashboard() {
        return this.$store.state.publicAccount
      },
      notificationMarginCorrection(){
        //Correction margin of notification icon for multiple cases
        if (this.notificationsBasket == 0 && this.$route.name != "notifications") {
          return -32;
        } else if (this.notificationsBasket == 0 && this.$route.name == "notifications"){
          return -26;
        } else if (this.notificationsBasket > 0 && this.$route.name == "notifications"){
          return 11;
        }
        return 5;
      },
      hasBoardsAvailable() {
        return this.$store.state.boards && this.$store.state.boards.length > 0;
      },
      hasScenario() {
        return this.$store.state.hasScenario;
      },
      isSimple() {
        return this.$store.state.isSimple;
      }
    },
    methods: {
      checkAgreed() {
        // Prevent escaping acceptance of terms conditions
        if(this.$store.state.token) {
          this.$store.dispatch('retrieveJustProfile')
            .then(() => {
              if (!this.$store.state.isPublicAccount && this.$store.state.profile && !this.$store.state.profile.details.agreed) {
                if (this.$router.currentRoute.name != 'terms' && !this.isSimple) {
                  this.$router.push({name: 'terms'});
                }
              }
            })
        }
      },
			saveData() {
				axios.defaults.headers.common['Authorization'] = 'JWT ' + this.$store.state.token;
				const data = this.$store.state.config;
				axios.put('/config/me/',data)
      }
    }
	}
</script>


<style scoped>
  .basket {
    position: relative;
    top: -32px;
    left: 0px;
    background: #00eb82;
    border-radius: 0.8em;
    -moz-border-radius: 0.8em;
    -webkit-border-radius: 0.8em;
    color: #00008c;
    display: inline-block;
    font-weight: bold;
    line-height: 1.6em;
    margin-right: 5px;
    text-align: center;
    width: 1.6em;
  }
  .notificationsBasket {
    top: -36px;
    left: 11px;
  }
  .basketMobile {
    position: absolute;
    top: -8px;
    left: calc(50% + 8px);
    background: #00eb82;
    border-radius: 0.8em;
    -moz-border-radius: 0.8em;
    -webkit-border-radius: 0.8em;
    color: #00008c;
    display: inline-block;
    font-weight: bold;
    line-height: 1.6em;
    margin-right: 5px;
    text-align: center;
    width: 1.6em;
  }
  .navbar {
    background-color: #00008c !important;
    height: 70px;
    width: 100%;
    padding-left: 20px;
    padding-right: 30px;
    z-index: 2;
    border-bottom: 1px solid #6666ba;
  }
  @media screen and (max-width: 800px) {
    .navbar {
      z-index: 2000;
      border-bottom: none;
    }
  }
  .navbar.row {
    margin: 0;
  }
  .nav-logo {
    height: 50px;
    width: auto;
    margin-top: 4px;
  }
  .nav-item {
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    color: #6666ba;
    background-repeat: no-repeat;
    background-position: top center;
    padding-top: 22px;
    margin-top: 10px;
    margin-left: 18px;
    margin-right: 18px;
    box-shadow: none;
    outline: none;
  }
  .nav-item:hover {
    text-decoration: none;
  }
  .nav-item-icon-1 {
    background-image: url(../../assets/images/nav_actual.png);
  }
  .nav-item-icon-2 {
    background-image: url(../../assets/images/nav_map.png);
  }
  .nav-item-icon-3 {
    background-image: url(../../assets/images/nav_analysis.png);
  }
  .nav-item-icon-4 {
    background-image: url(../../assets/images/nav_settings.png);
  }
  .nav-item-icon-5 {
    background-image: url(../../assets/images/nav_borden.png);
  }
  .nav-item-icon-6 img {
    margin-bottom: 28px;
  }
  .nav-item-icon-1.router-link-exact-active {
    color: #00eb82;
    background-image: url(../../assets/images/nav_actual_active.png);
    font-weight: 400;
  }
  .nav-item-icon-2.router-link-exact-active {
    color: #00eb82;
    background-image: url(../../assets/images/nav_map_active.png);
    font-weight: 400;
  }
  .nav-item-icon-3.router-link-exact-active {
    color: #00eb82;
    background-image: url(../../assets/images/nav_analysis_active.png);
    font-weight: 400;
  }
  .nav-item-icon-4.router-link-exact-active {
    color: #00eb82;
    background-image: url(../../assets/images/nav_settings_active.png);
    font-weight: 400;
  }
  .nav-item-icon-5.router-link-exact-active {
    color: #00eb82;
    background-image: url(../../assets/images/nav_borden_active.png);
    font-weight: 400;
  }
  .nav-item-icon-6 .notificationImg {
    margin: auto;
  }
  .nav-item-icon-6.router-link-exact-active .notificationImg {
    content: url(../../assets/images/nav_notifications_active.png);
  }
  .nav-item-icon-6.router-link-exact-active .notificationImgContainer {
    content: url(../../assets/images/nav_notifications_active.png);
    margin-top: 5px;
  }
  .nav-item.col-3 {
    padding-left: 0;
    padding-right: 0;
  }
  .menu-line {
    width: 30px;
    height: 3px;
    background-color: black;
    margin: 4px 0;
  }
  .navbar-brand {
    margin-right: 100px;
    outline: none
  }
  .fa-icon {
    margin-bottom: 7px;
  }
  a {
    font-size: 12px;
  }
  .navbar-brand {
    margin-right: 10px;
  }
  @media screen and (max-width: 800px) {
    .navbar {
      position: fixed;
      bottom: 0;
    }
    .nav-item {
      margin-left: 0;
      margin-right: 0;
    }
  }
</style>

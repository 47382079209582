<template>
  <ul id="logo-ul">
    <li class="logo-li" v-for="logo in logos" :key="logo.href">
      <a class="logo-a" :href="logo.href" target="_blank">
        <img class="logo-img" :src="logo.src" alt="logo" />
      </a>
    </li>
  </ul>
</template>


<script>
  import templateHelper from '@/helpers/templateHelper';

  export default {
    computed: {
      logos() {
        const template = templateHelper.getActiveTemplate();
        if (template == 'burqaa') {
          return [
            {
              href: 'https://www.theheinekencompany.com/sustainability-and-responsibility/environmental',
              src: require('@/assets/images/logo_heineken.jpg')
            },
            {
              href: 'https://www.wvi.org/ethiopia',
              src: require('@/assets/images/logo_world_vision.png')
            },
            {
              href: 'https://www.acaciawater.com',
              src: require('@/assets/images/logo_acacia_water.jpg')
            }
          ];
        }
        if (template == 'kunzila') {
          return [
            {
              href: 'https://www.netherlandsworldwide.nl/contact/embassies-consulates-general/ethiopia/embassy-addis-ababa',
              src: require('@/assets/images/logo_embassy.jpg')
            },
            {
              href: 'https://snv.org/country/ethiopia',
              src: require('@/assets/images/logo_snv.jpg')
            },
            {
              href: 'https://www.wlrc-eth.org/index.php/projects/active-projects/kunzila-integrated-landscape-management-and-wash-project-ilmwa',
              src: require('@/assets/images/logo_wlrc.jpg')
            },
            {
              href: 'https://www.acaciawater.com',
              src: require('@/assets/images/logo_acacia_water.jpg')
            }
          ];
        }
        return [];
      }
    }
  }
</script>


<style>
  #logo-ul {
    list-style-type: none;
    margin: 0px;
  }
  .logo-li {
    float: left;
    width: 200px;
    background-color: white;
    border-radius: 2px;
    margin: 5px;
    text-align: center;
  }
  .logo-img {
    height: 50px;
    padding: 1px;
  }
</style>

<template>
  <div v-bind:style="styleObject">
    <fixeau-head :classification='classification' :values='values' :name='name'> </fixeau-head>
    <router-link class="btn" :to="{ name: 'infographic', query: {pinNumber: pinNumber}, params: {back_page: back_page} }">{{ $t('devices.details') }}</router-link>
    <slot></slot>
  </div>
</template>


<script>
  import fixeauHead from './fixeauHead.vue'

  export default {
    props: ['values', 'name', 'pin', 'pinNumber', 'back_page', 'classification'],
    components: {
      fixeauHead,
    },
    data () {
      return {
        styleObject: {
          'text-align': 'center'
        }
      }
    }
  }
</script>

import { render, staticRenderFns } from "./NotificationItem.vue?vue&type=template&id=07cb6248&scoped=true&"
import script from "./NotificationItem.vue?vue&type=script&lang=js&"
export * from "./NotificationItem.vue?vue&type=script&lang=js&"
import style0 from "./NotificationItem.vue?vue&type=style&index=0&id=07cb6248&prod&scoped=true&lang=css&"
import style1 from "./NotificationItem.vue?vue&type=style&index=1&id=07cb6248&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07cb6248",
  null
  
)

export default component.exports
<template>
  <div>
    <nzgmeet v-if="activeTemplate == 'nzgmeet'"/>
    <texelmeet v-else-if="activeTemplate == 'texelmeet'"/>
    <burqaa v-else-if="activeTemplate == 'burqaa'"/>
    <kunzila v-else-if="activeTemplate == 'kunzila'"/>
    <fixeauDefault v-else/>
  </div>
</template>


<script>
  import fixeauDefault from './templates/fixeauDefault.vue';
  import nzgmeet from './templates/nzgmeet.vue';
  import texelmeet from './templates/texelmeet.vue';
  import burqaa from './templates/burqaa.vue';
  import kunzila from './templates/kunzila.vue';
  import i18n from '@/i18n';
  import templateHelper from '@/helpers/templateHelper';

  export default {
    name: 'login',
    components: {
      fixeauDefault,
      nzgmeet,
      texelmeet,
      burqaa,
      kunzila
    },
    data() {
      return {
        username: '',
        password: '',
        serverError: '',
      };
    },
    computed: {
      activeTemplate() {
        return templateHelper.getActiveTemplate();
      },
      isSimple() {
        return this.$store.state.isSimple;
      },
    },
    mounted() {
      const dictionary = {
        en: {
          custom: {
            username: {
              required: i18n.t('login.username_required'),
            },
            password: {
              required: i18n.t('login.password_required'),
              min: i18n.t('login.password_min')
            },
          }
        }
      };
      this.$validator.localize(dictionary);
    },
    methods: {
      login() {
        let vm = this;
        this.$store.dispatch('retrieveToken', {
          username: this.username,
          password: this.password,
        })
          .then(() => {
            let username = vm.username;
            localStorage.setItem('username', username);
            vm.$store.dispatch('retrieveJustProfile')
              .then(() => {
                if (this.$store.state.profile.details.agreed || this.isSimple) {
                  this.$router.push({name: 'home'});
                } else {
                  this.$router.push({name: 'terms'});
                }
              });
          })
          .catch(error => {
            this.serverError = error.response.data.non_field_errors[0];
            this.password = '';
          })
      },
      publicLogin(token) {
        //TEST API TOKEN:
        //let publicToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IiIsInVzZXJuYW1lIjoicHVibGljIiwiZXhwIjoxNzMzOTIwMjk5LCJ1c2VyX2lkIjoyNDV9.Omb75UhhpycfhVYIti9H2fkorh-H9MYotwKZF-gt31w";

        //DELTADRIP API TOKEN:
        //let publicToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImRlbHRhZHJpcCIsInVzZXJfaWQiOjE5OCwiZXhwIjoxNzMzODQwNjExLCJlbWFpbCI6IiJ9.RFQDVCP-pIT2AKknzc62CFQ89MvT4GUwbpsY1xVD8aw";

        //NZGMEET API TOKEN:
        //let publicToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IiIsInVzZXJfaWQiOjIxNywiZXhwIjoxNzE2NjQwNzM5LCJ1c2VybmFtZSI6Im56Z21lZXQifQ.9AHpj7H_L1YW7ZH0dh2yS5GN6OWHkRUayzzeDuHeXNw";

        this.$store.state.token = token;
        let expire = (new Date()).getTime() + (24 * 60 * 60 * 1000);
        localStorage.setItem('tokenExpire', expire);
        localStorage.setItem('token', token);
        this.$router.push({name: 'home'});
      }
    }
  }
</script>

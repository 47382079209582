<template>
  <div class="settings-container">
    <div class="settings-titel">{{ $t('settings.settings') }}</div>
    <div class="settings-group">
      <!-- bootstrap-vue -->
      <b-list-group class="mt-3 text-center">
        <b-list-group-item v-if="!isSimple && !isPublic">
          <router-link class="icon notifications" :to="{ name: 'notifications' }">
            {{ $t('settings.notification') }}
            <span v-if="notificationsBasket > 0" variant="danger" class="basket notificationsBasket">
              {{notificationsBasket}}
            </span>
          </router-link>
        </b-list-group-item>
        <b-list-group-item v-if="!isPublic">
          <router-link class="icon profile" :to="{ name: 'my-profile' }">
            {{ $t('settings.profile') }}
          </router-link>
        </b-list-group-item>
        <b-list-group-item v-if="!isSimple && !isPublic">
          <router-link class="icon devices" to="/devices">
            {{ $t('settings.manage_devices') }}
          </router-link>
        </b-list-group-item>
        <b-list-group-item v-if="!isSimple && isDesktop">
          <a class="icon profile" @click="resetTutorial()" >
            {{ $t('settings.reset_tutorial') }}
          </a>
        </b-list-group-item>
        <b-list-group-item v-if="!isSimple">
          <a class="icon profile" @click="changeLanguage()" >
            {{ $t('settings.change_language') }}
          </a>
        </b-list-group-item>
        <b-list-group-item>
          <router-link class="icon contact" to="/contact-us">
            {{ $t('settings.contact_us') }}
          </router-link>
        </b-list-group-item>
        <b-list-group-item v-if="!isPublic">
          <router-link class="icon password" :to="{ name: 'change-password' }">
            {{ $t('settings.change_password') }}
          </router-link>
        </b-list-group-item>
        <b-list-group-item>
          <router-link class="icon logout" :to="{ name: 'logout' }">
            {{ $t('settings.logout') }}
          </router-link>
        </b-list-group-item>
      </b-list-group>
    </div>
    <v-dialog/>
  </div>
</template>


<script>
  import axios from 'axios';
  import i18n from '@/i18n';

  export default {
    name: "settings",
    computed: {
      loggedIn() {
        return this.$store.getters.loggedIn;
      },
      notificationsBasket() {
        return this.$store.getters.notificationsBasket;
      },
      isSimple() {
        return this.$store.state.isSimple;
      },
      isPublic() {
        return this.$store.state.publicAccount;
      },
      isDesktop() {
        return window.innerWidth > 800;
      }
    },
    methods: {
      resetTutorial() {
        localStorage.setItem('tutorial_progress', 0);
        axios.patch('/user/me/', {"details": {"tutorial_progress": 0}});
        this.$modal.show('dialog', {
          title: i18n.t('settings.tutorial_was_reset'),
          text: i18n.t('settings.tutorial_was_reset_text'),
          buttons: [{title: i18n.t('settings.ok')}]
        });
      },
      changeLanguage() {
        i18n.locale = i18n.locale == 'nl' ? 'en' : 'nl';
        localStorage.setItem('locale', i18n.locale);
      }
    }
  }
</script>


<style scoped>
  .settings-container {
    background-color: #00008c;
    height: 100%;
    min-height: calc(100vh - 70px);
    text-align: center;
  }
  .settings-titel {
    font-size: 30px;
    font-weight: 700;
    color: #ffffff;
    padding: 30px 0 30px 0;
  }
  @media screen and (max-width: 800px) {
    .settings-titel {
      text-align: left;
      padding: 30px 10px 30px 30px;
    }
  }
  .settings-group {
    width: 100%;
    max-width: 500px;
    margin: auto;
    border-radius: 8px;
    overflow: hidden;
    background-color: #00006b;
    padding: 10px 0;
  }
  @media screen and (max-width: 800px) {
    .settings-group {
      max-width: 100%;
      border-radius: 0px;
      overflow: auto;
      background-color: transparent;
      padding: 0;
      margin-bottom: 70px;
    }
  }
  .settings-group .list-group {
    margin: 0 !important;
  }
  .list-group-item {
    padding: 0;
    border: 0;
  }
  .list-group-item a {
    font-size: 22px;
    color: #ffffff !important;
    text-align: left;
    background-color: #00006b;
    padding: 10px 10px 10px 65px;
    display: block;
    cursor:pointer;
  }
  @media screen and (max-width: 800px) {
    .list-group-item a {
      background-color: #00008c;
    }
  }
  .list-group-item a:hover{
    color: #ffffff;
    text-decoration: none;
    background-color: #00006b;
    padding: 10px 10px 10px 61px;
    border-left: 4px solid #00eb82;
  }
  .icon {
    background-repeat: no-repeat;
    background-position: 22px 13px;
  }
  .icon:hover {
    background-position: 18px 13px;
  }
  .profile {
    background-image: url(../../assets/images/profile_white.png);
  }
  .devices {
    background-image: url(../../assets/images/device_managing_white.png);
  }
  .contact {
    background-image: url(../../assets/images/suggestion_white.png);
  }
  .password {
    background-image: url(../../assets/images/change_password_white.png);
  }
  .logout {
    background-image: url(../../assets/images/login_white.png);
  }
  .notifications {
    background-image: url(../../assets/images/notifications_white.png);
  }
  .basket {
    float: right;
    background: #00eb82;
    border-radius: 0.8em;
    -moz-border-radius: 0.8em;
    -webkit-border-radius: 0.8em;
    color: #00008c;
    display: block;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.7em;
    margin-right: 5px;
    text-align: center;
    width: 1.7em;
  }
</style>

function getActiveTemplate() {
    let domain = window.location.hostname;
    let templates = {
      'nzgmeet.nl': 'nzgmeet',
      'texelmeet.nl': 'texelmeet',
      'burqaa.fixeau.com': 'burqaa',
      'kunzila-ilmwa.fixeau.com': 'kunzila',
      'wdod.fixeau.com': 'wdod',
      'localhost': 'fixeauDefault'
    };
    return templates[domain] || "fixeauDefault";
}

export default {
  getActiveTemplate
}

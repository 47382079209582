<template>
	<div class="login-container">
		<img class="login-logo" src="@/assets/images/Fixeau_logo.png" alt="logo" />
		<div class="page-wrapper login-form">
			<form action="#" @submit.prevent="$parent.login">
				<div v-if="$parent.serverError" class="server-error">{{ $t('login.login_failed') }}</div>
				<div class="form-control">
					<img class="input-icon" src="@/assets/images/mail_green.png" alt="" /><input type="text" :placeholder="$t('login.username')" name="username" id="username" class="login-input form-mail" :class="{ 'input-error': this.$parent.errors.has('username') }" v-model="$parent.username" v-validate="'required'">
					<span class="form-error">{{ $parent.errors.first('username') }}</span>
				</div>
				<div class="form-control">
					<img class="input-icon" src="@/assets/images/lock_green.png" alt="" /><input type="password" :placeholder="$t('login.password')" name="password" id="password" class="login-input form-pass" :class="{ 'input-error': this.$parent.errors.has('password') }" v-model="$parent.password" v-validate="'required|min:6'">
					<span class="form-error">{{  $parent.errors.first('password') }}</span>
				</div>
				<div>
					<button type="submit" class="btn-submit">{{ $t('login.log_in') }}</button>
				</div>
			</form>
		</div>
	</div>
</template>


<script>
	export default {
		name: "fixeauDefault",
		created() {
		}
	}
</script>


<style lang="scss" scoped>
  .login-container {
    background-color: #00008c;
    height: 100vh;
    text-align: center;
  }
  .login-logo {
    width: 120px;
    height: auto;
    margin: 63px 0 53px 0;
  }
  label {
    display: block;
    margin-bottom: 4px;
  }
  .login-heading {
    margin-top: 30px;
    margin-bottom: 16px;
  }
  .form-control {
    margin-bottom: 50px;
    border: none !important;
    padding: 0;
  }
  .login-form {
    width: calc(100% - 60px);
    max-width: 500px;
    margin: auto;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    padding: 20px 20px 0 20px;
    box-shadow: 0px 8px 15px 0px rgba(27, 33, 58, 1);
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
  .login-input {
    font-family: 'Dosis', sans-serif;
    font-size: 20px;
    font-weight: 400;
    width: calc(100% - 34px);
    padding: 10px 0 !important;
    float: right;
    outline: 0;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #e5e5e5 !important;
    -webkit-appearance: none;
  }
  .login-input:focus {
    border-bottom: 2px solid #00eb82 !important;
  }
  .input-icon {
    float: left;
    margin-top: 22px;
  }
  .btn-submit {
    font-family: 'Dosis', sans-serif;
    font-size: 20px;
    font-weight: 400;
    width: calc(100% + 40px);
    margin-left: -20px;
    padding: 14px 12px;
    border-radius: 0;
    border: none;
    color: #ffffff;
    background-color: #2e98fc;
    -webkit-appearance: none;
    cursor: pointer;
    &:hover {
      background-color: darken(#2e98fc, 10%);
    }
    &:disabled {
      background-color: lighten(#2e98fc, 25%);
      cursor: not-allowed;
    }
  }
  .server-error {
    font-family: 'Dosis', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: red;
    width: 100%;
    padding: 10px 0 10px 0;
    outline: 0;
    border-radius: 0;
    border: none;
    -webkit-appearance: none;
  }
  .form-error {
    font-size: 14px;
    color: red;
    padding: 4px 0 10px 34px;
    float: left;
    text-align: left;
  }
  .input-error {
    border: none;
    border-bottom: 2px solid red !important;
  }
</style>

<template>
  <div id="actual" v-if="ready && timeseries">
      <b-alert
        :show="dismissCountDown"
        dismissible
        variant="danger"
        @dismissed="dismissCountDown=0"
        fade
      >
      {{ $t('boards.name_too_long') }}
      </b-alert>
      <div id="prev-next"></div>
      <slick ref="slick" :options="slickOptions">
        <device v-for="(pin, index) in pinsWithData" class="pins"  :values='pin[0].values' :key="index" :name=index :pinNumber=index back_page='actual'>
          <template slot="button">{{ $t('boards.details') }}</template>
          <div class="info-container">
            <router-link v-for="(module, index) in pinPointValues(index)" :to="{ name: 'analysis', params: {selectedTimeseries: module} }" :key="index+10"><fixeauModuleMap :modules='module'> </fixeauModuleMap></router-link>
          </div>
        </device>
        <div v-for="handheld in timeseries" class="timeseries"  :key="handheld.id">
          <div class="title-handheld">
              <h1 v-if="!handheld.editMode">{{handheld.name}}</h1>
              <!-- Classification color in title -->
              <!--<h1 v-bind:style="{ color: (handheld.classification) ? handheld.classification.color : null}" v-if="!handheld.editMode">{{handheld.name}}</h1>-->

            <!-- uncomment and commnet the previous line to edit the name of timeseries -->
            <!--<h1 v-if="!handheld.editMode" @click="editMode(handheld)">{{handheld.name}}</h1>-->
            <h1 v-else><textarea ref="focusThis" type="text" :placeholder="handheld.name" v-model.lazy="handheld.name" @keyup.enter="changeText(handheld)" @blur="changeText(handheld)"></textarea></h1>
          </div>
          <div class="info-container">
            <router-link :to="{ name: 'measurementsListActual', params: {handheld: handheld} }"><fixeauModuleMap id="unclickable" :modules='[handheld]'> </fixeauModuleMap></router-link>
          </div>
        </div>
      </slick>
  </div>
</template>

<script>
  import device from './infographic/device.vue';
  import fixeauModuleMap from './map/fixeauModuleMap.vue';
  import 'lodash';
  import axios from 'axios';
  import Slick from 'vue-slick';
  import '../assets/slick/slick.css';
  import '../assets/slick/slick-theme.css';
  import 'q';

  export default {
    name: "Actual",
    components: {
      device,
      fixeauModuleMap,
      Slick,
    },
    data() {
      return {
        pinsPoints: null,
        timeseries: null,
        windowWidth: 0,
        dismissCountDown: 0,
        dismissSecs: 22,
        handheldOldName: '',
        ready: false
      }
    },
    created() {
      let vm = this;
      this.$store.dispatch('retrieveProfile').then(() => {
        vm.getSeries().then(() => {
          axios.get('/mapdata/?format=json')
            .then(response => {
              return vm.pinsPoints = response.data
            }).then(() => {
                this.$store.dispatch('retrieveClassifications')
                    .then(() => {
                        //Adds a classification to a serie.
                        function addClassificationToSerie(timeseries, classifications) {
                            timeseries.classification = (timeseries.id in classifications ) ? classifications[timeseries.id] : null;
                            return timeseries;
                        }

                        let classifications = this.$store.state.classifications;
                        this.timeseries.map(
                            function(serie) { return addClassificationToSerie(serie, classifications); }
                        );

                        function addClassificationToPinPoint(pinpoints, classifications) {
                            pinpoints.properties.values.forEach(function (value) {
                                value.classification =   (value.series in classifications ) ? classifications[value.series] : null;
                            })
                            return pinpoints;
                        }

                        this.pinsPoints.map(
                            function(pinPoint) { return addClassificationToPinPoint(pinPoint, classifications); }
                        );
                        this.ready = true;

                });
            })
        })
      })
    },
    /**
    * The following two functions are used to make v-for & slick work as expected.
    *
    * https://github.com/staskjs/vue-slick/issues/32
    **/
    // beforeUpdate() {
    //     if (this.$refs.slick) {
    //         this.$refs.slick.destroy();
    //     }
    // },
    // updated() {
    //     this.$nextTick(function () {
    //         if (this.$refs.slick) {
    //             this.$refs.slick.create(this.slickOptions);
    //         }
    //     });
    // },
    mounted() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;

      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
      });

      //this.$refs.slick.destroy();
    //  this.$refs.slick.create(this.slickOptions);

    },
    computed: {
      pinsWithData() {
        return this.$store.state.pinsWithData
      },
      slickOptions() {
        var defaultOptions = {
          arrows: true,
          dots: true,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0',
          focusOnSelect: false,
          appendArrows: '#prev-next',
          draggable: false,
          rows: 1,
          slidesPerRow: 1,
          responsive: [
            {
              breakpoint: 800,
              settings: {
                arrows: false,
                dots: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '156px',
                draggable: true,
                rows: 1,
                slidesPerRow: 1
              }
            },
            {
              breakpoint: 650,
              settings: {
                arrows: false,
                dots: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '17px',
                draggable: true,
                rows: 1,
                slidesPerRow: 1
              }
            }
          ]
        };

        var numCardsWidth;
        var numCardsToScroll;
        numCardsWidth = Math.floor(this.windowWidth / 230);
        defaultOptions.slidesToShow = numCardsWidth;

        numCardsToScroll = numCardsWidth - 1;

        if (numCardsToScroll < 1) {
            numCardsToScroll = 1;
          }

        defaultOptions.slidesToScroll = numCardsToScroll;

        if (this.windowHeight > 910) {
          defaultOptions.rows = 3;
        } else if (this.windowHeight > 650) {
          defaultOptions.rows = 2;
        } else {
          defaultOptions.rows = 1;
        }

        return defaultOptions;
      },
      profile() {
        return this.$store.state.profile
      },
      devices() {
        return (this.$store.state.profile != null ? this.$store.state.profile.details.other_sources.concat(this.$store.state.profile.details.probes) : null)
      },
      styleObject () {
          return {
            'color': this.module.classification.color,
          }
      },
    },
    methods: {
      changeText(handheld){
        if (this.$refs.focusThis[0].value.length < 10 || this.$refs.focusThis[0].value.length > 50) {
          this.dismissCountDown = this.dismissSecs;
          handheld.editMode = !handheld.editMode;
          handheld.name = this.handheldOldName;
        } else {
          handheld.editMode = !handheld.editMode;
          handheld.name = this.$refs.focusThis[0].value;
          return axios.patch('/series/' + handheld.id + '/', {
            "name": this.$refs.focusThis[0].value,
          })
        }
      },
      editMode(handheld) {
        handheld.editMode = !handheld.editMode;
        this.handheldOldName = handheld.name;
        let vm =this;
        setTimeout(function () {
          vm.$refs.focusThis[0].focus()
        }, 500)
      },
      pinPointValues(pinName){
        let deviceValues = _.filter(this.pinsPoints, event => event.properties.MACAddress === pinName)[0].properties.values;
        let groupedData = _.groupBy(deviceValues, 'parameter_name');
        return groupedData
      },
      async getSeries(){
        axios.defaults.headers.common['Authorization'] = 'JWT ' + this.$store.state.token;

        let surfaceLevel = (!this.$store.state.preferSurfaceLevel) ?  "&reference_level=surface" : "";
        const response = await axios.get(
          '/series/?latest_time__isnull=false&format=dashboard&parameter__in=EC,T,O2,NO3,WaterLevel,pH,SoilMoisture,Precipitation,SolarRadiation,WindSpeed,WindDirection&source__in=' + this.devices + surfaceLevel
          )
        let timeseries = response.data.results;
        for (let series in timeseries) {
          if(timeseries[series].latest){
            timeseries[series].value = timeseries[series].latest.value
          }
          timeseries[series].editMode = false;
        }
        return this.timeseries = timeseries



        // return axios.get('/series/?latest_time__isnull=false&format=dashboard&source__in=' + this.devices)
        //   .then(response => {
        //     let timeseries = response.data.results;
        //     for (let series in timeseries) {
        //       if(timeseries[series].latest){
        //         timeseries[series].value = timeseries[series].latest.value
        //       }
        //       timeseries[series].editMode = false;
        //     }
        //     return this.timeseries = timeseries
        //   });
      },
    },
  }
</script>

<style lang="scss">

  .pin-info-image svg path {
    fill: inherit !important;
  }
  #actual .btn {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #00eb82;
    border-radius: 0;
    margin: -300px 0 0 226px;
    padding: 0;
    border: 0;
  }
  @media screen and (min-width: 801px) {
    #actual .btn {
      font-size: 12px;
      margin: -5px 0 0 158px;
    }
  }
  #actual {
    background-color: #00008c;
    height: 100%;
    min-height: calc(100vh - 70px);
    text-align: center;
  }
  .slick-slider {
    margin: auto;
    overflow: hidden;
    overflow-x: auto;
  }
  @media screen and (max-width: 800px) {
    .slick-slider {
      width: 624px;
      min-height: 620px;
    }
  }
  @media screen and (max-width: 650px) {
    .slick-slider {
      width: 346px;
    }
  }
  @media screen and (min-width: 801px) {
    .slick-track {
      display: flex;
      flex-direction: column;
      flex-flow: column wrap;
      align-items: center;
      align-content: stretch;
      height: calc(100vh - 118px);
    }
  }
  .slick-slide {
    width: 312px !important;
    height: 546px !important;
    opacity: 0.3;
  }
  @media screen and (min-width: 801px) {
    .slick-slide {
      width: 220px !important;
      height: auto !important;
      opacity: 1;
    }
    .slick-slide > div {
      height: 266px !important;
    }
  }
  @media screen and (max-width: 650px) {
    .slick-slide {
      opacity: 0.8;
    }
  }
  .slick-center {
    opacity: 1;
  }
  #actual .info-container {
    border-radius: 6px;
    margin: 0 6px 0 6px;
    width: 300px;
    height: 338px;
  }
  @media screen and (min-width: 801px) {
    #actual .info-container {
      border-radius: 5px;
      margin: 0 5px 0 5px;
      width: 210px;
      height: 206px;
    }
  }
  .slick-center .info-container {
    transform: scale(1.05);
  }

  /* adjustment for iphone 5 */
  @media screen and (max-width: 325px) {
    .slick-slider {
      transform: scale(0.9);
      margin-top: -34px;
      margin-left: -12px;
    }
  }
  /* end adjustment for iphone 5 */

  #prev-next {
    width: 780px;
    left: 50%;
    margin-left: -390px;
    position: absolute;
    height: calc(100vh - 74px);
  }
  @media screen and (max-width: 800px) {
    #prev-next {
      display: none;
    }
  }
  #prev-next button {
    z-index: 10;
  }
  .title-handheld {
    height: 189px;
    width: 312px;
    display: table-cell;
    vertical-align: bottom;
    overflow: hidden;
  }
  @media screen and (min-width: 801px) {
    .title-handheld {
      height: 59px;
      width: 220px;
    }
  }
  #actual h1 {
    font-size: 45px;
    line-height: 1.2;
    font-weight: 700 !important;
    color: #00eb82;
    margin-top: 70px;
    margin-bottom: 0.5rem;
  }
  @media screen and (min-width: 801px) {
    #actual h1 {
      font-size: 24px;
      margin-top: 10px;
    }
  }
  #actual p {
    margin-top: -12px;
    margin-bottom: 12px;
  }
  @media screen and (min-width: 801px) {
    #actual p {
      margin-top: -17px;
      margin-bottom: -26px;
    }
    #actual p img {
      width: 21px;
      height: auto;
    }
  }
  #actual .pins {
    width: 300px;
    padding-bottom: 5px;
    display: table-cell;
    background-image: url(../assets/images/pin_small.png);
    background-repeat: no-repeat;
    background-position: center 23px;
    margin-top: 0 !important;
    border: none;
  }
  @media screen and (min-width: 801px) {
    #actual .pins {
      width: 210px;
      background-position: center 5px;
      background-size: auto 55px;
    }
  }
  #actual .timeseries {
    width: 300px;
    padding-bottom: 5px;
    display: table-cell;
    margin-top: 0 !important;
    border: none;
  }
  @media screen and (min-width: 801px) {
    #actual .timeseries {
      width: 210px;
    }
  }
  #actual .timeseries h1 {
    font-size: 42px;
    line-height: 50px;
    max-height: 189px;
    margin: 0 0 20px 0;
    padding: 0 15px;
    overflow: hidden;
  }
  @media screen and (min-width: 801px) {
    #actual .timeseries h1 {
      font-size: 21px;
      line-height: 27px;
      margin: 0 0 4px 0;
      padding: 0 10px;
      max-height: 54px;
    }
  }
  .slick-dots {
    width: 700px;
    margin: auto;
    overflow: hidden;
  }
  .slick-dots li {
    float: left;
    width: 12px;
  }
  .title-handheld textarea {
    width: 100%;
    font-size: 14px;
  }
  .alert-danger {
    margin: 0px !important;
    background-color: #ff2146 !important;
    font-size: 16px;
    color: white !important;
    border: 0px !important;
    border-radius: 0px !important;
  }
</style>

<template>
  <transition name="router-animation" enter-active-class="animated slideInDown" leave-active-class="animated slideOutDown" mode="out-in">
    <modal name="instructionsModal" height="auto" width="800px" :adaptive="true" @before-close="beforeClose">
      <div class="vue-dialog">
        <div class="vue-dialog-content">
          <instructions-content :pageIndex="currentInstructionPageIndex"></instructions-content>
        </div>

        <div class="vue-dialog-nav">
          <nav aria-label="Pagination">
            <ul class="pagination">
              <li class="page-item" v-if="currentInstructionIndex != 0">
                <a class="page-link" @click="previousInstruction()" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>

              <li class="page-item" :class="{ active: currentInstructionIndex == index }" v-for="(i,index) in maxInstructionIndex+1" :key="index">
                <a class="page-link" @click="setCurrentInstructionIndex(index)" href="#">{{ index+1 }}</a>
              </li>

              <li class="page-item" v-if="(currentInstructionIndex) < maxInstructionIndex">
                <a class="page-link" @click="nextInstruction()" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <div class="vue-dialog-buttons">
          <label for="checkbox">{{ $t('settings.do_not_show_instructions') }}</label><input id="checkbox" type="checkbox" v-model="doNotShowAgain" />
          <button type="button" class="vue-dialog-button" @click="$modal.hide('instructionsModal')" style="flex: 1 1 100%;">{{ $t('settings.ok') }}</button>
        </div>
      </div>
    </modal>
  </transition>
</template>


<script>
  import axios from 'axios';
  import InstructionsContent from './InstructionsContent.vue'

  export default {
    name: "InstructionModal",
    components: {
      InstructionsContent
    },
    computed: {
      maxInstructionIndex() {
        return this.indexList.length-1;
      },
      currentInstructionPageIndex() {
        return this.indexList[this.currentInstructionIndex];
      },
      indexList() {
        if (this.$store.state.isPublicAccount) {
          return  this.publicTutorialIndexes
        }
        return this.tutorialIndexes;
      },
      isSimple() {
        return this.$store.state.isSimple;
      }
    },
    data() {
      return {
        //Keep track which instruction index has been read.
        instructionsReadIndex: 0,
        currentInstructionIndex: this.instructionsReadIndex,

        //Used for the checkbox.
        doNotShowAgain: false,
        isShown: false,

        //Indexes of tutorial pages allowed for normal users.
        tutorialIndexes: [0,1,2,3,4,5],

        //Indexes of tutorial pages allowed for public users.
        publicTutorialIndexes: [0,1,2,4]
      }
    },
    watch:{
      // eslint-disable-next-line
      $route (to, from){
        this.loadInstructionComponent();
      }
    },
    mounted() {
      this.loadInstructionComponent();
    },
    methods: {
      beforeClose: function () {
        this.instructionsReadIndex = this.doNotShowAgain ? this.maxInstructionIndex : 0;
        if (!this.$store.state.publicAccount) {
          this.$store.state.profile.details.tutorial_progress = this.instructionsReadIndex;
          axios.patch('/user/me/', {
            "details": {
              "tutorial_progress": this.indexList[this.instructionsReadIndex]
            },
          })
        } else {
          localStorage.setItem('tutorial_progress', this.indexList[this.instructionsReadIndex]);
        }

      },
      loadInstructionComponent() {
        if (this.isSimple || this.isShown || ['terms', 'logout'].includes(this.$router.currentRoute.name)) {
          // Do not show for the simple version.
          // Only show the instruction once per session.
          // Do not show instruction modal over Terms&Conditions and logout page
          return;    
        }
        if(this.$store.state.token) {
          this.$store.dispatch('retrieveJustProfile').then(() => {
            if (!this.$store.state.publicAccount) {
              this.loadInstructionProgress(this.$store.state.profile.details.tutorial_progress);
            } else {
              //Keep track in localStorage
              let tutorialProgress = localStorage.getItem('tutorial_progress') || 0;
              this.loadInstructionProgress(tutorialProgress);
            }
          });
        }
      },
      loadInstructionProgress(tutorialProgress) {
        this.setCurrentInstructionIndexByValue(tutorialProgress);
        if (this.currentInstructionIndex == 0 && window.innerWidth > 800) {
          this.$modal.show('instructionsModal');
          this.setInstructionReadIndex(this.currentInstructionIndex);
          this.isShown = true;
        }
      },
      nextInstruction(){
        if (this.maxInstructionIndex >  this.currentInstructionIndex) {
          this.currentInstructionIndex++;
          this.setInstructionReadIndex();
        }
      },
      previousInstruction(){
        if (this.currentInstructionIndex > 0) {
          this.currentInstructionIndex--;
        }
      },
      setInstructionReadIndex(){
        //Sets the index of read to the highest index the user has read.
        //This index will be saved in the backend when closing the instruction modal.
        this.instructionsReadIndex = this.currentInstructionPageIndex;
      },
      setCurrentInstructionIndexByValue(value){
        this.currentInstructionIndex = this.indexList.findIndex(x => x == value)

        if (this.currentInstructionIndex == -1) {
          this.currentInstructionIndex = this.maxInstructionIndex;
        }

        this.setInstructionReadIndex();
      },
      setCurrentInstructionIndex(index){
        if (index <= this.maxInstructionIndex && index >= 0) {
          this.currentInstructionIndex = index;
        }
        this.setInstructionReadIndex();
      }
    }
  }
</script>


<style scoped>
  .vue-dialog {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
  }

  .vue-dialog-content {
    float:left;
    width:100%;
    padding:15px;
  }

  .vue-dialog-nav {
    float:left;
    margin:10px 0px;
    height:45px;
    text-align:center;
    width:100%;
  }

  .vue-dialog-nav nav {
    display: table;   /* Allow the centering to work */
    margin: 0 auto;
  }

  .vue-dialog-nav ul {
    min-width: 200px;
    list-style: none;
  }

  .vue-dialog-nav li {
    float:none;
    list-style:none;
    display:inline;
  }

  .vue-dialog-buttons label {
    width:50%;
    margin-top:10px;
    margin-left:10px;
  }

  .vue-dialog-buttons input {
    margin-top:2px;
    margin-left:-80px;
  }
</style>

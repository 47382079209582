<template>
  <div class="scenario-card">
    <a v-if="isSuccess" :href="folderUrl" target="_blank">
      <img class="scenario-download" src="../../assets/images/nav_settings_active.png" />
    </a>
    <img v-else class="scenario-download" src="../../assets/images/nav_settings_active.png" />
    <img class="scenario-delete-cross" @click="deleteScenario" src="../../assets/images/delete-cross.png" />
    <h4>{{ config.name }} <img class="scenario-status-image" :src="statusImage" /></h4>
    <h5 v-if="results.percentage && results.percentage < 100">{{ results.percentage }}%</h5>
    <h5 v-if="results.percentage == 100">{{ $t('scenario.convert_data') }}</h5>
    <table class="scenario-urls" v-if="isSuccess">
      <tr v-for="param in allParams" :key="param.name">
        <td>{{ param.name }}</td><td><a v-for="url in param.urls" :key="url.name" :href="url.url" target="_blank">{{ url.name }} </a></td>
      </tr>
      <tr><td></td><td><a :href="logUrl" target="_blank">{{ $t('scenario.log') }}</a></td></tr>
    </table>
  </div>
</template>


<script>
  import i18n from '@/i18n';
  export default {
    name: 'scenarioCard',
    props: ['id', 'config', 'results'],
    data () {
      return {
        statusImages: {                                                                 // TODO: Create suitable images for this page.
          "PENDING": require('../../assets/images/clock.png'),
          "STARTED": require('../../assets/images/clock.png'),
          "PROGRESS": require('../../assets/images/clock.png'),
          "SUCCESS": require('../../assets/images/checkmark-timeseries.png'),
          "REVOKED": require('../../assets/images/delete-cross.png'),
          "FAILURE": require('../../assets/images/delete-cross.png')
        },
        translations: {
          "h": i18n.t('scenario.h'),
          "cs": i18n.t('scenario.cs'),
          "s": i18n.t('scenario.s'),
          "pn": i18n.t('scenario.pn'),
          "qd": i18n.t('scenario.qd'),
          "q1": i18n.t('scenario.q1'),
          "q2": i18n.t('scenario.q2'),
          "q3": i18n.t('scenario.q3'),
          "q4": i18n.t('scenario.q4'),
          "gxg": i18n.t('scenario.gxg')
        }
      }
    },
    computed: {
      isSuccess() {
        if (this.results.result && !this.results.result.error) {
          return true;
        }
        else {
          return false;
        }
      },
      statusImage() {
        return this.statusImages[this.results.state] || this.statusImages["PENDING"];
      },
      folderUrl() {
        return 'https://output.fixeau.com' + this.results.result.folder.replace('/data/runs/kelpgw/', '/runs/kelpgw/') + '/output';
      },
      logUrl() {
        return this.results.result.urls.logfile;
      },
      allParams() {
        var allParams = [];
        var params = this.results.result.urls.params;
        for (var param in params) {
          allParams.push(this.paramUrls(param, params[param]));
        }
        return allParams;
      }
    },
    methods: {
      deleteScenario() {
        this.$emit('delete-scenario', this.id);
      },
      paramUrls(param, urls) {
        let dataUrls = [
          {
            url: urls.url,
            name: 'NetCDF'
          }
        ];
        if (param == 'gxg') {
          dataUrls.push({
            url: urls.wms.url + '?service=WMS&version=1.3.0&request=GetCapabilities&map=' + urls.wms.map,
            name: 'WMS'
          })
        }
        return {
          name: this.translations[param],
          urls: dataUrls
        };
      }
    }
  }
</script>


<style scoped>
  .scenario-card {
    width: 750px;
    margin: 10px;
    padding: 5px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color: #6666ba;
    background-color:  #00008c;
    border-color: #6666ba;
    border: solid 2px;
    border-radius: 6px;
  }
  .scenario-card h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 28px;
    color: #00eb82;
  }
  .scenario-card h5 {
    color: #6666ba;
  }
  .scenario-download {
    width: 18px;
    height: 18px;
    padding-top: 3px;
    float: left;
  }
  .scenario-delete-cross {
    width: 18px;
    height: 24px;
    float: right;
  }
  .scenario-status-image {
    width: 20px;
    height: 23px;
    padding-bottom: 3px;
  }
  .scenario-urls {
    margin: auto;
  }
  .scenario-urls td {
    padding-left: 10px;
    text-align: right;
    font-size: small;
  }
</style>

<template>
  <div class="card">
    <button class="btn delete-btn" @click="ignoredNotification(notification.id)">
      <v-icon name="times-circle"></v-icon>
    </button>
    <img class="notification-alert" src="@/assets/images/notification-alert.png" alt="" width="35px" height="31px">
    <Classify v-if="notification.notification_type === 'Classify'" :notification="notification"></Classify>
    <TimeseriesNotification v-if="notification.notification_type === 'SeriesSettings'" :notification="notification"></TimeseriesNotification>
  </div>
</template>


<script>
import Classify from "./Classify"
import TimeseriesNotification from "./TimeseriesOptionsNotification"
import {mapActions} from "vuex"
export default {
  props: ["notification"],
  components: {Classify, TimeseriesNotification},
  methods: {
    ...mapActions(['ignoredNotification'])
  }
}
</script>


<style scoped>
  .card {
    padding: 0px;
    margin: 40px 20px;
    font-family: "Dosis";
  }
  .notification-alert {
    margin: 30px auto 0px auto;
  }
  .delete-btn {
    background: white;
    color: red;
    font-size: 12px;
    font-weight: bold;
    width: 30px;
    height: 30px;
    right: 5px;
    position: absolute;
  }
  .delete-btn:active, .delete-btn:focus {
    outline: none;
    box-shadow: none;
  }
</style>
<style>
  .text {
    margin: 8px 20px 30px 20px;
    font-size: 20px;
    color: #000000;
    font-weight: 400;
  }
</style>

<template>
  <div class="scenario-overview">
    <router-link class="btn btn-create" :to="{ name: 'scenarioCreate' }">
      <h3>{{ $t('scenario.create_new') }}</h3>
    </router-link>
    <h3>{{ $t('scenario.scenarios') }}</h3>
    <div v-if="scenarios.length == 0">
      <h4 v-if="isLoaded">{{ $t('scenario.no_scenarios') }}</h4>
      <h4 v-else>{{ $t('scenario.load_scenarios') }}</h4>
    </div>
    <scenario-card v-for="scenario in scenarios" :key="scenario.id" :id="scenario.id" :config="scenario.config" :results="scenario.results"  @delete-scenario="deleteScenario"/>
  </div>
</template>


<script>
  import axios from 'axios'
  import scenarioCard from './scenarioCard.vue'

  export default {
    name: "scenarioOverview",
    data () {
      return {
        isLoaded: false,
        scenarios: []
      }
    },
    methods: {
      deleteScenario(id) {
        axios.delete('/modelrun/' + id + '/');
        this.scenarios = this.scenarios.filter(scenario => scenario.id !== id);
      },
      isRunning() {
        var runningStates = ["PENDING", "STARTED", "PROGRESS"];
        return this.scenarios.some(scenario => runningStates.includes(scenario.results.state));
      },
      loadResults() {
        var vm = this;
        axios.get('/modelrun/').then(
          response => {
            vm.scenarios = response.data.results;
            vm.isLoaded = true;
            if (vm.isRunning()) {
              setTimeout(() => vm.loadResults(), 10000);
            }
          }
        );
      }
    },
    mounted() {
      this.loadResults();
    },
    components: {
      scenarioCard
    }
  }
</script>


<style scoped>
  .scenario-overview {
    width: 75%;
    margin: 20px auto;
  }
  .scenario-overview h3 {
    font-weight: 500;
  }
  .btn-create {
    float: right;
  }
  .btn-create h3 {
    color: #007bff;
  }
</style>

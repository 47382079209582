import Vue from 'vue'
Vue.config.devtools = true
Vue.config.performance = true

import App from '@/App.vue'

import router from '@/router'
import Vuex from 'vuex'

import VueGtag from "vue-gtag";


//VeeValidate is a validation framework for fields validation
import VeeValidate from 'vee-validate'
//Bootstrap for VueJS
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'

import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'

//import VuePouchDB from 'vuejs-pouchdb'
//import debugPouch from 'pouchdb-debug'


Vue.component('v-icon', Icon)
//Popup modal https://www.npmjs.com/package/vue-js-modal
import VModal from 'vue-js-modal';

Vue.use(VModal, { dialog: true });
//Vuex store
import { store } from './store/store'
Vue.use(Vuex);  // Vue.use(VueRouter)
Vue.use(BootstrapVue);

//VuePouchDB.plugin(debugPouch)
// enable debug
//VuePouchDB.pouchdb.debug.enable('*')
//Vue.use(VuePouchDB, { name: 'leaflet-timedimension-tiles' })

Vue.use(VeeValidate,{events: 'change'});

import {InlineSvgPlugin} from 'vue-inline-svg';
Vue.use(InlineSvgPlugin);

import Highcharts from 'highcharts';
import VueHighcharts from 'vue-highcharts';
import boost from "highcharts/modules/boost";
import Exporting from 'highcharts/modules/exporting';
import noData from 'highcharts/modules/no-data-to-display';
import i18n from './i18n'
Exporting(Highcharts);
boost(Highcharts);
noData(Highcharts);
Vue.use(VueHighcharts, { Highcharts });


Vue.config.productionTip = false
// Checking if the url is public or private depending on if the token available in the query or not
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
        name: 'login',
      })
    } else {
      //Public token has been expired, logout!
      const urlParams = new URLSearchParams(location.search);
      const token = urlParams.get('token');
      if (!token) {
        store.dispatch('retrieveJustProfile');
      }

      //Reload notifications
      if (!store.state.publicAccount) {
        store.dispatch('fetchNotifications');
      }
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters.loggedIn) {
      next({
        name: 'home',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

window.eventBus = new Vue()

function catchQuery() {
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('token');
  if (token) {
    //Public login through a token in url!
    store.state.token = token;
    let expire = (new Date()).getTime() + (24 * 60 * 60 * 1000);
    localStorage.setItem('tokenExpire', expire);
    localStorage.setItem('token', token);
  }
}

Vue.use(VueGtag, {
  config: { id: "G-3L28GFPLDL" } ,
  params: {
    send_page_view: true
  },
  //Only load after cookie accepted
  bootstrap: false
}, router);

const vue = new Vue({
  router,
  store,
  methods: catchQuery(),
  i18n,
  render: h => h(App)
})
vue.$mount('#app')

<template>
  <div>
    <div v-if="!details">
      <div class="page-container">
        <b-list-group class="main container">
          <div class="back">
            <router-link to="/actual"> </router-link>
            <router-link class="grafiek" :to="{ name: 'analysis', params: {selectedTimeseries: grafiekData} }">{{ $t('measurements.chart') }}</router-link>
          </div>
          <h2 class="mb-4">{{handheld.name}}</h2>
          <h2 v-if="measurements[0]"><small>{{convertUnit(measurements[0].unit)}}</small></h2>
          <div class="list-container">
            <b-list-group-item v-for="(measurement, index) in measurements" :key="index" @click="goToDetails(measurement)">
              <p class="float-left">{{convert(measurement.time)}}</p><p class="float-right ">{{measurement.value.toFixed(3)}}</p>
              <br>
              <span>{{localTime(measurement.time)}}</span>
            </b-list-group-item>
          </div>
        </b-list-group>
      </div>
    </div>

    <div v-else>
      <div class="page-container-gray">
        <div class="main container">
          <div class="back">
            <a @click="goBack()"> </a>
          </div>
          <h3 class="mb-4"><strong>{{handheld.name}}</strong></h3>
          <div id="control">
            <a v-if="previous" class="d-inline-block" @click="previousMeasurement()"><img class="mb-1" src="../../assets/images/arrow-left.png" alt=""/></a>
            <p class="d-inline-block timeMiddle text-center">{{convert(currentMeasurement.time)}}, {{localTime(currentMeasurement.time)}}</p>
            <a v-if="next" class="d-inline-block" @click="nextMeasurement()"><img class="mb-1" src="../../assets/images/arrow-right.png" alt=""/></a>
          </div>
          <div class="maesurements text-center">
            <fixeauModuleMap class="text-center" :modules='[currentMeasurement]'> </fixeauModuleMap>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import fixeauModuleMap from '../map/fixeauModuleMap';
  import i18n from '@/i18n';
  export default {
    props: ['handheld'],
    name: "measurementsList",
    components: {fixeauModuleMap},
    data () {
      return {
        measurements: [],
        grafiekData: [],
        details: false,
        currentMeasurement: {},
        previous: true,
        next: true,
        pxpercm: 15,
      }
    },
    mounted () {
      let url = `/series/${this.handheld.id}/data/?format=json`;
      axios
        .get(url)
        .then(response => {
          this.measurements = response.data.slice(0, 100);
          this.measurements.forEach(element => {
            element.parameter_name = this.handheld.parameter
          });
          let handheld = _.cloneDeep(this.handheld);
          handheld.latest.series = handheld.id;
          handheld.latest.parameter = handheld.parameter;
          let grafiekDataObject = _.groupBy([handheld.latest], 'series');
          this.grafiekData = grafiekDataObject[Object.keys(grafiekDataObject)[0]];
        })
        .catch(error => alert(error))

    },
    computed: {

    },
    watch: {
      currentMeasurement() {
        let index = this.measurements.indexOf(this.currentMeasurement);
        if(index >= 0){
          if(this.measurements[index - 1] === undefined && this.measurements[index + 1] === undefined) {
            this.next = false;
            this.previous = false;
          } else if (this.measurements[index - 1] === undefined) {
            this.next = false;
            this.previous = true;
          } else if (this.measurements[index + 1] === undefined) {
            this.previous = false;
            this.next = true;
          } else {
            this.next = true;
            this.previous = true;
          }
        }
      }
    },
    methods: {
      convertUnit(unit){
        if(unit === '-') {
          return ''
        } else {
          return unit
        }
      },
      convert(date) {
        const monthNames = [
          i18n.t('datetime.january'),
          i18n.t('datetime.february'),
          i18n.t('datetime.march'),
          i18n.t('datetime.april'),
          i18n.t('datetime.may_long'),
          i18n.t('datetime.june'),
          i18n.t('datetime.july'),
          i18n.t('datetime.august'),
          i18n.t('datetime.september'),
          i18n.t('datetime.october'),
          i18n.t('datetime.november'),
          i18n.t('datetime.december')
        ];
        const d = new Date(date);
        const now = new Date();
        if (d.toDateString().slice(0,10) === now.toDateString().slice(0,10)){
          return i18n.t('datetime.today')
        } else {
          return `${date.slice(8,10)} ${monthNames[d.getMonth()]} ${date.slice(0,4)}`
        }
      },
      localTime: function(timeString) {
        // Convert an ISO timestring to the format HH:MM in local time.
        return new Date(timeString).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})
      },
      goBack: function(){
        this.details = !this.details
      },
      goToDetails: function(measurement){
        this.currentMeasurement = measurement;
        // this.details = !this.details;  // Note Stefan: detail page is ugly and useless???
      },
      previousMeasurement: function () {
        let index = this.measurements.indexOf(this.currentMeasurement);
        if(index >= 0 && index < this.measurements.length - 1){
          this.currentMeasurement = this.measurements[index + 1]
        }
      },
      nextMeasurement: function () {
        let index = this.measurements.indexOf(this.currentMeasurement);
        if(index >= 0 && index < this.measurements.length + 1){
          this.currentMeasurement = this.measurements[index - 1]
        }
      }
    }
  }
</script>

<style scoped>
  /* .main {
    width: 400px;
  }
  .main .list-group-item:hover  {
    background-color: yellow;
    cursor: pointer;
  }
  .grafiek {
    background-image: none;
    float: right;
  }
  .grafiek:active {
    outline:none
  }
  .grafiek::selection {
    background: #F2EFE9;
  }
  .timeMiddle {
    width: 330px;
  }
  #control a {
    color: #1978cf;
    cursor: pointer;
  }
  .back a {
    cursor: pointer;
  } */

</style>

<style scoped>
  .page-container, .page-container-gray {
    background-color: #f2efe9;
    height: 100%;
    min-height: calc(100vh - 70px);
    text-align: left;
  }
  .page-container-gray {
    background-image: url(../../assets/images/background-gray.png);
    background-position: left 195px;
    background-repeat: repeat-x;
  }
  .main {
    width: calc(100% - 60px);
    max-width: 500px;
    margin: auto;
    padding: 0;
  }
  .main h2 {
    font-family: 'Dosis', sans-serif;
    font-weight: 700;
    color: #000000;
    margin: 0;
    padding: 0;
    border: 0;
  }
  .list-container  {
    box-shadow: 0px 8px 15px 0px rgba(27, 33, 58, 0.2);
    border-radius: 8px;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 800px) {
    .list-container {
      margin-bottom: 100px;
    }
  }
  .list-group-item {
    border: none;
    border-bottom: 1px solid #F2EFE9;
    margin: 0;
    padding: 22px 15px 15px 66px;
    background-color: #ffffff;
    background-image: url(../../assets/images/clock.png);
    background-repeat: no-repeat;
    background-position: 25px center;
  }
  .list-group-item:last-child {
    border: none;
  }
  .main .list-group-item:hover  {
    background-color: #F2EFE9;
    cursor: pointer;
  }
  @media screen and (max-width: 800px) {
    .main .list-group-item:hover  {
      background-color: #ffffff;
    }
  }
  .list-group-item p {
    font-size: 20px;
    margin: 0;
  }
  .list-group-item span {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #404040;
  }
  .grafiek {
    background-image: none;
    float: right;
  }
  .grafiek:active {
    outline:none
  }
  .grafiek::selection {
    background: #F2EFE9;
  }
  .timeMiddle {
    width: 282px;
  }
  #control {
    font-size: 22px;
    padding-bottom: 30px;
    width: 300px;
    margin: 0 auto;
  }
  #control a {
    color: #1978cf;
    cursor: pointer;
  }
  .back a {
    cursor: pointer;
  }
  @media screen and (max-width: 800px) {
    .container-gray {
      margin-left: -30px;
      margin-right: -30px;
    }
  }
  .maesurements {
    border-radius: 6px;
    margin: 12px auto;
    width: 300px;
    height: 338px;
    background-color: #ffffff;
    overflow: hidden;
  }
</style>
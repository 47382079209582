<template>
  <div class="nameModifingContainer">
    <form @submit="saveTimeseriesName">
      <h3>{{ $t('settings.series_name') }}:</h3>
      <span class="form-error">{{ errors.first('name') }}</span>
      <input type="text" v-model="newName" name="name" v-validate="'required|max:32'"
       :class="{ 'input-error':errors.has('name') }" size="42" style="width: 320px;">
      <button type="submit" class="btn save-btn">{{ $t('settings.save') }}</button>
    </form>
  </div>
</template>


<script>
import {mapActions, mapMutations} from 'vuex'
import i18n from '@/i18n'

export default {
  name: "modifySeriesName",
  props: ['id', 'name'],
  data() {
    return {
      newName: this.name
    }
  },
  mounted() {
      const dictionary = {
        en: {
          custom: {
            name: {
              required: i18n.t('settings.name_required'),
              max: i18n.t('settings.max_characters')
            },
          }
        }
      }
      this.$validator.localize(dictionary);
  },
  methods: {
    ...mapMutations(['setSuccessfulAlertCounter']),
    ...mapActions(['saveTimeseriesName']),
    saveTimeseriesName(event) {
      event.preventDefault();
      
      const data = {
        id: this.id,
        name: this.newName
      }
      this.$validator.validateAll().then(() => {
          if (!this.errors.any()) {
            this.$store.dispatch('saveTimeseriesName', data).then(() => {
              this.$parent.name = this.newName
              this.$router.push({
                path: '/series',
                query: {
                  id: this.id,
                  name: this.newName
                }
              });
              this.setSuccessfulAlertCounter(2)
            })
          }
      })
    }
  }
}
</script>


<style scoped>
  .nameModifingContainer {
    padding-bottom: 20px; 
    border-bottom: 1px solid gray;
  }
  .nameModifingContainer .btn {
    display: block;
    margin: 10px auto;
  }
  .input-error {
    border: 1px solid #e91a1a;
  }
</style>

import axios from 'axios';

const state = {
  deviceTimeseries: [],
  soilTypeData: {},
  classificationData: {},
  successfulAlertCounter: 0
};

const getters = {
  allDeviceTimeseries: (state) => state.deviceTimeseries,
  soilTypeData: (state) => state.soilTypeData,
  classificationData: (state) => state.classificationData,
  successfulAlertCounter: (state) => state.successfulAlertCounter
};

const actions = {
  async fetchDeviceTimeseries({ commit }, device_id) {
    const response = await axios.get(
      `/series/?source=${device_id}&parameter__in=EC,T,NO3,O2,WaterLevel,pH,SoilMoisture,Precipitation,SolarRadiation,WindSpeed,WindDirection`
    );
    commit('setDeviceTimeseries', response.data.results)
  },
  async fetchSoilTypeData({ commit }, id) {
    const response = await axios.get(
      `/soiltype/${id}/`
    );
    commit('setSoilTypeData', response.data)
  },
  // eslint-disable-next-line
  async saveSoilType({ commit }, data) {
    await axios.post(
      `/soiltype/${data.id}/`,
      {
        "soil_type": data.selected
      }
    );
  },
  async fetchClassificationData({ commit }, id) {
    const response = await axios.get(
      `/classification/${id}/`
    );
    commit('setClassificationData', response.data)
  },
  // eslint-disable-next-line
  async saveClassification({ commit }, data) {
    await axios.patch(
      `/classification/${data.id}/`,
      {
        "default": {"choices": data.choices}
      }
    );
  },
  // eslint-disable-next-line
  async saveTimeseriesName({ commit }, data) {
    await axios.patch('/series/' + data.id + '/', 
    {
      "name": data.name,
    })
  }
};

const mutations = {
  setDeviceTimeseries: (state, deviceTimeseries) => (state.deviceTimeseries = deviceTimeseries),
  setSoilTypeData: (state, soilTypeData) => (state.soilTypeData = soilTypeData),
  setClassificationData: (state, classificationData) => (state.classificationData = classificationData),
  setSuccessfulAlertCounter: (state, counter) => (state.successfulAlertCounter = counter),
};

export default {
  state,
  getters,
  actions,
  mutations
}

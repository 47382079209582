<template>
  <div class="scenario-create">
    <h3>{{ $t('scenario.create_new') }}</h3>
    <h4 v-if="isFailed">{{ $t('scenario.something_went_wrong') }}</h4>
    <h4 v-if="isSending">{{ $t('scenario.process') }}</h4>
    <form @submit.prevent="onSubmit" v-else>
      <table>
        <tr>
          <td>
            <label for="scenario-name">{{ $t('scenario.name') }}:</label>
          </td>
          <td>
            <input type="text" id="scenario-name" name="name" v-model="name" :placeholder="scenarioName" required>
          </td>
        </tr>
        <tr>
          <td>
            <label for="scenario-start">{{ $t('scenario.start') }}:</label>
          </td>
          <td>
            <input type="text" id="scenario-start" name="start" v-model="start" placeholder="2019-01-01" required v-validate="'regex:^20[0-9]{2}-[0-1][0-9]-[0-3][0-9]$'">
            <p class="validate-error">{{ errors.first('start') }}</p>
          </td>
        </tr>
        <tr>
          <td>
            <label for="scenario-stop">{{ $t('scenario.stop') }}:</label>
          </td>
          <td>
            <input type="text" id="scenario-stop" name="stop" v-model="stop" placeholder="2019-12-31" required v-validate="'regex:^20[0-9]{2}-[0-1][0-9]-[0-3][0-9]$'">
            <p class="validate-error">{{ errors.first('stop') }}</p>
          </td>
        </tr>
        <tr>
          <td>
            <label for="scenario-climate">{{ $t('scenario.climate') }}:</label>
          </td>
          <td>
            <select name="scenario-climate" v-model="climate">
              <option value="">-</option>
              <option value="WH2050">WH2050</option>
              <option value="WH2085">WH2085</option>
            </select>
          </td>
        </tr>
        <tr v-if="showDateError">
          <td colspan="2">
            <p class="validate-error">{{ $t('scenario.date_error') }}</p>
          </td>
        </tr>
      </table>
      <div class="scenario-modification" v-if="areas">
        <h4>{{ $t('scenario.change_level') }} <button type="button" class="btn save-btn" @click="onHelpSelect">[?]</button></h4>
        <level-modification v-for="modification in levelModifications" :key="modification.id" :modification="modification" :areas="areas" @delete-modification="deleteLevelModification"/>
        <button type="button" class="btn save-btn" @click="addLevelModification">[+]</button>
      </div>
      <div class="scenario-modification" v-else>
        <h4>{{ $t('scenario.load_level_map') }}</h4>
      </div>
      <div class="scenario-modification">
        <h4>{{ $t('scenario.change_base_level') }} <button type="button" class="btn save-btn" @click="onHelpDraw">[?]</button></h4>
        <base-level-modification v-for="modification in baseLevelModifications" :key="modification.id" :modification="modification" @delete-modification="deleteBaseLevelModification"/>
        <button type="button" class="btn save-btn" @click="addBaseLevelModification">[+]</button>
      </div>
      <div class="scenario-modification">
        <h4>{{ $t('scenario.change_resistance') }} <button type="button" class="btn save-btn" @click="onHelpDraw">[?]</button></h4>
        <resistance-modification v-for="modification in resistanceModifications" :key="modification.id" :modification="modification" @delete-modification="deleteResistanceModification"/>
        <button type="button" class="btn save-btn" @click="addResistanceModification">[+]</button>
      </div>
      <h4>{{ $t('scenario.select_output') }}</h4>
      <div class="output-option" v-for="outputOption in outputOptions" :key="outputOption.value">
        <input type="checkbox" :id="outputOption.value" :value="outputOption.value" v-model="output">
        <label :for="outputOption.value">{{ outputOption.name }}</label>
      </div>
      <div class="output-option">
        <input type="checkbox" id="is_ensemble" v-model="is_ensemble">
        <label for="is_ensemble">{{ $t('scenario.uncertainty') }}</label>
      </div>
      <p class="validate-error" v-if="showOutputError">{{ $t('scenario.output_error') }}</p>
      <p class="validate-error" v-if="showModificationError">{{ $t('scenario.modification_error') }}</p>
      <br>
      <p>
        <button type="submit" class="btn save-btn">{{ $t('scenario.compute_scenario') }}</button>
      </p>
    </form>
  </div>
</template>


<script>
  import { mapState, mapMutations } from 'vuex';
  import axios from 'axios'
  import L from 'leaflet';
  import i18n from '@/i18n';
  import levelModification from './levelModification.vue'
  import baseLevelModification from './baseLevelModification.vue'
  import resistanceModification from './resistanceModification.vue'

  export default {
    name: "scenarioCreate",
    data () {
      return {
        isFailed: false,
        isSending: false,
        isSubmitted: false,
        name: "",
        start: "",
        stop: "",
        climate: "",
        outputOptions: [
          {name: i18n.t('scenario.h'), value: "h"},
          {name: i18n.t('scenario.pn'), value: "pn"},
          {name: i18n.t('scenario.q4'), value: "q4"},
          {name: i18n.t('scenario.q3'), value: "q3"},
          {name: i18n.t('scenario.q2'), value: "q2"},
          {name: i18n.t('scenario.q1'), value: "q1"},
          {name: i18n.t('scenario.qd'), value: "qd"},
          {name: i18n.t('scenario.gxg'), value: "gxg"}
        ],
        output: [],
        is_ensemble: false,
        areas: null,
        showModificationError: false
      }
    },
    computed: {
      ...mapState('scenarioState', ['levelModifications', 'baseLevelModifications', 'resistanceModifications']),
      scenarioName() {
        return i18n.t('scenario.scenario_name');
      },
      isValidDates() {
        return this.start < this.stop && this.isValidDate(this.start) && this.isValidDate(this.stop);
      },
      isValidOutput() {
        return this.output.length > 0;
      },
      showDateError() {
        return this.start.length == 10 && this.stop.length == 10 && !this.isValidDates;
      },
      showOutputError() {
        return this.isSubmitted && !this.isValidOutput;
      }
    },
    methods: {
      ...mapMutations('scenarioState', [
        'pushLevelModification', 'pushBaseLevelModification', 'pushResistanceModification',
        'deleteLevelModification', 'deleteBaseLevelModification', 'deleteResistanceModification'
      ]),
      onHelpDraw() {
        alert(i18n.t('scenario.help_draw'));
      },
      onHelpSelect() {
        alert(i18n.t('scenario.help_select'));
      },
      modifications() {
        return this.levelModifications.concat(this.baseLevelModifications).concat(this.resistanceModifications);
      },
      uniqueID() {
        var modifications = this.modifications();
        if (modifications.length == 0) return 0;
        var ids = modifications.map(modification => modification.id);
        return Math.max(...ids) + 1;
      },
      addLevelModification() {
          var modification = {
            id: this.uniqueID(),
            operator: "+",
            value: 0.0,
            layer: new L.FeatureGroup(),
            start: '',
            stop: ''
          }
          this.pushLevelModification(modification);
      },
      addBaseLevelModification() {
          var modification = {
            id: this.uniqueID(),
            operator: "+",
            value: 0.0,
            layer: new L.FeatureGroup(),
            level: 4
          }
          this.pushBaseLevelModification(modification);
      },
      addResistanceModification() {
          var modification = {
            id: this.uniqueID(),
            operator: "*",
            value: 1.0,
            layer: new L.FeatureGroup(),
            level: 4
          }
          this.pushResistanceModification(modification);
      },
      serializeLevelModification(modification) {
        return {
          "parameter": "hp",
          "operator": modification.operator,
          "value": modification.value,
          "start": modification.start,
          "stop": modification.stop,
          "features": modification.layer.toGeoJSON().features
        };
      },
      serializeBaseLevelModification(modification) {
        return {
          "parameter": "b" + modification.level,
          "operator": modification.operator,
          "value": modification.value,
          "features": modification.layer.toGeoJSON().features
        };
      },
      serializeResistanceModification(modification) {
        return {
          "parameter": "c" + modification.level,
          "operator": modification.operator,
          "value": modification.value,
          "features": modification.layer.toGeoJSON().features
        };
      },
      serializeLevelModifications() {
        return this.levelModifications.map(this.serializeLevelModification);
      },
      serializeBaseLevelModifications() {
        return this.baseLevelModifications.map(this.serializeBaseLevelModification);
      },
      serializeResistanceModifications() {
        return this.resistanceModifications.map(this.serializeResistanceModification);
      },
      serializeScenario() {
        if (this.output.includes('gxg') && !this.output.includes('h')) {
          this.output.push('h');
        }
        return {
          "name": this.name,
          "start": this.start,
          "stop": this.stop,
          "aoi": null,
          "climate": this.climate,
          "output" : this.output,
          "isEnsemble": this.is_ensemble,
          "modifications": this.serializeLevelModifications().concat(
            this.serializeBaseLevelModifications()
          ).concat(
            this.serializeResistanceModifications()
          )
        };
      },
      isValidModifications() {
        let modifications = this.modifications();
        return modifications.length > 0 && modifications.every((modification) => modification.layer.toGeoJSON().features.length > 0);
      },
      isValidDate(date) {
        const year = new Date(date).getFullYear();    // incorrect date gives NaN, which works correctly
        const thisYear = new Date().getFullYear();
        const yearBounds = {
          '': [1990, thisYear],                       // without climate scenario
          'WH2050': [2035, 2064],
          'WH2085': [2070, 2099]
        }
        const [minYear, maxYear] = yearBounds[this.climate];
        return minYear <= year && year <= maxYear;
      },
      isValidInput() {
        let isValidModifications = this.isValidModifications();
        this.showModificationError = !isValidModifications;
        return this.isValidDates && this.isValidOutput && isValidModifications;
      },
      onSubmit() {
        this.isSubmitted = true;
        let vm = this;
        this.$validator.validateAll().then((isSuccess) => {
          if (isSuccess && vm.isValidInput()) {
            vm.isFailed = false;
            vm.isSending = true;
            axios.post('/modelrun/', {"config": vm.serializeScenario()}).then(
              () => {
                vm.$router.push({ name: 'scenarioOverview', params: {}});
              }
            ).catch(
              function () {
                vm.isFailed = true;
                vm.isSending = false;
              }
            );
          }
        });
      },
      setErrorMessages() {
        const dictionary = {
          en: {
            custom: {
              start: {
                regex: i18n.t('scenario.start_error')
              },
              stop: {
                regex: i18n.t('scenario.stop_error')
              }
            }
          }
        };
        this.$validator.localize(dictionary);
      }
    },
    mounted() {
      var vm = this;
      axios.create(
        { baseURL: '/' }    // Temporarily overwrite the default baseURL.
      ).get('static/data/peilgebieden_WDOD.json').then(
        response => {vm.areas = response.data}
      );
      this.setErrorMessages();
    },
    components: {
      levelModification,
      baseLevelModification,
      resistanceModification
    }
  }
</script>


<style scoped>
  .scenario-create {
    width: 600px;
    margin: 20px auto;
  }
  .scenario-create h3, .scenario-create h4 {
    font-weight: 500;
  }
  .scenario-create table {
    width: 300px;
    margin-left: 25px;
    margin-bottom: 20px;
  }
  .scenario-modification {
    width: 550px;
    margin-left: 25px;
    margin-bottom: 20px;
  }
  .output-option {
    line-height: 14px;
    padding-left: 25px;
  }
  .output-option label {
    padding-left: 5px;
    display: inline-block;
  }
  .validate-error {
    color: red;
    font-size: small;
  }
</style>

<template>
    <div id="main">
      <div id="content">
        <span :style="moduleStyle" class="pin-info-title">{{ modules[0].parameter_name }}<br/><span :style="moduleStyle" v-if="this.$store.state.profile.details.prefers_surface_level && modules[0].parameter_name == 'Grondwaterstand'">(NAP)</span></span>
        <div  :style="moduleStyle" :class="'pin-info-image-' + this.totalValues">
          <inline-svg
                  :src="image"
                  width="60"
                  height="60"></inline-svg>
        </div>
        <div :style="moduleStyle" :class="'pin-info-values-' + this.totalValues">
          <span :style="valueStyle(index)" :class="'pin-info-value-' + index" v-for="(module, index) in modules" :key="'pin'+ index">
            <template v-if="modules[index].value">{{ modules[index].value.toFixed(2)}}</template>
          </span>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    props: ['modules'],
    data () {
      return {
        images: {
          EC: require('../../assets/images/salt.svg'),
          Grondwaterstand: require('../../assets/images/ground_water.svg'),
          Bodemvocht: require('../../assets/images/drop.svg'),
          Neerslag: require('../../assets/images/drop.svg'),
          NO3: require('../../assets/images/no3.svg'),
          O2: require('../../assets/images/no3.svg'),
          pH: require('../../assets/images/ph.svg'),
          T: require('../../assets/images/temperature.svg')
        },
        defaultColor: "#bebeaf"
      }
    },
    computed: {
      image () {
        return this.images[this.modules[0].parameter_name];
      },
      totalValues() {
          if (Array.isArray(this.modules)) {
          return this.modules.length;
        }
        return 0;
      },
      moduleStyle () {
        if (this.modules[0].classification != null) {
          return {
            'fill' : this.modules[0].classification.color,
            'color': this.modules[0].classification.color
          }
        } else {
          return {
            'fill': this.defaultColor,
            'color': this.defaultColor
          }
        }
      },
    },
    methods: {
      valueStyle(index) {
        if (this.modules[index].classification != null) {
          return {
            'color': this.modules[index].classification.color
          }
        } else {
          return {
            'color': this.defaultColor
          }
        }
      }
    }
  }
</script>

<style lang="scss">
  .pin-info-image svg path,
  .pin-info-image-1 svg path,
  .pin-info-image-2 svg path,
  .pin-info-image-3 svg path {
    fill: inherit !important;
  }

  /* map pin info */
  .modalLegends .v--modal-box #main {
    height: 130px;
    float: left;
    overflow: hidden;
    border-left:1px solid #eeeeee;
  }
  .modalLegends .v--modal-box #content {
    background: white;
    padding: 10px 6px;
    width: 84px;
    text-align: center;
  }
  .modalLegends .v--modal-box .pin-info-title {
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
    text-transform: uppercase;
    word-wrap: break-word;
    word-break: break-all;
    color: #bebeaf;
    background: white;
    display: block;
    height: 40px;
  }
  .modalLegends .v--modal-box .pin-info-image-1 svg {
    width: 34px;
    height: auto;
    margin-top: 4px;
  }
  .modalLegends .v--modal-box .pin-info-image-2,
  .modalLegends .v--modal-box .pin-info-image-3 {
    height: 40px;
    width: 34px;
    float: left;
  }
  .modalLegends .v--modal-box .pin-info-image-2 svg,
  .modalLegends .v--modal-box .pin-info-image-3 svg {
    width: 30px;
    height: auto;
    margin-top: 16px;
  }
  .modalLegends .v--modal-box .pin-info-values-2,
  .modalLegends .v--modal-box .pin-info-values-3 {
    height: 64px;
    vertical-align: middle;
    display: table-cell;
  }
  .modalLegends .v--modal-box .pin-info-value-0 {
    font-size: 20px;
    font-weight: 700;
    line-height: 21px;
  }
  .modalLegends .v--modal-box .pin-info-values-2 .pin-info-value-0,
  .modalLegends .v--modal-box .pin-info-values-2 .pin-info-value-1,
  .modalLegends .v--modal-box .pin-info-values-3 .pin-info-value-0,
  .modalLegends .v--modal-box .pin-info-values-3 .pin-info-value-1,
  .modalLegends .v--modal-box .pin-info-values-3 .pin-info-value-2 {
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    text-align: left;
    color: #000000;
    float: right;
    width: 34px;
    padding-left: 8px;
    background-repeat: no-repeat;
    background-position: 0 -14px;
  }
  .modalLegends .v--modal-box .pin-info-values-2 .pin-info-value-0,
  .modalLegends .v--modal-box .pin-info-values-3 .pin-info-value-0 {
    background-image: url(../../assets/images/data_line_small_1.png);
  }
  .modalLegends .v--modal-box .pin-info-values-2 .pin-info-value-1,
  .modalLegends .v--modal-box .pin-info-values-3 .pin-info-value-2 {
    background-image: url(../../assets/images/data_line_small_3.png);
  }
  .modalLegends .v--modal-box .pin-info-values-3 .pin-info-value-1 {
    background-image: url(../../assets/images/data_line_small_2.png);
  }

  /* actual */
  #actual .info-container #main, 
  #actual .info-container a > div {
    width: 146px;
    height: 168px;
    float: left;
    overflow: hidden;
    margin: 0 2px 4px 2px;
  }
  #actual .info-container a:nth-child(3):nth-last-child(1) {
    #main, > div {
      width: 296px;
    }
  }
  #actual .info-container a:nth-child(2):nth-last-child(1), 
  #actual .info-container a:nth-child(1):nth-last-child(2) {
    #main, > div {
      width: 296px;
    }
  }
  @media screen and (min-width: 801px) {
    #actual .info-container #main, 
    #actual .info-container a > div {
      width: 101px;
      height: 101px;
    }
    #actual .info-container a:nth-child(3):nth-last-child(1) {
      #main, > div {
        width: 206px;
      }
    }
    #actual .info-container a:nth-child(2):nth-last-child(1), 
    #actual .info-container a:nth-child(1):nth-last-child(2) {
      #main, > div {
        width: 206px;
      }
    }
  }
  #actual .info-container #content, 
  #actual .info-container a > div > div {
    background: white;
    padding: 20px 6px;
    width: 146px;
    height: 168px;
    text-align: center;
    border-radius: 6px;
  }
  #actual .info-container a:nth-child(3):nth-last-child(1) {
    #content, > div > div {
      width: 296px;
      padding: 20px 81px;
    }
  }
  #actual .info-container a:nth-child(2):nth-last-child(1), 
  #actual .info-container a:nth-child(1):nth-last-child(2) {
    #content, > div > div {
      width: 296px;
      padding: 20px 81px;
    }
  }
  @media screen and (min-width: 801px) {
    #actual .info-container #content, 
    #actual .info-container a > div > div {
      width: 101px;
      height: 101px;
      border-radius: 5px;
      padding: 10px 4px;
    }
    #actual .info-container a:nth-child(3):nth-last-child(1) {
      #content, > div > div {
        width: 206px;
        padding: 10px 58px;
      }
    }
    #actual .info-container a:nth-child(2):nth-last-child(1), 
    #actual .info-container a:nth-child(1):nth-last-child(2) {
      #content, > div > div {
        width: 206px;
        padding: 10px 58px;
      }
    }
  }
  #actual .info-container .pin-info-title {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    text-transform: uppercase;
    color: #bebeaf;
    background: white;
    display: block;
    height: 25px;
  }
  @media screen and (min-width: 801px) {
    #actual .info-container .pin-info-title {
      font-family: 'Poppins', sans-serif;
      font-size: 9px;
      line-height: 13px;
      height: 24px;
    }
  }
  #actual .info-container .pin-info-image-2,
  #actual .info-container .pin-info-image-3 {
    height: 66px;
    width: 66px;
    float: left;
  }
  @media screen and (min-width: 801px) {
    #actual .info-container .pin-info-image-2,
    #actual .info-container .pin-info-image-3 {
      height: 40px;
      width: 40px;
    }
  }
  #actual .info-container .pin-info-image-1 svg {
    width: 66px;
    height: auto;
    margin-top: 0px;
  }
  @media screen and (min-width: 801px) {
    #actual .info-container .pin-info-image-1 svg {
      width: 40px;
    }
  }
  #actual .info-container .pin-info-image-2 svg,
  #actual .info-container .pin-info-image-3 svg {
    width: 42px;
    height: auto;
    margin-top: 30px;
  }
  @media screen and (min-width: 801px) {
    #actual .info-container .pin-info-image-2 svg,
    #actual .info-container .pin-info-image-3 svg {
      width: 29px;
      margin-top: 21px;
      margin-left: 4px;
    }
  }
  #actual .info-container .pin-info-values-2,
  #actual .info-container .pin-info-values-3 {
    height: 102px;
    vertical-align: middle;
    display: table-cell;
  }
  @media screen and (min-width: 801px) {
    #actual .info-container .pin-info-values-2,
    #actual .info-container .pin-info-values-3 {
      height: 70px;
    }
  }
  #actual .info-container .pin-info-value-0 {
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
  }
  @media screen and (min-width: 801px) {
    #actual .info-container .pin-info-value-0 {
      font-size: 20px;
      line-height: 26px;
    }
  }
  #actual .info-container .pin-info-values-2 .pin-info-value-0,
  #actual .info-container .pin-info-values-2 .pin-info-value-1,
  #actual .info-container .pin-info-values-3 .pin-info-value-0,
  #actual .info-container .pin-info-values-3 .pin-info-value-1,
  #actual .info-container .pin-info-values-3 .pin-info-value-2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: #000000;
    float: right;
    width: 64px;
    padding-left: 15px;
    background-repeat: no-repeat;
    background-position: 0 -10px;
  }
  @media screen and (min-width: 801px) {
    #actual .info-container .pin-info-values-2 .pin-info-value-0,
    #actual .info-container .pin-info-values-2 .pin-info-value-1,
    #actual .info-container .pin-info-values-3 .pin-info-value-0,
    #actual .info-container .pin-info-values-3 .pin-info-value-1,
    #actual .info-container .pin-info-values-3 .pin-info-value-2 {
      font-size: 15px;
      line-height: 22px;
      width: 46px;
      background-position: 0 -13px;
    }
  }
  #actual .info-container .pin-info-values-2 .pin-info-value-0,
  #actual .info-container .pin-info-values-3 .pin-info-value-0 {
    background-image: url(../../assets/images/data_line_1.png);
  }
  #actual .info-container .pin-info-values-2 .pin-info-value-1,
  #actual .info-container .pin-info-values-3 .pin-info-value-2 {
    background-image: url(../../assets/images/data_line_3.png);
  }
  #actual .info-container .pin-info-values-3 .pin-info-value-1 {
    background-image: url(../../assets/images/data_line_2.png);
  }

  /* one container */
  #actual .info-container a:first-child:nth-last-child(1) {
    #main, > div {
      width: 294px;
      height: 338px;
    }
    #content, > div > div {
      width: 294px;
      height: 338px;
    }
    .pin-info-title {
      padding-top: 50px;
    }
    .pin-info-image-1 {
      height: 150px;
      width: 100%;
      float: none;
    }
    .pin-info-image-2,
    .pin-info-image-3 {
      height: 100px;
      width: 160px;
      float: left;
    }
    .pin-info-image-1 svg {
      width: 100px;
      height: auto;
      margin-top: 30px;
    }
    .pin-info-image-2 svg,
    .pin-info-image-3 svg {
      width: 100px;
      height: auto;
      margin-top: 76px;
    }
    .pin-info-values-1 {
      height: auto;
      display: inline;
    }
    .pin-info-values-2,
    .pin-info-values-3 {
      height: 250px;
      vertical-align: middle;
      display: table-cell;
    }
    .pin-info-values-1 .pin-info-value-0 {
      font-size: 34px;
      line-height: 40px;
      text-align: center;
      float: none;
      width: 100%;
      padding-left: 0px;
      background: none;
    }
    .pin-info-values-2 .pin-info-value-0,
    .pin-info-values-2 .pin-info-value-1,
    .pin-info-values-3 .pin-info-value-0,
    .pin-info-values-3 .pin-info-value-1,
    .pin-info-values-3 .pin-info-value-2 {
      font-size: 30px;
      font-weight: 700;
      line-height: 50px;
      text-align: left;
      color: #000000;
      float: right;
      width: 120px;
      padding-left: 26px;
      background-repeat: no-repeat;
      background-position: 0 -29px;
    }
    .pin-info-values-2 .pin-info-value-0,
    .pin-info-values-3 .pin-info-value-0 {
      background-image: url(../../assets/images/data_line_big_1.png);
    }
    .pin-info-values-2 .pin-info-value-1,
    .pin-info-values-3 .pin-info-value-2 {
      background-image: url(../../assets/images/data_line_big_3.png);
    }
    .pin-info-values-3 .pin-info-value-1 {
      background-image: url(../../assets/images/data_line_big_2.png);
    }
  }
  @media screen and (min-width: 801px) {
    #actual .info-container a:first-child:nth-last-child(1) {
      #main, > div {
        width: 206px;
        height: 206px;
      }
      #content, > div > div {
        width: 206px;
        height: 206px;
      }
      .pin-info-title {
        font-size: 12px;
        line-height: 16px;
        height: 25px;
        padding-top: 30px;
      }
      .pin-info-image-1 {
        height: 110px;
      }
      .pin-info-image-2,
      .pin-info-image-3 {
        height: 70px;
        width: 110px;
      }
      .pin-info-image-1 svg {
        width: 70px;
      }
      .pin-info-image-2 svg,
      .pin-info-image-3 svg {
        width: 70px;
        margin-top: 50px;
      }
      .pin-info-values-2,
      .pin-info-values-3 {
        height: 175px;
      }
      .pin-info-values-1 .pin-info-value-0 {
        font-size: 28px;
        line-height: 32px;
      }
      .pin-info-values-2 .pin-info-value-0,
      .pin-info-values-2 .pin-info-value-1,
      .pin-info-values-3 .pin-info-value-0,
      .pin-info-values-3 .pin-info-value-1,
      .pin-info-values-3 .pin-info-value-2 {
        font-size: 25px;
        line-height: 40px;
        width: 80px;
        background-position: 0 -34px;
      }
    }
  }

  /* maesurements */
    .maesurements .pin-info-title {
      font-family: 'Poppins', sans-serif;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      text-transform: uppercase;
      word-wrap: break-word;
      word-break: break-all;
      color: #bebeaf;
      background: white;
      display: block;
      height: 25px;
      padding-top: 65px;
    }
    .maesurements .pin-info-image-1 {
      height: 150px;
      width: 100%;
      float: none;
    }
    .maesurements .pin-info-image-1 svg {
      width: 100px;
      height: auto;
      margin-top: 30px;
    }
    .maesurements .pin-info-values-1 {
      height: auto;
      display: inline;
    }
    .maesurements .pin-info-values-1 .pin-info-value-0 {
      font-size: 34px;
      font-weight: 700;
      line-height: 40px;
      text-align: center;
      color: #000000;
    }
</style>

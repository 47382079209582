<template>
  <div class="devices-main">
    <div class="back"><router-link to="/settings">{{ $t('settings.settings') }}</router-link></div>
    <div class="list-devices" v-if="pinsWithData">
      <deviceSettings v-for="(pin, index) in pinsWithData" class="listitem pin" :values='pin[0].values'
      :key="index" :name=index :pinNumber=index back_page='devices'>
          <div class="col-2">
            <router-link :to="{ name: 'deviceTimeseriesList', query: {device: index} }" class="settingPin">
              <img src="../../assets/images/nav_settings_active.png" alt="">
            </router-link>
          </div>
       </deviceSettings>

      <div v-if="profile">
        <div v-for="handheld in devices" :key="handheld">
          <div class="listitem probe row">
            <div class="col-10">
              <h1 class="probeHeading">{{ handheld }}</h1>
            </div>
            <div class="col-2">
              <router-link :to="{ name: 'deviceTimeseriesList', query: {device: handheld} }" class="settingProb">
                <img src="../../assets/images/nav_settings_active.png" alt="">
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p>{{ $t('devices.no_devices') }}</p>
    </div>
  </div>
</template>

<script>
  import deviceSettings from './deviceSettings.vue'
  import axios from 'axios'
  export default {
    name: "devices",
    components: {
      deviceSettings,
    },
    data () {
      return {
        timeseries: null,
      }
    },
    created() {
      let vm = this;
      this.$store.dispatch('retrieveProfile').then(() => {
        vm.getSeries()
      })
    },
    computed: {
      pinsWithData() {
        return this.$store.state.pinsWithData
      },
      profile() {
        return this.$store.state.profile
      },
      devices() {
        return this.profile.details.probes
      },
    },
    methods: {
      getSeries(){
        axios.defaults.headers.common['Authorization'] = 'JWT ' + this.$store.state.token;
        return axios.get('/series/?latest_time__isnull=false&format=dashboard&source__in=' + this.devices)
          .then(response => {
            let timeseries = response.data.results;
            for (let series in timeseries) {
              if(timeseries[series].latest){
                timeseries[series].value = timeseries[series].latest.value
              }
            }
            return this.timeseries = timeseries
          });
      }
    },
  }
</script>

<style scoped>
  .devices-main {
    width: 350px;
    margin: auto;
    padding: 0;
  }
  .back {
    text-align: left;
    padding: 12px 0 30px 0;
  }
  .back a {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    color: #00008c;
    text-decoration: none;
    padding-left: 28px;
    background-image: url(../../assets/images/go_back_blue.png);
    background-repeat: no-repeat;
    background-size: 18px auto;
    background-position: 0px 2px;
  }
  .back a:hover {
    color: #00008c;
    text-decoration: none;
  }
  .list-devices  {
    box-shadow: 0px 8px 15px 0px rgba(27, 33, 58, 0.2);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 30px;
    background-color: #ffffff;
  }
  .col-10, .col-2 {
    padding: 0px;
    margin: 0px;
  }
  .settingPin {
    position: absolute;
    right: 13px;
    top: 10px
  }
  .settingProb {
    position: absolute;
    right: 10px;
    top: 13px
  }
  .probeHeading {
    padding-top: 20px;
  }
  @media screen and (max-width: 800px) {
    .list-devices {
      margin-bottom: 100px;
    }
  }
</style>

<template>
  <div id="page-content">

    <div v-if="pageIndex == 0">
      <h1>{{ $t('settings.welcome_to_fixeau') }}</h1>
      <p>{{ $t('settings.instruction_examples') }}</p>
      <p>{{ $t('settings.instruction_start') }}</p>
    </div>

    <div v-if="pageIndex == 1">
      <h1>{{ $t('settings.actual') }}</h1>
      <video width="640" height="320" controls loop autoplay>
        <source src="../../assets/videos/MODULE_1.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>

    <div v-if="pageIndex == 2">
      <h1>{{ $t('settings.map') }}</h1>
      <video width="640" height="320" controls loop autoplay>
        <source src="../../assets/videos/MODULE_2.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
     </div>

    <div v-if="pageIndex == 3">
      <h1>{{ $t('settings.boards') }}</h1>
      <video width="640" height="320" controls loop autoplay>
        <source src="../../assets/videos/MODULE_3.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>

    <div v-if="pageIndex == 4">
      <h1>{{ $t('settings.analysis') }}</h1>
      <video width="640" height="320" controls loop autoplay>
        <source src="../../assets/videos/MODULE_4.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>

    </div>

    <div v-if="pageIndex == 5">
      <h1>{{ $t('settings.settings') }}</h1>
      <video width="640" height="320" controls loop autoplay>
        <source src="../../assets/videos/MODULE_5.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>

  </div>
</template>


<script>
  export default {
    props: ['pageIndex']
  }
</script>


<style scoped>
  video {
    margin-left:55px;
  }

  #page-content h1, #page-content h2, #page-content h3 {
    text-align:center;
  }

  #page-content img {
    width:100%;
  }
</style>

<template>
    <div id="main">
      <h2>Voorwaarden</h2>
      <p>Fixeau B.V., gevestigd aan Van Hogendorpplein 4 2805 BM Gouda, is verantwoordelijk voor de verwerking van (persoons)gegevens zoals weergegeven in deze privacyverklaring.</p>
      <h5>Contactgegevens</h5>
      <p><a href="mailto:info@fixeau.com ">info@fixeau.com </a></p>
      <p class="address">Agora 4</p>
      <p class="address">8934CJ, Leeuwarden</p>
      <p>+31 (0)6 1128 6594</p>
      <h5>(Persoons) gegevens die wij verwerken</h5>
      <p>Fixeau B.V. verwerkt uw (persoons)gegevens doordat u gebruik maakt van onze diensten en/of omdat u deze zelf aan ons verstrekt. Als u gegevens aan Fixeau B.V. verstrekt, dan worden deze verwerkt in overeenstemming met de Algemene Verordening Gegevensbescherming.</p>
      <p>Hieronder vindt u een overzicht van de (persoons)gegevens die wij verwerken:</p>
      <ul>
        <li>Voor- en achternaam;</li>
        <li>Bedrijfsnaam;</li>
        <li>Adresgegevens;</li>
        <li>E-mailadres;</li>
        <li>Gebruikersnamen;</li>
        <li>IP-adres;</li>
        <li>Locatiegegevens;</li>
        <li>Meetdata.</li>
      </ul>
      <h5>Met welk doel en op basis van welke grondslag wij (persoons)gegevens verwerken</h5>
      <p>Fixeau B.V. verwerkt uw (persoons)gegevens voor de volgende doelen:</p>
      <ul>
        <li>Verzenden van onze nieuwsbrief en/of reclamefolder;</li>
        <li>U te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren;</li>
        <li>U de mogelijkheid te bieden een account aan te maken.</li>
      </ul>
      <h5>Geautomatiseerde besluitvorming</h5>
      <p>Fixeau B.V. neemt op basis van geautomatiseerde verwerkingen geen besluiten over zaken die (aanzienlijke) gevolgen kunnen hebben voor personen.</p>       <h5>Hoe lang we (persoons)gegevens bewaren</h5>
      <p>Fixeau B.V. bewaart uw (persoons)gegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor uw gegevens worden verzameld.</p>           <h5>Delen van (persoons)gegevens met derden</h5>
      <p>Fixeau B.V. verkoopt uw gegevens niet aan derden en verstrekt deze uitsluitend indien dit nodig is voor de uitvoering van onze overeenkomst met u of om te voldoen aan een wettelijke verplichting. Met bedrijven die uw gegevens verwerken in onze opdracht, sluiten wij een bewerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van uw gegevens. Fixeau B.V. blijft verantwoordelijk voor deze verwerkingen.</p>
      <h5>Cookies, of vergelijkbare technieken, die wij gebruiken</h5>
      <p>Fixeau B.V. gebruikt alleen technische en functionele cookies die geen inbreuk maken op uw privacy. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen op uw computer, tablet of smartphone. De cookies die wij gebruiken zijn noodzakelijk voor de technische werking van de website en/of app en uw gebruiksgemak. Ze zorgen ervoor dat de website naar behoren werkt en onthouden bijvoorbeeld uw voorkeursinstellingen. Ook kunnen wij hiermee onze website optimaliseren. U kunt zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen.</p>
      <h5>Gegevens inzien, aanpassen of verwijderen</h5>
      <p>U heeft het recht om uw (persoons)gegevens in te zien, te laten corrigeren of te laten verwijderen. Daarnaast heeft u het recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van uw (persoons)gegevens door ons bedrijf en heeft u het recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt indienen om de (persoons)gegevens die wij van u beschikken in een computerbestand naar u of een ander, door u genoemde organisatie, te sturen. Wilt u gebruik maken van uw recht op bezwaar en/of recht op gegevensoverdraagbaarheid of heeft u andere vragen/opmerkingen over de gegevensverwerking, stuur dan een gespecificeerd verzoek naar info@fixeau.com. Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van uw identiteitsbewijs bij het verzoek mee te sturen. Maak in deze kopie uw pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw privacy. Fixeau B.V. zal zo snel mogelijk, maar in ieder geval binnen vier weken, op uw verzoek reageren. Fixeau B.V. wil u er tevens op wijzen dat u de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit (persoons)gegevens. Dat kan via de volgende link:
        <a href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons">https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons</a></p>
      <h5>Hoe wij (persoons)gegevens beveiligen</h5>
      <p>Fixeau B.V. neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op via
        <a href="mailto:info@fixeau.com">info@fixeau.com</a></p>
      <h5>Algemene voorwaarden verkoop Fixeau</h5>
      <p>Op alle rechtsverhoudingen tussen Fixeau en haar cliënten zijn uitsluitend de door Fixeau  gehanteerde algemene voorwaarden verkoop Fixeau van toepassing, welke via deze <a href="https://dashboard.fixeau.com/verkoop-voorwaarden.pdf" target="_blank">link</a> kunnen worden opgevraagd en uitgeprint</p>
    <div>
      <label for="agree"><input id="agree" type="checkbox" value="agree" v-model="checked"/> <b>{{ $t('settings.i_accept') }}</b></label>
      <!--<label for="agree"><input class="mr-2" id="agreeData" type="checkbox" value="agree" v-model="checkedData"/>I Agree to share my data</label>-->
      <button type="button" class="btn-agree" :disabled="isDisabled" @click="changeUserTermsAcceptance()">{{ $t('settings.confirm') }}</button>
    </div>
  </div>
</template>


<script>
  export default {
    name: "terms",
    data() {
      return {
        checked : false,
        checkedData: false
      }
    },
    methods: {
      changeUserTermsAcceptance: function () {
        this.$store.dispatch('patchTermsAcceptance').then(() => {
          this.$store.state.profile.details.agreed = true;
          this.$router.push({name: 'home'})
        })
      }
    },
    computed:{
      isDisabled(){
       //return !(this.checked && this.checkedData);
       return !this.checked;
      }
    }
  }
</script>


<style lang="scss" scoped>
  #main {
    width: calc(100% - 60px);
    max-width: 500px;
    margin: auto;
    padding: 30px 0 30px 0;
  }
  @media screen and (max-width: 800px) {
    #main {
      margin-bottom: 60px;
    }
  }
  h2 {
    font-size: 30px;
    line-height: 38px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  h5 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    margin: 0;
  }
  p {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 1rem;
  }
  .address {
    margin: 0;
  }
  li {
    margin-left: 20px ;
    font-size: 16px;
    line-height: 20px;
  }
  li:last-child {
    margin-bottom: 1rem;
  }
  label {
    font-family: 'Dosis', sans-serif;
    font-size: 19px;
    line-height: 25px;
    padding-top: 10px;
  }
  #agree {
    margin: 6px 10px 30px 0;
    float: left;
  }
  .btn-agree {
    font-family: 'Dosis', sans-serif;
    font-size: 20px;
    font-weight: 400;
    width: 100%;
    padding: 14px 12px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    background-color: #2e98fc;
    -webkit-appearance: none;
    cursor: pointer;
    &:hover {
      background-color: darken(#2e98fc, 10%);
    }
    &:disabled {
      background-color: lighten(#2e98fc, 25%);
      cursor: not-allowed;
    }
  }
  @media screen and (max-width: 800px) {
    .btn-agree {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 3000;
      height: 70px;
      border-radius: 0;
    }
  }
</style>

<template>
  <div id="head">
    <h1>Pin {{ name.slice(-3) }}</h1>
    <div class="line-white">
      {{ $t('devices.battery') }}<br>
      <img :src="image" height="21" width="33"/>
    </div>
  </div>
</template>


<script>
  export default {
    props: ['values', 'name', 'classification'],
    data () {
      return {
        batteryImages: {
          0: require('../../assets/images/batterij_vol_zwart.png'),
          1: require('../../assets/images/batterij_leeg_zwart.png'),
          2: require('../../assets/images/batterij_leeg_zwart.png')
        }
      }
    },
    computed: {
      image () {
        let status = this.values["batteryStatus"];
        return this.batteryImages[status];
      }
    }
  }
</script>


<style>
  #head {
    text-align: center;
  }
  .line-white {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 10px;
    line-height: 22px;
    color: #ffffff;
    width: 315px;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    margin: 0 auto;
    padding: 10px 0 14px 0;
  }
</style>

<template>
  <div  id="actual">
    <div id="prev-next"></div>
    <slick ref="slick" :options="slickOptions" v-if="show">

      <div
        v-for="board in boards"
        v-bind:key="board.id">

        <div style="width: 100%; display: inline-block;">
          <div class="title-board">
            <h1>{{board.name}}</h1>
            <a  @click="showDeleteModal(board)" href="#"><img src="../assets/images/delete-cross.png" /></a>
          </div>

          <div class="info-container">
            <div class="board-router-link" @click="routeLink(board)">
              <a>
                <div id="unclickable">
                  <div id="content">
                  <img class='board_icon' src="../assets/images/board_icon.png" />
                  </div>
                </div>
              </a>
            </div>
          </div>

        </div>
      </div>
    </slick>

    <v-dialog/>

<!--
    <ul class="nav nav-tabs">
      <li  class="nav-item"
         v-for="board in boards"
         v-bind:key="board.id"
         v-bind:post="board">
        <a class="nav-link"  v-bind:class="{ active: isActiveBord(board.id)}"  @click="selectTab(boardslick-slide.id)" href="#">{{board.name}}</a>
        <a class="nav-link-delete" @click="showDeleteModal(board)" href="#"><img src="../assets/images/cross_blue.png" /></a>
      </li>
    </ul>-->
<!--
    <bord v-for="board in boards"
      v-if="isActiveBord(board.id)"
      v-bind:key="board.id"
      v-bind:post="board"
      v-bind:boardId="board.id">
    </bord>-->
  </div>
</template>


<script>
  // import Bord from './boards/board';
  import axios from 'axios'
  import Slick from 'vue-slick'
  import i18n from '@/i18n'
  import '../assets/slick/slick.css'
  import '../assets/slick/slick-theme.css'

  export default {
  name: "Borden",
  components: {
    // Bord,
    Slick
  },
  props:  ["back_button"],
  mounted() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    });

    this.$store.dispatch('retrieveBoards')
      .then(() => {
        this.boards = this.$store.state.boards;
        this.ready = true;
      }).then(() => {
        if (this.boards.length > 0) {
          //Redirect to board if only one board is available
          if (this.boards.length == 1) {
            this.$router.push({
              name: 'bord',
              params: { board: this.boards[0] }
            });
            return;
          }
          let previous = localStorage.getItem('previous_visited_page');
          if (this.back_button || previous == '/bord')  {
            let activeBoard= this.boards.filter(board => board.active == true);
            if(activeBoard.length > 0){
              //Back button is used, set the active board to inactive
              axios.patch('/board/' + activeBoard[0].id + '/', {
                active: false
              });
            }
          } else {
            //Redirect to active tab
            let activeBoard= this.boards.filter(board => board.active == true);
            if (activeBoard.length  > 0) {
              this.$router.push({
                name: 'bord',
                params: {board: activeBoard[0]}
              });
              return;
            }
          }
          this.show = true;
        }
      }).then(() => {
        //this.$refs.slick.create(this.slickOptions);
      });
  },
  data () {
    return {
    boards: {},
    activeTabId: null,
    ready: false,
    show: false
    }
  },
  computed: {
    slickOptions() {
      var defaultOptions = {
        arrows: true,
        dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: '0',
        focusOnSelect: false,
        appendArrows: '#prev-next',
        draggable: false,
        rows: 1,
        slidesPerRow: 1,
        responsive: [
          {
            breakpoint: 800,
            settings: {
              arrows: false,
              dots: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true,
              centerPadding: '156px',
              draggable: true,
              rows: 1,
              slidesPerRow: 1
            }
          },
          {
            breakpoint: 650,
            settings: {
              arrows: false,
              dots: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true,
              centerPadding: '17px',
              draggable: true,
              rows: 1,
              slidesPerRow: 1
            }
          }
        ]
      };

      var numCardsWidth;
      var numCardsToScroll;
      numCardsWidth = Math.floor(this.windowWidth / 230);
      defaultOptions.slidesToShow = numCardsWidth;

      numCardsToScroll = numCardsWidth - 1;

      if (numCardsToScroll < 1) {
        numCardsToScroll = 1;
      }

      defaultOptions.slidesToScroll = numCardsToScroll;

      if (this.windowHeight > 910) {
        defaultOptions.rows = 3;
      } else if (this.windowHeight > 650) {
        defaultOptions.rows = 2;
      } else {
        defaultOptions.rows = 1;
      }

      return defaultOptions;
    }
  },
  methods: {
    isActiveBord(id) {
      return this.activeTabId === id;
    },
    routeLink(board){
      let previousActiveBoard = this.boards.filter(board => board.active == true);
      //Set previous active board to false
      if (previousActiveBoard.length >  0) {
        axios.patch('/board/' + previousActiveBoard[0].id + '/', {
          active: false
        })
      }

      //Set new  board active true
      axios.patch('/board/'+board.id+'/', {
        active: true
      })

      this.$router.push({
        name: 'bord',
        params: { board: board }
      });

    },
    showDeleteModal(board) {
      this.$modal.show('dialog', {
        title: i18n.t('boards.caution'),
        text: i18n.t('boards.are_you_sure1') + board.name + i18n.t('boards.are_you_sure2'),
        buttons: [
          {
            title: i18n.t('boards.yes'),
            handler: () => {
              axios.delete('/board/'+board.id+'/', {
                active: true
              }).then(() => {
                this.boards.splice(this.boards.indexOf(board), 1);
                this.$nextTick(() => {
                  this.$refs.slick.reSlick();
                });

                if (this.activeTabId == board.id) {
                  if(this.boards.length > 0){
                    this.activeTabId = this.boards[0].id ;
                  } else {
                    //Reroute to map
                    this.$router.push("map")
                  }
                }
              });
              this.$modal.hide('dialog')
            }
          },
          {
            title: i18n.t('boards.no'),
            default: true,    // Will be triggered by default if 'Enter' pressed.
            handler: () => {
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    }
  }
  }
</script>


<style lang="scss" scoped>
  .nav-item {
    position:relative;
  }

  .nav-link-delete {
    position:absolute;
    top:0px;
    right:0px;
  }

  .nav-link-delete img{
    float:left;
    margin-top:2px;
    margin-right:2px;
    width:8px;
    height:8px;
  }

  .slick-slider {
    margin: auto;
    overflow: hidden;
    overflow-x: auto;
  }
  @media screen and (max-width: 800px) {
    .slick-slider {
      width: 624px;
      min-height: 620px;
    }
  }
  @media screen and (max-width: 650px) {
    .slick-slider {
      width: 346px;
    }
  }
  @media screen and (min-width: 801px) {
    .slick-track {
      display: flex;
      flex-direction: column;
      flex-flow: column wrap;
      align-items: center;
      align-content: stretch;
      height: calc(100vh - 118px);
    }
  }
  .slick-slide {
    width: 312px !important;
    height: 546px !important;
    opacity: 0.3;
  }
  @media screen and (min-width: 801px) {
    .slick-slide {
      width: 220px !important;
      height: auto !important;
      opacity: 1;
    }
    .slick-slide > div {
      height: 266px !important;
    }
  }
  @media screen and (max-width: 650px) {
    .slick-slide {
      opacity: 0.8;
    }
  }
  .slick-center {
    opacity: 1;
  }

  .slick-center .info-container {
    transform: scale(1.05);
  }

  /* adjustment for iphone 5 */
  @media screen and (max-width: 325px) {
    .slick-slider {
      transform: scale(0.9);
      margin-top: -34px;
      margin-left: -12px;
    }
  }
  /* end adjustment for iphone 5 */

  #prev-next {
    width: 780px;
    left: 50%;
    margin-left: -390px;
    position: absolute;
    height: calc(100vh - 74px);
  }
  @media screen and (max-width: 800px) {
    #prev-next {
      display: none;
    }
  }
  #prev-next button {
    z-index: 10;
  }

  .slick-dots {
    width: 700px;
    margin: auto;
    overflow: hidden;
  }
  .slick-dots li {
    float: left;
    width: 12px;
  }
  .title-board textarea {
    width: 100%;
    font-size: 14px;
  }
  .alert-danger {
    margin: 0px !important;
    background-color: #ff2146 !important;
    font-size: 16px;
    color: white !important;
    border: 0px !important;
    border-radius: 0px !important;
  }

  .title-board {
    position:relative;
    width:100%;
  }

  .title-board h1 {
    float:left;
    width:100%;
  }

  .title-board img{
    width:18px;
    height:24px;
    margin-top:-33px;
    margin-right:8px;
    float:right;
  }
  .board-router-link {
    cursor: pointer;
  }

  .board_icon {
    margin-top: 30px;
    width:120px;
    height:120px;
  }
</style>

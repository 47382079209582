import axios from 'axios';

const state = {
  notifications: []
};

const getters = {
  allNotifications: (state) => state.notifications,
  notificationsBasket: (state) => state.notifications.length
};

const actions = {
  async fetchNotifications({ commit }) {
    const response = await axios.get(
      '/notification/?completed=false&ignored=false'
    );
    commit('setNotifications', response.data.results)
  },
  async completedNotification({ commit }, id) {
    await axios.patch(
      `/notification/${id}/`,
      {
        completed: true
      }
    );
    commit('deleteNotification', id)
  },
  async ignoredNotification({ commit }, id) {
    await axios.patch(
      `/notification/${id}/`,
      {
        ignored: true
      }
    );
    commit('deleteNotification', id)
  },
  // eslint-disable-next-line
  async fetchClassifyChoices({ commit }, id) {
    const response = await axios.get(
      `/classify/${id}/`
    );
    return response.data.choices
  },
  // eslint-disable-next-line
  async saveClassify({ commit }, data) {
    await axios.post(
      `/classify/${data.id}/`,
      {
        "label": data.selected
      }
    );
  },
};

const mutations = {
  setNotifications: (state, notifications) => (state.notifications = notifications),
  deleteNotification: (state, id) => (state.notifications = state.notifications.filter(notification => notification.id !== id))
};

export default {
  state,
  getters,
  actions,
  mutations
}
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import notifications from './modules/notifications'
import timeseriesSettings from './modules/timeseriesSettings'
import scenarioState from './modules/scenario'
import templateHelper from '@/helpers/templateHelper'
import i18n from '@/i18n'

Vue.use(Vuex);

if (localStorage.tokenExpire && (parseInt(localStorage.tokenExpire) < new Date().getTime())) {
  localStorage.removeItem('tokenExpire');
  localStorage.removeItem('token');
}

const template = templateHelper.getActiveTemplate();

let locale = localStorage.getItem('locale');
if (locale) {
  i18n.locale = locale;
}
else {
  if (['burqaa', 'kunzila'].includes(template)) {
    i18n.locale = 'en';
  }
}

axios.defaults.baseURL = 'https://data.fixeau.com/api/v1';
if (localStorage.token) axios.defaults.headers.common['Authorization'] = 'JWT ' + localStorage.token;
Vue.config.devtools = true;
const getDefaultState = () => {
  return {
    token: localStorage.getItem('token') || null,
    // "accepted" in profile (perhaps make a getter that refers to profile)
    userTermsAcceptance: false,
    // Geojson timeseries for the map
    timeseries: [],
    // mapdata for fixeau pins
    pinsPoints: [],
    //all my classifications
    classifications: [],
    // (/config/me)
    config: {
      map_config: {},
      layer_config: {},
      legend_config: {},
    },
    // (/user/me)
    profile: null,
    // pinsetup for fixeau pins
    pinsWithData: null,
    // the basket for Map
    timeseriesBasket: [],
    // the basket for Analysis (baskets might be merged)
    itemsBasket: [],
    // maximum accepteble items in the basket
    limitBasket: 4,
    // "public" in profile (perhaps make a getter that refers to profile)
    publicAccount: false,
    // Expiration timestamp of the timeseries cache.
    timeseriesExpire: [],
    //boards for bordenpagina
    boards: [],
    preferSurfaceLevel: null,
    isSimple: ['burqaa', 'kunzila'].includes(template),
    hasScenario: template == 'wdod'
  }
}

export const store = new Vuex.Store({
  //Import small stores in modules folder
  modules: {
    notifications,
    timeseriesSettings,
    scenarioState
  },
  state: getDefaultState(),
  getters: {
    loggedIn(state) {
      return state.token !== null
    },
    timeseries(state) {
      return state.timeseries;
    },
    timeseriesBasket(state) {
      return state.timeseriesBasket
    },
    boards(state) {
      return state.boards
    },
    itemsBasket(state) {
      return state.itemsBasket
    },
    countBasket(state) {
      return state.itemsBasket.length
    },
    limitBasket (state) {
      return state.limitBasket
    },
    preferSurfaceLevel (state) {
      if (state.isPublicAccount) {
        let preferSurfaceLevel = localStorage.getItem("prefer_surface_level");
        return (preferSurfaceLevel != "undefined") ? preferSurfaceLevel : false;
      }
      return state.preferSurfaceLevel;
    }

  },
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState())
    },
    retrieveToken(state, token) {
      state.token = token
    },
    patchTermsAcceptance(state, userTermsAcceptance) {
      state.userTermsAcceptance = userTermsAcceptance
    },
    destroyToken(state) {
      state.token = null
    },
    retrieveTimeseries(state, timeseries) {
      state.timeseries = timeseries
    },
    retrieveBoards(state, boards) {
      state.boards = boards
    },
    retrievePinsPoints(state, pinsPoints) {
      state.pinsPoints = pinsPoints
    },
    retrieveClassifications(state, classifications) {
      state.classifications = classifications
    },
    retrieveConfig(state, config) {
      state.config = config
    },
    retrieveProfile(state, profile) {
      state.profile = profile
    },
    isPublicAccount(state, publicAccount) {
      state.publicAccount = publicAccount
    },
    retrievePins(state, pinsWithData) {
      state.pinsWithData = pinsWithData
    },
    // clear basket
    clearBasket(state) {
      state.itemsBasket = []
    },
    // clear Timesries-basket
    clearTimeseriesBasket(state) {
      state.timeseriesBasket = []
    },
    // add item to basket
    enqueueBasket(state, payload) {
      if (state.itemsBasket.length >= state.limitBasket) {
        payload.error = 'Basket is full.'
      } else if (payload.item) {
        payload.result = state.itemsBasket.push(payload.item)
      } else {
        payload.error = 'Item is undefined.'
      }
    },
    // remove item from basket
    dequeueBasket(state, payload) {
      if (state.itemsBasket.length > 0) {
        if (state.itemsBasket.indexOf(payload.item) > -1) {
          state.itemsBasket.splice(state.itemsBasket.indexOf(payload.item), 1);
          payload.result = state.itemsBasket
        }
      } else {
        payload.error = 'Basket is empty.'
      }
    },
    preferSurfaceLevel(state, value) {
      state.preferSurfaceLevel = value;
      Vue.set(state, 'preferSurfaceLevel', value);

      if (state.publicAccount) {
        localStorage.setItem("prefer_surface_level", value);
      } else {
        axios.patch('/user/me/', {
          "details" : {
            "prefers_surface_level": value
          },
        })
      }
    }
  },
  actions: {
    resetState(context) {
      return context.commit('resetState')
    },
    retrieveTimeseries(context, resolveDirectly) {
      return new Promise((resolve) => {
        let ecRequest =  context.dispatch('retrieveTimeseriesPerParameter', "EC");
        let tRequest =  context.dispatch('retrieveTimeseriesPerParameter', "T");
        let wlRequest =  context.dispatch('retrieveTimeseriesPerParameter', "WaterLevel");
        let phRequest =  context.dispatch('retrieveTimeseriesPerParameter', "pH");
        let smRequest =  context.dispatch('retrieveTimeseriesPerParameter', "SoilMoisture");
        let noRequest =  context.dispatch('retrieveTimeseriesPerParameter', "NO3");
        let oRequest =  context.dispatch('retrieveTimeseriesPerParameter', "O2");
        let prRequest =  context.dispatch('retrieveTimeseriesPerParameter', "Precipitation");
        let srRequest =  context.dispatch('retrieveTimeseriesPerParameter', "SolarRadiation");

        Promise.all([ecRequest, tRequest, wlRequest, phRequest, smRequest, noRequest, prRequest, srRequest, oRequest]).then(() => {
          resolve();
        });

        if (resolveDirectly) {
          resolve();
        }
      });

    },
    retrievePinsPoints(context) {
      if (context.state.pinsPoints && context.state.pinsPoints.length > 0) return 'skip'
      return axios.get('/mapdata/?format=json')
          .then(response => {
            let pinsPoints = {
              type: "FeatureCollection",
              features: response.data,
            };
            context.commit('retrievePinsPoints', pinsPoints)
          })
    },
    retrieveBoards(context) {
      //Need active state updates, so will be reloaded for now.
      //  if (this.getters.boards !== null) return 'skip'
      if (this.isPublicAccount) return 'skip'
      axios.defaults.headers.common['Authorization'] = 'JWT ' + context.state.token;

      return axios.get('/board/?format=json')
          .then(response => {
            context.commit('retrieveBoards', response.data.results)
          })
    },
    retrieveClassifications(context) {
      let surfaceLevel = (this.state.profile &&
      this.state.profile.details.prefers_surface_level) ? "?reference_level=surface" : "";

      return axios.get('/classification/me/latest/' + surfaceLevel)
          .then(response => {
            context.commit('retrieveClassifications', response.data)
          })
    },
    destroyToken(context) {
      if (context.getters.loggedIn) {
        return new Promise(resolve => {
          context.commit('destroyToken')
          localStorage.removeItem('token')
          resolve();
        })
      }
    },
    retrieveToken(context, credentials) {

      return new Promise((resolve, reject) => {
        axios.post('/token/', {
          username: credentials.username,
          password: credentials.password,
        })
            .then(response => {
              let token = response.data.token;
              localStorage.setItem('token', token);
              context.commit('retrieveToken', token);
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
      })
    },
    patchTermsAcceptance(context) {
      context.commit('patchTermsAcceptance', true);
      return axios.patch('/user/me/', {
        "details": {"agreed": true},
      });
    },
    contactUs(context, data) {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + context.state.token;
      return new Promise((resolve, reject) => {
        axios.post('/user/suggestion/', {
          message: data.message,
        })
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
      })
    },
    changePassword(context, credentials) {
      axios.defaults.auth = {
        username: credentials.username,
        password: credentials.oldPassword
      };
      return new Promise((resolve, reject) => {
        axios.patch('/user/me/', {
          password: credentials.newPassword,
        })
            .then(response => {
              axios.defaults.auth = undefined;    // otherwise axios keeps using the old password
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
      })
    },
    retrieveConfig(context) {
      // let config = Object.assign({}, this.state.config.layer_config);
      // let isEmptyObject = Object.keys(config).length === 0;
      // if (!isEmptyObject) return 'skip'
      axios.defaults.headers.common['Authorization'] = 'JWT ' + context.state.token;
      let surfaceLevel = (this.state.preferSurfaceLevel) ? "?reference_level=surface" : "";

      return axios.get('/config/me/' + surfaceLevel)
          .then(response => {
            context.commit('retrieveConfig', response.data)
          })
    },
    async retrieveProfile(context) {
      //Retrieve profile and pinsetup for each pin
      if (this.state.profile && this.state.pinsWithData) return 'skip'
      axios.defaults.headers.common['Authorization'] = 'JWT ' + context.state.token;
      await context.dispatch('retrieveJustProfile');
      let responseProfile = context.state.profile;
      let resultsArray = {};

      function deployPin(pin) {
        let url = '/pinsetup/' + pin + '/?format=json';
        return axios
            .get(url)
            .then(response => {
              if (response.data.length > 0) {
                resultsArray[pin] = response.data;
              }
              return;
            })
      }

      let pinsObject = responseProfile.details.pins;
      for (const pin in pinsObject) {
        await deployPin(pinsObject[pin])
      }
      await context.commit('retrievePins', resultsArray);
      await context.commit('retrieveProfile', responseProfile)
      return responseProfile
    },
    retrieveJustProfile(context) {
      //if (context.state.profile) return 'skip'
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + context.state.token;
        axios.get('/user/me/').then(response => {
          context.commit('isPublicAccount', response.data.details.public);
          context.commit('patchTermsAcceptance', response.data.details.agreed);
          context.commit('preferSurfaceLevel', response.data.details.prefers_surface_level);

          resolve(context.commit('retrieveProfile', response.data));
        }, error => {
          reject(error);
        })
      })
    },
    // add timeseries to basket and return newly added item.
    addTimeseriesBasket(context, item) {
      return new Promise((resolve) => {
        resolve(context.state.timeseriesBasket.push(item))
      })
    },
    // remove target timeseries from basket.
    removeTimeseriesBasket(context, item) {
      return new Promise((resolve) => {
        resolve(context.state.timeseriesBasket.splice(context.state.timeseriesBasket.indexOf(item), 1))
      })
    },
    // add item to basket and return newly added item.
    addItemBasket({commit}, item) {
      return new Promise((resolve, reject) => {
        let payload = {item}
        commit('enqueueBasket', payload)
        if (payload.error) {
          reject(payload.error)
        } else {
          resolve(payload.result)
        }
      })
    },
    // remove first item from basket and return removed item.
    removeItemBasket({commit}, item) {
      return new Promise((resolve, reject) => {
        let payload = {item}
        commit('dequeueBasket', payload)
        if (payload.error) {
          reject(payload.error)
        } else {
          resolve(payload.result)
        }
      })
    },
    retrieveTimeseriesPerParameter(context, parameter) {
      let now = new Date();

      let parameterExpire = parameter;
      if (parameter == 'WaterLevel' && !context.state.preferSurfaceLevel) {
        parameterExpire = parameter+"NAP";
      }

      let timeseriesExpire =  Object.assign({}, context.state.timeseriesExpire);
      if (timeseriesExpire[parameterExpire] && timeseriesExpire[parameterExpire] > now.getTime()) return 'skip';
      let expire = new Date();
      expire.setHours(expire.getHours() + 1);

      //*1 to make Date a Long
      context.state.timeseriesExpire[parameterExpire] = expire*1;
      return context.dispatch('retrieveTimeseriesPerParameterRequest', parameter);
    },
    retrieveTimeseriesPerParameterRequest(context, parameter) {
      return new Promise(function (resolveAll) {
        // Containers to be filled with the timeseries data:
        let timeseriesFeatures = [];

        function parsePage(page, sourceType) {
          page.results.forEach(function (series) {
            if (series.location && series.latest !== null) {
              series.data_source = sourceType;
              timeseriesFeatures.push(series);
            }
          });
        }

        // Process page and request next page:
        function processPage(page, sourceType) {
          if (page.next) {
            let nextPage = new Promise(function (resolve) {
              axios.get(page.next).then(response => resolve(response.data)).then(() => {
                resolve();
              })
            })
            parsePage(page, sourceType);
            nextPage.then(function (page) {
              processPage(page, sourceType);
            });
          } else {
            parsePage(page, sourceType);
            let timeseries = store.state.timeseries;
            if (timeseries == null) {
              timeseries = [];
            }

            if (parameter == 'WaterLevel' && !store.state.preferSurfaceLevel) {
              parameter = parameter+"NAP";
            }

            timeseries[parameter] = {
              type: "FeatureCollection",
              features: timeseriesFeatures
            };

            context.commit('retrieveTimeseries', Object.assign({}, timeseries));
          }
        }

        //Source type FixeauPin requests
        axios.defaults.headers.common['Authorization'] = 'JWT ' + context.state.token;
        let params = {
          format: 'dashboard_geo',
          page_size: 2000,
          latest_time__isnull: false,
          source__source_type: 'FixeauPin'
        };
        if (parameter != 'pins') {
          params.parameter__in = parameter;     // TODO: split timeseries on parameter and retrieve timeseries for each parameter separately ( 1) faster when looking at one parameter on the map, 2) faster refresh at NAP/mv toggle, 3) on analysis, load parameters first, then give priority to the parameter clicked)
        }

        //Source  in requests
        if (context.state.preferSurfaceLevel) params.reference_level = 'surface';
        let request1 = axios.get('/series/', {params: params}).then(response => processPage(response.data, 'FixeauPin'));
        const params2 = {...params};
        params2.source__source_type__in = "Caddisfly,User,FixeauMobile,AkvoMobile"
        delete params2.source__source_type;
        let request2 = axios.get('/series/', {params: params2}).then(response => processPage(response.data, 'FixeauMobile'));

        //Source not in requests
        const params3 = {...params2};
        params3.source__source_type__not_in = "FixeauPin,Caddisfly,User,FixeauMobile,AkvoMobile"
        delete params3.source__source_type__in;
        let request3 = axios.get('/series/', {params: params3}).then(response => processPage(response.data, 'Other'));

        Promise.all([request1, request2, request3]).then(() => {
          resolveAll();
        });
      });
    }
  }
});
